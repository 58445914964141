import { Box, Typography } from "@mui/material";
import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Brush,
} from "recharts";
import { commonXaxisProp, commonYAxiosProp } from "../../const/analytics";

function CustomLineChart({ title, options, data, ...rest }) {
  const lineMap =
    options?.length > 0
      ? options.map((res) => {
          return (
            <Line
              key={res?.datakey}
              type="monotone"
              dataKey={res?.datakey}
              strokeWidth={res?.strokeWidth}
              stroke={res?.stroke}
            />
          );
        })
      : false;

  return (
    <Box
      sx={{
        width: "100%",
        height: "300px",
        background: "#fff",
        borderRadius: "12px",
        paddingBlock: "10px 10px",
        display: "flex",
        // alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        gap: "15px",
        // border: "1px solid red",
      }}
    >
      <Typography
        sx={{
          fontFamily: "Proxima Nova",
          fontSize: "18px",
          paddingLeft: "20px",
          paddingTop: "5px",
          fontWeight: 600,
          lineHeight: "26px",
          letterSpacing: "-0.02em",
          textAlign: "left",
        }}
      >
        {title}
      </Typography>
      <ResponsiveContainer width={"100%"} height={"85%"}>
        <LineChart {...rest} width={"100%"} data={data}>
          <XAxis
            dataKey="name"
            padding={{ left: 10, right: 10 }}
            tickLine={false}
            interval={0}
            {...commonXaxisProp}
          />
          <YAxis axisLine={false} tickLine={false} {...commonYAxiosProp} />
          <Brush
            dataKey="name"
            padding={"10px 0px 0px 0px"}
            height={28}
            stroke="#8884d8"
          />
          <Tooltip />
          <Legend
            fontFamily="Proxima Nova"
            layout="horizontal"
            verticalAlign="top"
            align="right"
          />

          {lineMap}
        </LineChart>
      </ResponsiveContainer>
    </Box>
  );
}

export default CustomLineChart;
