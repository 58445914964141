import { Search } from "@mui/icons-material";
import { InputAdornment, OutlinedInput, SvgIcon } from "@mui/material";
import React from "react";

const SearchBar = ({ value, setState, placeholder }) => {
  return (
    <>
      <OutlinedInput
        defaultValue=""
        value={value}
        onChange={(e) => setState(e.target.value)}
        placeholder={placeholder ?? `Search Customer, Phone no, Email`}
        startAdornment={
          <InputAdornment position="start">
            <SvgIcon fontSize="small">
              <Search />
            </SvgIcon>
          </InputAdornment>
        }
        sx={{
          width: "30vw",
          borderRadius: "12px",
          height: "42px",
          "& .MuiOutlinedInput-input": {
            padding: "9px 70px 9px 15px",
          },
          background: "#FFF",
        }}
      />
    </>
  );
};

export default SearchBar;
