import React from "react";
import { Box, Typography, Stack, Button } from "@mui/material";
import downloadIcon from "../../assets/DriverIcon.svg";
import FileUploadInput from "../FileUploadInput";
import FileUploadIcon from "@mui/icons-material/FileUpload";
// fileUploadComponent
const FileUpload = ({
  profilePic,
  setProfilePic,
  renamebtntext = "Change",
  handleUploadFileToApi = { handleUploadFileToApi },
}) => {
  const handlePhotoUpload = (file) => {
    if (!file) return;
    setProfilePic((prev) => ({
      ...prev,
      profilePic: URL.createObjectURL(file),
    }));

    handleUploadFileToApi(file);
  };

  return profilePic.profilePic ?? "" ? (
    <Box width={"100%"} display={"flex"} position={"relative"}>
      <img
        src={profilePic.profilePic}
        style={{
          display: "block",
          objectFit: "cover",
        }}
        width={"155px"}
        height={"155px"}
        alt=""
      />
      <Button
        sx={{
          position: "absolute",
          left: "165px",
          bottom: "0px",
        }}
        variant="outlined"
        onClick={() => {
          let uploadDocument = document.getElementById("upload-photo");

          if (uploadDocument) {
            uploadDocument.click();
          }
        }}
      >
        {renamebtntext}
      </Button>
      <FileUploadInput id="upload-photo" onFileChange={handlePhotoUpload} />
    </Box>
  ) : (
    <Box
      sx={{
        display: "inline-flex",
        padding: "42px 28px 40px 29px",
        justifyContent: "center",
        alignItems: "center",
        boxSizing: "border-box",
        border: "1px dashed rgba(0, 0, 0, 0.30)",
        background: "rgba(0, 0, 0, 0.04)",
        borderRadius: "12px",
      }}
    >
      <Stack alignItems={"center"} gap={"10px"}>
        <FileUploadIcon
          sx={{
            width: "40px",
            height: "40px",
            fill: "#6F747E",
          }}
        />
        <Button
          variant="text"
          sx={{
            color: "#000",
          }}
          onClick={() => {
            let uploadDocument = document.getElementById("upload-photo");

            if (uploadDocument) {
              uploadDocument.click();
            }
          }}
        >
          Upload photo
        </Button>

        <FileUploadInput id="upload-photo" onFileChange={handlePhotoUpload} />
      </Stack>
    </Box>
  );
};

const OperatorProfile = ({
  profilePic,
  setProfilePic,
  renamebtntext,
  handleUploadFileToApi = () => {},
}) => {
  const opertorProfileTitleStyle = {
    color: "var(--Font-sub-text, #6F747E)",
    fontFamily: "Proxima Nova",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
    mb: "10px",
    display: "block",
  };

  return (
    <Box>
      <Typography sx={opertorProfileTitleStyle}>Operator Profile</Typography>

      <Box
        height={"40%"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"flex-start"}
      >
        {/* Assume FileUpload is a component for file upload */}
        <FileUpload
          profilePic={profilePic}
          setProfilePic={setProfilePic}
          renamebtntext={renamebtntext}
          handleUploadFileToApi={handleUploadFileToApi}
        />
      </Box>
    </Box>
  );
};

export default OperatorProfile;
