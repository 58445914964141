import { useSelector } from "react-redux";
import { formatDateWithMoment } from "../utils/formateDate";

export const useSettings = () => {
  const { dateformat, timezone, timeFormat } = useSelector(
    (store) => store.date
  );

  const formatDate = (date, timezone, format) => {
    return formatDateWithMoment(date, timezone, format);
  };

  return { dateformat, timezone, timeFormat, formatDate };
};
