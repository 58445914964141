import { DatePicker } from "@mui/x-date-pickers";
import React from "react"; // Adjust the path as needed
import useQueryParams from "../../../hooks/useQueryParams ";

const CustomDatePicker = ({
  label,
  value,
  onChange,
  name,
  setState,
  isoString,
  isDispatch,
  state,
  maxDate,
  minDate,
  style,
  filterName,
  ...rest
}) => {
  const { setQuery, removeQuery } = useQueryParams();
  return (
    <DatePicker
      minDate={minDate}
      maxDate={maxDate}
      format="DD/MM/YYYY"
      sx={{ ...style, color: "#d9d9da" }}
      label={label}
      slotProps={{ textField: { size: "small" } }}
      value={value || null}
      onChange={(newValue) => {
        if (newValue) {
          if (filterName) {
            setQuery(filterName, newValue?.toISOString());
          }
        } else {
          removeQuery("date");
        }

        onChange(name, newValue, setState, isoString, isDispatch, state);
      }}
      {...rest}
    />
  );
};

export default CustomDatePicker;
