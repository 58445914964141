import { Avatar, Box, Stack, Typography } from "@mui/material";
import React, { useEffect, useRef } from "react";
import midRUnFastIcon from "../assets/mdi_run-fast.svg";

//css
const quickFilterCss = {
  boxSizing: "border-box",
  display: "inline-flex",
  width: "auto",
  height: "42px",
  padding: "5px 19px",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "12px", // Border radius
  border: "1px solid #B3B3B3", // Border color
  background: "#FFF", // Background color
  gap: "5px",
};

const quickFilterContainer = {
  boxSizing: "border-box",
  display: "flex",
  gap: "12px",
  padding: "5px 0",
  marginLeft: "8px",
};

const quickFilterTextStyle = {
  fontFamily: "Poppins",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  textTransform: "capitalize",
  color: "#2c2c2e",
};

const MapQuickFilter = ({
  initialApiCoordinates,
  quickFilterConfig,
  quickfilter,
  handleTicketStatus,
}) => {
  const countRef = useRef({
    all: 0,
    plus: 0,
    taxi: 0,
    compact: 0,
  });

  useEffect(() => {
    const updateCount = () => {
      if (initialApiCoordinates) {
        const count = {
          all: 0,
          plus: 0,
          taxi: 0,
          compact: 0,
        };

        Object.values(initialApiCoordinates).forEach((res) => {
          if (res?.data?.cab_type) {
            count[res?.data?.cab_type?.toLowerCase()] += 1;
            count["all"] += 1;
          }
        });

        countRef.current = count;
      }
    };

    // Initial count update
    updateCount();

    // Set interval for periodic updates
    const intervalId = setInterval(updateCount, 500);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [initialApiCoordinates]);

  return (
    <Box sx={quickFilterCss}>
      <Box
        sx={{
          boxSizing: "border-box",
          padding: "5px",
          borderRight: "2px solid #B4B4B4",
        }}
      >
        <Avatar
          src={midRUnFastIcon}
          sx={{
            width: "24px",
            height: "24px",
            mr: "8px",
          }}
        />
      </Box>

      <Stack direction={"row"} alignItems={"center"} gap={"10px"}>
        {quickFilterConfig?.length > 0 &&
          quickFilterConfig.map((res) => (
            <Box
              key={res.label}
              sx={{ ...quickFilterContainer, alignItems: "center" }}
            >
              <input
                type="checkbox"
                value={res.value}
                id={res.label}
                checked={quickfilter?.includes(res.value)}
                onChange={handleTicketStatus}
                style={{
                  margin: "0",
                  padding: "0",
                  height: "20px",
                  width: "20px",
                }}
              />
              <Typography sx={quickFilterTextStyle}>
                <label style={{ display: "block" }} htmlFor={res.label}>
                  {res.label}
                  {res.value && (
                    <span>{`(${countRef?.current[res.value]})`}</span>
                  )}
                </label>
              </Typography>
            </Box>
          ))}
      </Stack>
    </Box>
  );
};

export default MapQuickFilter;
