// React imports
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

// Other imports
import axios from "axios";

import TripStatusHandler from "./TripStatusHandler";

export let strongTextStyle = {
  fontFamily: "Proxima Nova",
  fontSize: "30px",
  fontStyle: "normal",
  fontWeight: 800,
  lineHeight: "normal",
  letterSpacing: "0.027px",
  textTransform: "capitalize",
  color: "var(--font-dark, #0F1828)",
};

export let thinTextStyle = {
  fontFamily: "Proxima Nova",
  fontSize: "19px",
  fontStyle: "normal",
  textTransform: "capitalize",
  fontWeight: 100,
  lineHeight: "20px",
  letterSpacing: "0.027px",
  color: "var(--font-light-70, rgba(15, 24, 40, 0.70))",
};
export let bottomCardStyles = {
  height: "11%",
  background: "#FFF",
  color: "var(--font-dark, #0F1828)",
  display: "flex",
  gap: "8px",
  flexDirection: "column",
  // alignItems: "flex-start",
  padding: "20px",
  marginBottom: "20px",
};
export let bottomCardInnerStyles = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  marginBottom: "10px",
};

export let groupCircleImagesStyles = {
  display: "flex",
  flexDirection: "column",
  gap: "15px",
  alignItems: "center",
  justifyContent: "center",
  marginBottom: "10px",
  marginRight: "18px",
  height: "100%",
};

export let driverImageStyle = {
  width: "100px",
  height: "100px",
  borderRadius: "50%",
  backgroundColor: "gray",
  // marginRight: "",
  // boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.5)",
};
export let starStyle = {
  width: "30px",
  height: "30px",
  // marginLeft: "20px",
  // marginTop: "10px",
  // marginRight: "10px",
};
export let cashSvgStyle = {
  width: "35px",
  height: "35px",
  // marginLeft: "50vw",
  // marginRight: "5px",
};
const RideTracking = () => {
  const [isLoading, setisLoading] = useState(false);

  // const [isMobile, setIsMobile] = useState(window?.innerWidth <= 600);
  //Styles
  let googleMapStyle = {
    flex: 1, // Allow the map container to take up available space
    display: "flex",
    flexDirection: "column", // To stack the map and data card vertically on small screens
    justifyContent: "center", // Center the map horizontally
  };

  //Change styles as per mobile screen
  if (true) {
    driverImageStyle = {
      ...driverImageStyle,
      height: "50px",
      width: "50px",
    };

    strongTextStyle = {
      ...strongTextStyle,
      fontSize: "20px",
    };

    thinTextStyle = {
      ...thinTextStyle,
      fontSize: "17px",
    };

    starStyle = {
      ...starStyle,
      width: "20px",
      height: "20px",
      // marginLeft: "10px",
    };

    cashSvgStyle = {
      ...cashSvgStyle,
      width: "25px",
      height: "25px",
      // marginLeft: "40vw",
    };
  }

  const { ride_uuid } = useParams();
  const [apiResponse, setApiResponse] = useState({});
  const config = useSelector(
    (store) => store.config.configValues.server_base_url
  );

  useEffect(() => {
    const fetchData = () => {
      if (config) {
        axios
          .get(`${config}/crm/ride/tracking/${ride_uuid}/`)
          .then((response) => {
            const data = response.data;
            setApiResponse(data?.data);
            let dropTime = new Date();
            dropTime.setMinutes(dropTime.getMinutes() + data?.data?.eta_in_min);
            dropTime = dropTime.toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
              hour12: true,
            });
          })
          .catch((error) => {
            // Handle error, e.g., redirect to not found page
            // window.location.href = "/not-found/";
          })
          .finally(() => {
            setTimeout(() => {
              setisLoading(false);
            }, 500);
          });
      }
    };

    // Continue hit until apiResponse is set
    if (Object.keys(apiResponse)?.length <= 0) {
      setisLoading(true);
      fetchData();
    }

    // Set interval of 50 minutes
    const intervalId = setInterval(fetchData, 50 * 60 * 1000); // 50 minutes in milliseconds

    // Cleanup interval when component unmounts
    return () => clearInterval(intervalId);
  }, [ride_uuid, config]); // Depend on ride_uuid, apiResponse, and config

  return <TripStatusHandler apiResponse={apiResponse} isLoading={isLoading} />;
};

export default RideTracking;
