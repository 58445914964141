import {
  Box,
  Button,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Clear } from "@mui/icons-material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import ReusableTimePicker from "./reusableComponents/DateTimePickers/ReusableTimePicker";
import { showErrorToast } from "../utils/toastUtils";
import { checkIsObjectIsFilled } from "./NewTraningCenter";
import AddIcon from "@mui/icons-material/Add";

function validateSlots(slotData = []) {
  if (slotData.length <= 1) return true;

  for (let i = 0; i < slotData.length; i++) {
    const { start_time, end_time } = slotData[i];

    // Check if both start and end time values exist
    if (!start_time || !end_time) {
      console.error(`Error: Slot at index ${i} has missing time values.`);
      return false; // Indicate error and stop further checks
    }

    const startTime = new Date(start_time);
    const endTime = new Date(end_time);

    for (let j = 0; j < slotData.length; j++) {
      if (i !== j) {
        const { start_time: otherStartTime, end_time: otherEndTime } =
          slotData[j];

        // Check if other slot also has both time values
        if (!otherStartTime || !otherEndTime) {
          console.error(`Error: Slot at index ${j} has missing time values.`);
          return false; // Indicate error and stop further checks
        }

        const otherStart = new Date(otherStartTime);
        const otherEnd = new Date(otherEndTime);

        // Check for overlap
        if (startTime < otherEnd && endTime > otherStart) {
          return false; // Overlap found
        }
      }
    }
  }

  return true; // No overlaps found
}

const initialSlotOptions = {
  render_name: "",
  start_time: "",
  end_time: "",
  default_capacity: "",
};

const TrainingCenterForm = ({
  handleTrainingCenter,
  trainingCenter,
  slotOptions,
  setSlotOptions,
  handleSlotOptions,
  handleFromStartTime,
  handleToEndTime,
}) => {
  const flexStyle = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  };

  const [slotsNameOptions, setSlotsNameOptions] = useState([
    { text: "Morning", isDisable: false },
    { text: "Afternoon", isDisable: false },
    { text: "Evening", isDisable: false },
  ]);

  const [openConfirmationDialog, setOpenConfirmationDialog] = useState({
    delete: false,
    block: false,
  });

  useEffect(() => {
    if (slotOptions && slotOptions.length > 0) {
      setSlotsNameOptions((prev) => {
        return prev.map((res) => {
          const findvalue = slotOptions.find(
            (options) => res.text === options.render_name
          );

          return { ...res, isDisable: findvalue ? true : false };
        });
      });
    }
  }, [slotOptions]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "18px",
        backgroundColor: "#fff",
        padding: "0 25px 12px 25px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          gap: "18px",
          width: "100%",
          mt: "18px",
        }}
      >
        <TextField
          fullWidth
          label="Training Center Name"
          name="training_center_name"
          value={trainingCenter?.training_center_name}
          onChange={handleTrainingCenter}
        />
      </Box>

      <TextareaAutosize
        style={{
          width: "100%",
          height: "100px",
          boxSizing: "border-box",
          padding: "10px",
          border: "1px solid gray",
          outline: "none",
          resize: "none",
        }}
        placeholder="Training Center Address"
        name="training_center_address"
        value={trainingCenter?.training_center_address}
        onChange={handleTrainingCenter}
      />

      <Box sx={{ ...flexStyle, gap: "10px", width: "100%" }}>
        <TextField
          fullWidth
          type="number"
          label="Latitude"
          name="training_center_latitude"
          value={trainingCenter?.training_center_latitude}
          onChange={handleTrainingCenter}
        />
        <TextField
          fullWidth
          type="number"
          label="Longitude"
          name="training_center_longitude"
          value={trainingCenter?.training_center_longitude}
          onChange={handleTrainingCenter}
        />
      </Box>

      <Typography
        sx={{
          fontSize: "19px",
          fontFamily: "Proxima Nova",
          fontWeight: 600,
          mt: "16px",
        }}
      >
        Add Slots
      </Typography>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "18px",
        }}
      >
        {slotOptions.map((slotoption, index) => (
          <Box key={index}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <IconButton
                  onClick={() => {
                    setSlotOptions((prev) =>
                      prev.filter((option, i) => i !== index)
                    );
                  }}
                >
                  <Clear
                    sx={{
                      fill: "#000",
                    }}
                  />
                </IconButton>
              </Box>
              <Stack gap={"10px"} flexDirection={"row"}>
                <TextField
                  select
                  fullWidth
                  label="Slot Name"
                  name="render_name"
                  value={slotOptions[index].render_name}
                  onChange={(e) => {
                    setSlotsNameOptions((prev) => {
                      return prev.map((res) =>
                        res.text === e.target.value
                          ? { ...res, isDisable: true }
                          : res
                      );
                    });
                    handleSlotOptions(e, index);
                  }}
                >
                  {slotsNameOptions.map((res) => (
                    <MenuItem
                      disabled={res?.isDisable ?? false}
                      key={res?.text ?? ""}
                      value={res?.text ?? ""}
                    >
                      {res?.text}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  fullWidth
                  label="Slot Capacity"
                  type="number"
                  name="default_capacity"
                  value={slotOptions[index]?.default_capacity}
                  onChange={(e) => handleSlotOptions(e, index)}
                />
              </Stack>
            </Box>
            <Box>
              <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                  width: "100%",
                }}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <ReusableTimePicker
                    key={index}
                    label="From"
                    sx={{
                      width: "100%",
                    }}
                    ampm={true}
                    name={"start_time"}
                    value={slotOptions[index]?.start_time ?? ""}
                    onChange={(name, value, setState) => {
                      handleFromStartTime(name, value, setState, index);
                    }}
                  />
                  <ReusableTimePicker
                    label="To"
                    sx={{
                      width: "100%",
                    }}
                    ampm={true}
                    name={"end_time"}
                    value={slotOptions[index]?.end_time ?? ""}
                    onChange={(name, value, setState) => {
                      handleToEndTime(name, value, setState, index);
                    }}
                  />
                </LocalizationProvider>
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
        }}
      >
        <Button
          sx={{
            color: "#000",
          }}
          onClick={() => {
            const checkToIsGreaterThenFrom = slotOptions.some((slot) => {
              const fromTime = new Date(slot.start_time);
              const toTime = new Date(slot.end_time);

              if (fromTime >= toTime) {
                showErrorToast("To should be greater than From");
                return true; // Indicates that an error was found
              }

              return false; // Indicates that no error was found for this slot
            });

            if (checkToIsGreaterThenFrom) {
              return;
            }

            if (!validateSlots(slotOptions) && slotOptions.length !== 0) {
              showErrorToast(
                "Slots are overlapping. Please set them properly.",
                {
                  autoClose: 5000,
                }
              );
              return;
            }

            const lastSlot = slotOptions[slotOptions.length - 1];
            if (checkIsObjectIsFilled(lastSlot) || slotOptions.length === 0) {
              setSlotOptions((prevOptions) => [
                ...prevOptions,
                initialSlotOptions,
              ]);
            } else {
              showErrorToast(
                "Please fill the previous slot before adding a new one."
              );
            }
          }}
          endIcon={<AddIcon />}
        >
          Add
        </Button>
      </Box>
      {/* <CustomConfirmationDialog
        open={openConfirmationDialog?.delete ?? false}
        status="delete"
        message="Are you sure you want to remove this slot?"
        // deleteById={}
      /> */}
    </Box>
  );
};

export default TrainingCenterForm;
