import { showErrorToast } from "./toastUtils";

function isFileValid(file) {
  // If no file is provided, returning false
  if (!file) return false;
  const allowedExtensions = ["pdf", "jpeg", "jpg", "png", "heic", "svg"];
  const fileName = file.name;
  const fileExtension = fileName.split(".").pop().toLowerCase();
  return allowedExtensions.includes(fileExtension);
}

export function isFileExtensionValid(fileName) {
  const allowedExtensions = ["pdf", "jpeg", "jpg", "png", "heic", "svg"];
  const fileExtension = fileName.split(".").pop().toLowerCase();

  return allowedExtensions.includes(fileExtension);
}

const checkboxOrder = [
  "basicDetailsCheck",
  "documentsCheck",
  "carDetailsCheck",
  "bankDetailsCheck",
  "tranningDetails",
];

export const handleCheckboxChange = (checkboxStates, checkboxName) => {
  // Find the index of the changed checkbox
  const changedIndex = checkboxOrder.indexOf(checkboxName);
  if (!checkboxStates[checkboxName]) return;
  // Check checkboxes in order
  let errorMessage = "";
  let check = false;
  for (let i = 0; i <= changedIndex; i++) {
    if (!checkboxStates[checkboxOrder[i]]) {
      // Display a custom error message based on the checkbox

      switch (checkboxOrder[i]) {
        case "documentsCheck":
          if (!checkboxStates[checkboxOrder[0]]) {
            errorMessage = "Please Verify Basic Details";
          } else {
            errorMessage = "Please Verify Documents";
          }
          check = true;
          break;
        case "carDetailsCheck":
          errorMessage = "Please Verify Car Details";
          check = true;
          break;
        case "bankDetailsCheck":
          errorMessage = "Please Verify Bank Details";
          check = true;
          break;
        case "tranningDetails":
          errorMessage = "Please Verify  Training Details.";
          check = true;
          break;
        default:
          check = true;
          errorMessage = "Please Verify Basic Details first.";
          break;
      }

      if (check) {
        // Display the error message
        showErrorToast(errorMessage);
        return check;
      }
    }
  }

  // Return early to prevent state update
  return errorMessage ? true : false;
};

export default isFileValid;
