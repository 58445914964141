import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";

const PushNotificationDailog = ({
  PushNotificationState,
  setOpen = () => {},
  open,
  handlefetch = () => {},
  options = [],
}) => {
  const handleCloseDialog = () => {
    setOpen(false);
  };

  const target = PushNotificationState?.target;
  const foundOption = options?.find((option) => option?.value === target);
  const label = foundOption?.label;

  const labelStyle = {
    color: "#6F747E",
    fontFamily: "Proxima Nova",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "17.05px",
    textAlign: "left",
  };

  const valueStyle = {
    color: "#000",
    fontWeight: "bold",
    fontFamily: "Proxima Nova",
    fontSize: "16px",
    // fontWeight: 600,
    lineHeight: "17.05px",
  };

  return (
    <Dialog
      open={open}
      style={{
        zIndex: 2000,
        backgroundColor: "transparent",
      }}
      onClose={() => {
        handleCloseDialog();
      }}
    >
      <DialogTitle>Review message</DialogTitle>
      <DialogContent
        sx={{
          maxWidth: "1000px",
          display: "flex",
          flexDirection: "column",
          gap: "15px",
        }}
      >
        {PushNotificationState?.title && (
          <Stack gap={"5px"}>
            <Typography sx={labelStyle}>Notification Title</Typography>
            <Typography sx={valueStyle}>
              {PushNotificationState?.title}
            </Typography>
            {/* 
            <div
              style={{
                borderBottom: "1px solid #000",
                marginTop: "5px",
              }}
            ></div> */}
          </Stack>
        )}

        {PushNotificationState?.description && (
          <Stack gap={"5px"}>
            <Typography sx={labelStyle}>Notification Description</Typography>
            <Typography sx={valueStyle}>
              {PushNotificationState?.description}
            </Typography>
            {/* <div
              style={{
                borderBottom: "1px solid #000",
                marginTop: "5px",
              }}
            ></div> */}
          </Stack>
        )}

        {PushNotificationState?.target && (
          <Stack gap={"5px"}>
            <Typography sx={labelStyle}>Notification Target</Typography>
            <Typography sx={valueStyle}>{label}</Typography>
            {/* <div
              style={{
                borderBottom: "1px solid #000",
                marginTop: "5px",
              }}
            ></div> */}
          </Stack>
        )}

        <Stack gap={"10px"}>
          <Typography sx={labelStyle}>Save data</Typography>
          <Typography sx={valueStyle}>
            {PushNotificationState?.saveInformationCheck ? "True" : "false"}
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={(e) => {
            e.stopPropagation();
            handleCloseDialog();
          }}
          sx={{ color: "#000" }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={(e) => {
            if (handlefetch) {
              handlefetch(e);
            }
          }}
          sx={{
            color: "#fff",
            backgroundColor: "#000",
            "&:hover": {
              color: "#fff",
              backgroundColor: "#000",
            },
          }}
        >
          Publish
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PushNotificationDailog;
