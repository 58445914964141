import React, { useEffect, useState } from "react";
import axiosInstance from "../utils/axiosConfig";
import { showErrorToast } from "../utils/toastUtils";
import banknoteIcon from "../assets/summary/Banknote.svg";
import { areaoptions, getLabel, graphyXLabel } from "./Summary";
import duration from "dayjs/plugin/duration";
import { Box, MenuItem, Select, Stack } from "@mui/material";
import AnalyticsCard from "./AnalyticsCard";
import { SelectOptions, initialDateFilter } from "../const/analytics";
import { useSettings } from "../hooks/useSettings";
import CustomAreaChart from "./rechart/CustomAreaChart";

import dayjs from "dayjs";
import CommonDateFilterPanel from "./CommonDateFilterPanel";
import { formatDateWithDayJs } from "../utils/formateDate";
dayjs.extend(duration);

//css

function capitalizeIfAllCaps(word) {
  if (word === word.toUpperCase()) {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  }

  return word;
}

const formatDateRange = (dateFilter) => {
  if (dateFilter.select === "CUSTOM") {
    const fromDate = dayjs(dateFilter.from_time).format("DD/MM/YYYY");
    const toDate = dayjs(dateFilter.to_time).format("DD/MM/YYYY");

    if (fromDate !== "Invalid Date" && toDate !== "Invalid Date") {
      return `${fromDate} - ${toDate}`;
    }
  }
  return "";
};

const displayText = (dateFilter, res) => {
  let text = "";

  if (res && res[0]) {
    text += `${capitalizeIfAllCaps(dateFilter.select)} ${res[0]}`;
  }

  const customDateRange = formatDateRange(dateFilter);
  if (customDateRange) {
    text += text ? `<br/>${customDateRange}` : customDateRange;
  }

  return text;
};

const containerStyle = {
  borderRadius: "12px",
  height: "42px",
  outline: "none",
  backgroundColor: "#fff",
};

const FinanceManagement = () => {
  const [areaChart, setAreaChart] = useState([]);
  const [areaChartData, setAreaChartData] = useState([]);
  const [selectedOption, setSelectedOption] = useState("TODAY");
  const [dateFilter, setDateFilter] = useState(initialDateFilter);
  const { timezone, formatDate } = useSettings();
  const [sums, setSums] = useState({});

  const totalFinanceObj = {
    total_peekup_charge_revenue: {
      label: "Total Peekup charge Revenue",
    },

    total_ride_fare_revenue: {
      label: "Total Ride Revenue",
    },
  };
  const handleAreaChart = (overallSummary) => {
    let graph_data = overallSummary?.graph_data?.data ?? [];

    const sumsobj = {};
    // Parse the start and end dates
    const start = dayjs(dateFilter?.from_time);

    const end = dayjs(dateFilter?.to_time);

    // Calculate the difference in years, months, weeks, and days
    const diffYears = end.diff(start, "year");
    const diffMonths = end.diff(start, "month");
    const diffWeeks = end.diff(start, "week");
    const diffDays = end.diff(start, "day");

    const checkisCustom =
      dateFilter?.select === "CUSTOM" &&
      dateFilter?.from_time &&
      dateFilter?.to_time;

    const label = getLabel(diffYears, diffMonths, diffWeeks, diffDays);

    if (graph_data && Array?.isArray(graph_data)) {
      graph_data = graph_data?.map((res, index) => {
        Object.entries(res).forEach(([key, value]) => {
          if (key !== "date") {
            if (!sumsobj[key]) {
              sumsobj[key] = value;
            } else {
              if (index === graph_data.length - 1) {
                const Fixed = sumsobj[key] + value;

                sumsobj[key] = Fixed.toFixed(2);
              } else {
                sumsobj[key] += value;
              }
            }
          }
        });
        return {
          ...res,
          date: graphyXLabel(
            checkisCustom ? label : dateFilter.select,
            res?.date,
            formatDate,
            timezone
          ),
        };
      });
    }

    setAreaChart((prevState) => ({
      ...prevState,
      ...overallSummary,
      graph_data: {
        ...overallSummary?.graph_data,
        data: graph_data,
      },
    }));

    setSums(sumsobj);
  };

  const handleDateFilter = (name, value) => {
    if (name === "select" && value !== "CUSTOM") {
      setDateFilter((prev) => ({
        ...prev,
        [name]: value,
        from_time: null,
        to_time: null,
      }));

      return;
    }

    setDateFilter((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const queryBuilder = () => {
    const querys = [];

    const format = "DD-MM-YYYY";

    if (dateFilter.select && dateFilter.select !== "CUSTOM") {
      querys.push(`range=${dateFilter?.select}`);
    }

    if (
      dateFilter?.select === "CUSTOM" &&
      dateFilter?.from_time &&
      dateFilter?.to_time
    ) {
      const custom_start_date = formatDateWithDayJs(
        dateFilter?.from_time,
        format
      );

      const custom_end_date = formatDateWithDayJs(dateFilter?.to_time, format);

      querys.push(`range=${dateFilter?.select}`);
      querys.push(`custom_start_date=${custom_start_date}`);
      querys.push(`custom_end_date=${custom_end_date}`);
    }

    return querys.length > 0 ? `?${querys.join("&")}` : "";
  };

  useEffect(() => {
    const getFinanceAnalytics = async () => {
      const querys = queryBuilder();
      const endpoint = `/crm/finance/v2/summary/${querys}`;

      try {
        const response = await axiosInstance.get(endpoint);
        if (response.status === 200) {
          const analyticsData = response.data?.data;
          console.log(analyticsData);
          handleAreaChart(analyticsData);
          // setAreaChartData(analyticsData?.total_revenue.graph_data);
        } else {
          console.error(response.status);
        }
      } catch (error) {
        showErrorToast(error);
      }
    };

    if (dateFilter?.select !== "CUSTOM") {
      getFinanceAnalytics();
    } else if (dateFilter?.from_time && dateFilter?.to_time) {
      getFinanceAnalytics();
    }
  }, [dateFilter]);

  //css

  return (
    <Box sx={{ m: "1.5rem 1.5rem", boxSizing: "border-box" }}>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Box
          sx={{
            margin: "5px 0",
          }}
        >
          <CommonDateFilterPanel
            dateFilter={dateFilter}
            filterSelectOptions={SelectOptions}
            handleDateFilter={handleDateFilter}
          />
        </Box>
      </Box>

      <Box display={"flex"} flexDirection={"column"}>
        <Stack width={"100%"} mb={"20px"}>
          <Box display={"flex"} flexDirection={"column"} gap={"10px"}>
            <Box
              mt={"20px"}
              display={"flex"}
              gap={"10px"}
              sx={{
                background: "#fff",
                boxSizing: "border-box",
              }}
            >
              <Box width={"100%"}>
                <CustomAreaChart
                  legend={areaChart?.graph_data?.title ?? ""}
                  data={areaChart?.graph_data?.data ?? []}
                  options={areaChart?.graph_data?.options ?? []}
                  showShadow={false}
                />
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: "20px",
              mt: "20px",
            }}
          >
            {Object.entries(sums)?.map((res) => {
              console.log(res);
              return (
                <Box sx={{ flex: 1 }}>
                  <AnalyticsCard
                    svg={banknoteIcon}
                    headerText={displayText(dateFilter, res)}
                    value={res ? Number(res[1]) : 0}
                  />
                </Box>
              );
            })}
          </Box>

          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: "20px",
              mt: "20px",
            }}
          >
            {Object.entries(totalFinanceObj)?.map((res) => {
              return (
                <Box sx={{ flex: 1 }}>
                  <AnalyticsCard
                    svg={banknoteIcon}
                    headerText={res[1]?.label}
                    value={areaChart[res[0]]}
                  />
                </Box>
              );
            })}
          </Box>
          {/* <Box sx={flexContainer}>
            <Box sx={flexChildContainer}>
              <AnalyticsCard
                headerText={customerDataObj.todays_revenue.text}
                svg={customerDataObj.todays_revenue.svgIcon}
                value={customerData?.todays_revenue ?? 0}
              />
            </Box>
            <Box sx={flexChildContainer}>
              <AnalyticsCard
                headerText={customerDataObj.total_revenue.text}
                svg={customerDataObj.total_revenue.svgIcon}
                value={customerData?.total_revenue ?? 0}
              />
            </Box>
          </Box> */}
          {/* 
          <Box sx={flexContainer}>
            <Box sx={flexChildContainer}>
              <Stack direction={"row"} gap={"20px"}>
                <AnalyticsCard
                  headerText={topDriverCardData.total_earning.label}
                  svg={topDriverCardData.total_earning.svgIcon}
                  value={driverData?.total_earning ?? 0}
                />
                <AnalyticsCard
                  headerText={topDriverCardData.avg_earning.label}
                  svg={topDriverCardData.avg_earning.svgIcon}
                  value={driverData?.avg_earning ?? 0}
                />
              </Stack>
            </Box>

            <Box sx={flexChildContainer}>
              <Stack direction={"row"} gap={"20px"}>
                <AnalyticsCard
                  headerText={topDriverCardData.drivers_onboarded.label}
                  svg={topDriverCardData.drivers_onboarded.svgIcon}
                  value={driverData?.drivers_onboarded ?? 0}
                />
                <AnalyticsCard
                  headerText={topDriverCardData.due_appointment.label}
                  svg={topDriverCardData.due_appointment.svgIcon}
                  value={driverData?.due_appointment ?? 0}
                />
              </Stack>
            </Box>
          </Box> */}
        </Stack>
      </Box>
    </Box>
  );
};

export default FinanceManagement;
