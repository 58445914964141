import axiosInstance from "./axiosConfig";
import { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { setLogin } from "../state";

async function Refreshtoken(refreshToken) {
  const url = "/authentication/token/refresh/"; // Replace with your actual endpoint

  const body = {
    refresh: refreshToken.replace(/"/g, ""),
  };

  try {
    const response = await axiosInstance.post(url, body);
    if (response.status !== 200) {
      window.location.href = "/login";
      return;
    }
    return {
      token: response?.data?.access ?? "",
      refreshToken: response?.data?.refresh ?? "",
    };
  } catch (error) {
    console.log("redirect", error);
    window.location.href = "/login";
    return Promise.reject(error);
  }
}

const AxiosInterceptor = ({ children }) => {
  const dispatch = useDispatch();
  const { accessToken, refreshToken } = useSelector(
    (store) => ({
      accessToken: store?.auth?.token ?? "",
      refreshToken: store?.auth?.refreshToken ?? "",
    }),
    shallowEqual
  );

  const global = useSelector((store) => store.auth);
  const [interceptorSetupComplete, setInterceptorSetupComplete] =
    useState(false);

  useEffect(() => {
    const setupInterceptors = async () => {
      // Request interceptor to set the Authorization header
      axiosInstance.interceptors.request.use(
        async (config) => {
          const token = localStorage.getItem("token");
          // Add the Authorization header using the token from the Redux store
          if (!config._retry) {
            config.headers["Authorization"] = `Bearer ${token}`;
          }
          return config;
        },
        (error) => {
          console.error("Request interceptor error:", error);
          return Promise.reject(error);
        }
      );

      // Response interceptor to check response data
      axiosInstance.interceptors.response.use(
        async (response) => {
          const originalRequest = response.config;

          if (response?.data?.refresh_token && !originalRequest._retry) {
            const refreshResponse = await handleTokenRefresh(refreshToken);
            console.log(response, "response");
            if (refreshResponse) {
              handleTokenUpdate(refreshResponse, originalRequest, dispatch);
              return axiosInstance(originalRequest);
            }
          }

          return response;
        },
        async (error) => {
          const originalRequest = error.config;

          if (error?.response?.data?.refresh_token && !originalRequest._retry) {
            const refreshResponse = await handleTokenRefresh(refreshToken);
            if (refreshResponse) {
              handleTokenUpdate(refreshResponse, originalRequest, dispatch);
              return axiosInstance(originalRequest);
            }
          }

          return Promise.reject(error);
        }
      );

      setInterceptorSetupComplete(true);
    };

    const handleTokenRefresh = async (refreshToken) => {
      try {
        const response = await Refreshtoken(refreshToken);
        return response;
      } catch (refreshError) {
        dispatch(
          setLogin({
            token: null,
            refreshToken: null,
            user: null,
          })
        );

        window.location.href = "/login"; // Redirect to login on refresh error
        return null;
      }
    };

    const handleTokenUpdate = (refreshResponse, originalRequest) => {
      localStorage.setItem("token", refreshResponse.token);
      dispatch(setLogin({ ...global, ...refreshResponse }));
      originalRequest._retry = true;
      originalRequest.headers[
        "Authorization"
      ] = `Bearer ${refreshResponse.token}`;
    };

    const initializeInterceptors = async () => {
      if (accessToken) {
        await setupInterceptors();
      }
    };

    if (!interceptorSetupComplete) {
      initializeInterceptors();
    }
  }, [accessToken, interceptorSetupComplete, dispatch]);

  return interceptorSetupComplete ? children : !global?.token ? children : null;
};

// You can add more defaults or interceptors as needed
export { AxiosInterceptor };
