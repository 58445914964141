import { Box, Button, Typography } from "@mui/material";
import React, { useState } from "react";
import { showErrorToast, showSuccessToast } from "../utils/toastUtils";
import axiosInstance from "../utils/axiosConfig";
import { useNavigate } from "react-router-dom";
import TrainingCenterForm from "./TrainingCenterForm";
import usePermission from "../hooks/usePermission";

export function formatTimeFromISOString(dateString, changeformat) {
  const dateObject = new Date(dateString);

  const hours = dateObject.getHours();
  const minutes = dateObject.getMinutes();

  // Format the time in 12-hour format with AM/PM
  const formattedTime = `${hours % 12 || 12}:${
    minutes < 10 ? "0" : ""
  }${minutes}${hours < 12 ? "AM" : "PM"}`;

  if (changeformat) {
    const formattedTime = `${hours % 12 || 12}${
      minutes !== 0 ? `:${minutes < 10 ? "0" : ""}${minutes}` : ""
    }${hours < 12 ? " AM" : " PM"}`;

    return formattedTime;
  }

  return formattedTime;
}

export const checkIsObjectIsFilled = (obj) => {
  if (!obj) return;

  return Object.values(obj).every((res) => res);
};

const initialTraningCenterState = {
  training_center_name: "",
  training_center_latitude: "",
  training_center_longitude: "",
  training_center_address: "",
};

const NewTraningCenter = () => {
  const navigate = useNavigate();

  const permission = usePermission();

  const [trainingCenter, setTrainingCenter] = useState(
    initialTraningCenterState
  );

  const handleTrainingCenter = (e, propvalue) => {
    if (!e) return;
    // for timing if there is no e.target that means we will consider e as name
    // also propvalue as value
    const { name, value } = e?.target || { name: e, value: propvalue };

    setTrainingCenter((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const [slotOptions, setSlotOptions] = useState([
    {
      render_name: "Morning",
      start_time: "",
      end_time: "",
      default_capacity: "",
    },
  ]);

  const handleSlotOptions = (e, index) => {
    const { name, value } = e.target;

    setSlotOptions((prev) =>
      prev.map((slotoption, i) =>
        index === i ? { ...slotoption, [name]: value } : slotoption
      )
    );
  };

  const handleFromStartTime = (name, value, setState, index) => {
    if (!value) return;
    const toTime = slotOptions[index]?.end_time;
    if (toTime) {
      const fromTime = new Date(value);
      const totime = new Date(toTime);
      if (fromTime >= totime) {
        showErrorToast("To should be greater than From");
        return;
      }
    }

    setSlotOptions((prev) =>
      prev.map((slotoption, i) =>
        index === i ? { ...slotoption, [name]: value } : slotoption
      )
    );
  };

  const handleToEndTime = (name, value, setState, index) => {
    if (!value) return;
    console.log(index);
    const fromTime = slotOptions[index]?.start_time;

    if (fromTime) {
      const from = new Date(fromTime);
      const to = new Date(value);

      if (from >= to) {
        showErrorToast("To should be greater than From");
        return;
      }
    }

    setSlotOptions((prev) =>
      prev.map((slotoption, i) =>
        index === i ? { ...slotoption, [name]: value } : slotoption
      )
    );
  };

  function validateSlots(slotData = []) {
    if (slotData.length <= 1) return true;

    for (let i = 0; i < slotData.length; i++) {
      const { start_time, end_time } = slotData[i];

      // Check if both start and end time values exist
      if (!start_time || !end_time) {
        console.error(`Error: Slot at index ${i} has missing time values.`);
        return false; // Indicate error and stop further checks
      }

      const startTime = new Date(start_time);
      const endTime = new Date(end_time);

      for (let j = 0; j < slotData.length; j++) {
        if (i !== j) {
          const { start_time: otherStartTime, end_time: otherEndTime } =
            slotData[j];

          // Check if other slot also has both time values
          if (!otherStartTime || !otherEndTime) {
            console.error(`Error: Slot at index ${j} has missing time values.`);
            return false; // Indicate error and stop further checks
          }

          const otherStart = new Date(otherStartTime);
          const otherEnd = new Date(otherEndTime);

          // Check for overlap
          if (startTime < otherEnd && endTime > otherStart) {
            return false; // Overlap found
          }
        }
      }
    }

    return true; // No overlaps found
  }

  const createSlot = async () => {
    const url = "/crm/training-center-v2/";

    if (slotOptions.length < 1) {
      showErrorToast("At least one slot is required");
      return;
    }

    const isEverythingFilled = () => {
      return slotOptions.every((slotInfo) => {
        const requiredProperties = [
          "render_name",
          "start_time",
          "end_time",
          "default_capacity",
        ];

        return requiredProperties.every((property) => {
          return !!slotInfo[property]; // Check if property exists and has a truthy value
        });
      });
    };

    if (!isEverythingFilled()) {
      showErrorToast("Please fill all the details");
      return;
    }

    if (
      !Object.values(trainingCenter).every(
        (res) => res !== null && res !== undefined && res !== ""
      )
    ) {
      showErrorToast("Please fill all the details");
      return;
    }

    const isToGreterThenFrom = () => {
      return slotOptions.every((res) => {
        const FromTime = new Date(res["start_time"]);
        const ToTime = new Date(res["end_time"]);

        return FromTime <= ToTime;
      });
    };

    if (!isToGreterThenFrom()) {
      showErrorToast("To should be greater than From");
      return;
    }

    if (!validateSlots(slotOptions)) {
      showErrorToast("Slots are overlapping. Please set them properly.");
      return;
    }

    const slot_name_and_default_capacity = slotOptions.reduce(
      (acc, obj, index) => {
        const slotname = obj?.render_name?.toUpperCase();
        acc[slotname] = {
          render_name: obj?.render_name ?? "",
          order:
            (acc[obj.render_name] && acc[obj.render_name]["order"]) ??
            index + 1,
          render_time: `${formatTimeFromISOString(obj?.start_time, true)}`,
          start_time: formatTimeFromISOString(obj?.start_time),
          end_time: formatTimeFromISOString(obj?.end_time),
          default_capacity: obj?.default_capacity,
        };
        return acc;
      },
      {}
    );
    const body = {
      training_center_name: trainingCenter?.training_center_name,
      training_center_address: trainingCenter?.training_center_address,
      latitude: trainingCenter?.training_center_latitude,
      longitude: trainingCenter?.training_center_longitude,
      center_availability_in_days: trainingCenter?.center_availability_in_days,
      slot_name_and_default_capacity,
    };

    try {
      const response = await axiosInstance.post(url, body);

      // setSlotData(initialSlotData);
      setTrainingCenter({
        training_center_name: "",
        training_center_latitude: "",
        training_center_longitude: "",
        start_time: "",
        end_time: "",
        training_center_address: "",
        center_availability_in_days: "",
      });
      showSuccessToast("Slot Created Successfully!");
      navigate("/training-center");
    } catch (error) {
      showErrorToast(error);
    }
  };

  // css
  const containerStyle = {
    m: "1.5rem",
    width: "58%",
  };

  const flexStyle = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  };

  const closeIconCss = {
    fill: "#000",
  };

  return (
    <Box sx={containerStyle}>
      <Box
        sx={{
          ...flexStyle,
          justifyContent: "space-between",
          width: "100%",
          padding: "12px 25px",
          backgroundColor: "#fff",
          boxSizing: "border-box",
          textTransform: "capitalize",
          fontWeight: 600,
        }}
      >
        <Typography
          sx={{
            fontFamily: "Proxima Nova",
            color: "#000",
            fontSize: "25px",
            fontWeight: "600",
            fontStyle: "normal",
            letterSpacing: "0.4px",
            gap: "18px",
          }}
          variant="h2"
        >
          Add Center
        </Typography>
      </Box>

      <TrainingCenterForm
        handleTrainingCenter={handleTrainingCenter}
        trainingCenter={trainingCenter}
        slotOptions={slotOptions}
        setSlotOptions={setSlotOptions}
        handleSlotOptions={handleSlotOptions}
        handleFromStartTime={handleFromStartTime}
        handleToEndTime={handleToEndTime}
      />

      <Box
        sx={{
          padding: "12px 30px",
          display: "flex",

          alignItems: "center",
          boxSizing: "border-box",
          backgroundColor: "#fff",
        }}
      >
        {permission?.CREATE && (
          <Button
            variant="outlined"
            onClick={createSlot}
            sx={{
              width: "80%",
              margin: "0 auto",
              height: "50px",
              borderRadius: "12px",
              background: "#000",
              border: "none",
              color: "#fff",
              "&:hover": {
                background: "#000",
              },
            }}
          >
            Add Center
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default NewTraningCenter;
