import { Autocomplete, TextField } from "@mui/material";
import React from "react";

const CommonAutoComplete = ({ options, bankDetails, setBankDetails }) => {
  return (
    <Autocomplete
      freeSolo
      disablePortal
      id="combo-box-demo"
      fullWidth
      sx={{ padding: "0", height: "50px" }}
      options={options}
      value={bankDetails.bankName}
      onChange={(event, newValue) => {
        setBankDetails({
          ...bankDetails,
          bankName: newValue,
        });
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          type="text"
          variant="outlined"
          color="secondary"
          style={{ padding: "0", height: "50px" }}
          label="Bank Name"
          fullWidth
        />
      )}
    />
  );
};

export default CommonAutoComplete;
