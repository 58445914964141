import { Box, LinearProgress, Stack, Typography } from "@mui/material";
import React from "react";
import LogoSvg from "../assets/Logo.svg";
import peekupLogo from "../assets/peekup_logo_blue_color.svg";

const Loader = () => {
  const containerCss = {
    width: "100dvw",
    height: "100dvh",
    boxSizing: "border-box",
  };

  const centerBox = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  };

  return (
    <Box sx={containerCss}>
      <Box sx={centerBox}>
        <Stack
          alignItems={"center"}
          justifyContent={"center"}
          width={"auto"}
          gap={"29px"}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              ml: "20px",
            }}
          >
            <img
              src={peekupLogo}
              style={{
                display: "block",
              }}
              alt="logo img"
              width={"180px"}
              height={"auto"}
            />
          </Box>

          <LinearProgress
            sx={{
              width: "100%",
              color: "#4064ac",
              borderRadius: "12px",
            }}
          />
        </Stack>
      </Box>
    </Box>
  );
};

export default Loader;
