import React, { useState } from "react";
import { Box, Typography, Button } from "@mui/material";
import { handleUploadFileToApi } from "../utils/uploadimg";

const ImageUpload = ({
  selectedImage,
  setSelectedImage,
  setView,
  handleSaveProfile,
}) => {
  const [hover, setHover] = useState(false);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (
      file &&
      (file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "application/pdf")
    ) {
      const imageUrl = URL.createObjectURL(file);
      handleUploadFileToApi(file, handleSaveProfile);
      setSelectedImage({
        file,
        imgurl: imageUrl,
      });
    } else {
      alert("Please upload a valid .jpeg, .png, or .pdf file.");
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {selectedImage?.imgurl ? (
        <>
          <div
            style={{ position: "relative", cursor: "pointer" }}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            onClick={() => {
              setView(true);
            }}
          >
            <img
              src={selectedImage?.imgurl}
              alt="Uploaded Preview"
              style={{
                width: "100%",
                maxHeight: "100px",
                objectFit: "contain",
                height: "auto",
                borderRadius: "8px",
              }}
            />
            {hover && (
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  maxHeight: "100px",
                  bgcolor: "rgba(0, 0, 0, 0.4)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="body1"
                  color="white"
                  sx={{ fontWeight: "bold" }}
                >
                  View
                </Typography>
              </Box>
            )}
          </div>

          {/* Re-upload Button */}
          <Button
            variant="outlined"
            component="label"
            sx={{ mt: 2, width: "150px" }}
          >
            Re-upload
            <input
              type="file"
              accept="image/jpeg,image/png,application/pdf"
              hidden
              onChange={handleImageChange}
            />
          </Button>
        </>
      ) : (
        <label
          htmlFor="upload-button"
          style={{
            display: "block",
            padding: "20px",
            border: "2px dashed #ccc",
            borderRadius: "8px",
            textAlign: "center",
            width: "300px",
            cursor: "pointer",
          }}
        >
          <p>Upload</p>
          <input
            id="upload-button"
            type="file"
            accept="image/jpeg,image/png,application/pdf"
            style={{ display: "none" }}
            onChange={handleImageChange}
          />
        </label>
      )}
    </div>
  );
};

export default ImageUpload;
