// filtersSlice.js
import { createSlice } from "@reduxjs/toolkit";

const filtersSlice = createSlice({
  name: "filters",
  initialState: {
    booking: "",
    driverType: "",
    driverFromId :  "",
    tnvsType: "",
    appointmentStatus: "",
    cabType: "",
    joinedon: "",
    statusUpdate: "",
    date: {
      startDate: null,
      endDate: null,
    },
    gender: "",
    isOnline: "",
    trips: "",
    rating: "",
  },
  reducers: {
    setBooking: (state, action) => {
      state.booking = action.payload;
    },
    setTnvsType: (state, action) => {
      state.tnvsType = action.payload;
    },
    setDriverType: (state, action) => {
      state.driverType = action.payload;
    },
    setAppointmentStatus: (state, action) => {
      state.appointmentStatus = action.payload;
    },
    setCabType: (state, action) => {
      state.cabType = action.payload;
    },

    setJoinedOn: (state, action) => {
      state.joinedon = action.payload;
    },

    setDriverFromId  : (state, action) => {
      state.driverFromId = action.payload;
    },
    setStatusUpdate: (state, action) => {
      state.statusUpdate = action.payload;
    },
    setDate: (state, action) => {
      state.date = action.payload;
    },
    setGender: (state, action) => {
      state.gender = action.payload;
    },
    setIsOnline: (state, action) => {
      state.isOnline = action.payload;
    },
    setTrips: (state, action) => {
      state.trips = action.payload;
    },
    setRating: (state, action) => {
      state.rating = action.payload;
    },
    clearAllFilters: (state) => {
      state.booking = "";
      state.tnvsType = "";
      state.driverType = "";
      state.date = { startDate: null, endDate: null };
      state.gender = "";
      state.isOnline = "";
      state.trips = "";
      state.rating = "";
      state.joinedon = "";
      state.appointmentStatus = "";
      state.cabType = "";
      state.statusUpdate = "";
      state.driverFromId = ""
    },
  },
});

export const {
  setBooking,
  setDriverType,
  setTnvsType,
  setDate,
  setGender,
  setIsOnline,
  setTrips,
  setJoinedOn,
  setAppointmentStatus,
  setStatusUpdate,
  setCabType,
  setRating,
  setDriverFromId,
  clearAllFilters,
} = filtersSlice.actions;

export default filtersSlice.reducer;
