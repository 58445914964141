// Import necessary dependencies
import { createSlice } from "@reduxjs/toolkit";

// Define initial state
const initialState = {
  configValues: {
    google_api_key: process.env.REACT_APP_GOOGLE_API_KEY,
    server_base_url: process.env.REACT_APP_SERVER_BASE_URL,
    websocket_server_base_url: null,
  },
};

// Create a slice
const configSlice = createSlice({
  name: "config",
  initialState,
  reducers: {
    setConfigValues: (state, action) => {
      state.configValues = action.payload;
    },
  },
});

// Export the actions and reducer
export const { setConfigValues } = configSlice.actions;
export default configSlice.reducer;
