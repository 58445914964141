import { MenuItem, Select } from "@mui/base";
import { Box, IconButton, Button, Grid, Typography } from "@mui/material";
import { Stack, display } from "@mui/system";
import React, { useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router";
import OperatorProfile from "./operatorManagement/OperatorProfile";
import OperatorDetailsForm from "./operatorManagement/OperatorDetailsForm";
import { showErrorToast, showSuccessToast } from "../utils/toastUtils";
import axiosInstance from "../utils/axiosConfig";
import filterEmptyPropertiesRecursively from "../utils/filterEmptyPropertiesRecursively";
import usePermission from "../hooks/usePermission";

// container head
const NewOperatorHeader = ({ type, handleCreate }) => {
  // hooks
  const navigate = useNavigate();

  const permission = usePermission();

  // css
  const iconCss = {
    fill: "#000",
    width: "32px",
    height: "22px",
    boxSizing: "border-box",
  };

  const buttonStyles = {
    border: "1px solid #d9d9da",
    borderRadius: "10px",
    padding: "5px",
  };

  const createButtonStyles = {
    width: "150px",
    height: "32px",
    color: "#fff",
    backgroundColor: "#000",
    "&:hover": {
      backgroundColor: "#000",
    },
  };

  return (
    <Stack
      direction={"row"}
      width={"100%"}
      alignItems={"center"}
      justifyContent={"space-between"}
      boxSizing={"border-box"}
    >
      <IconButton
        sx={buttonStyles}
        onClick={() => {
          // Using the navigate hook to go back
          navigate(-1);
        }}
      >
        <ArrowBackIcon sx={iconCss} />
      </IconButton>

      <Stack direction={"row"} alignItems={"center"}>
        {permission?.CREATE && (
          <Button sx={createButtonStyles} onClick={handleCreate}>
            Create
          </Button>
        )}
        {type !== "new" && (
          <IconButton>
            <MoreVertIcon sx={iconCss} />
          </IconButton>
        )}
      </Stack>
    </Stack>
  );
};

const intialOperatorDetails = {
  operatorName: "",
  operatorNumber: "",
  operatorEmail: "",
  address: {
    "House number": "",
    Barangay: "",
    City: "",
    "Postal code": "",
  },
  password: "",
};

// main component
const NewOperator = () => {
  const navigate = useNavigate();

  // state
  const [operatorDetails, setOperatorDetails] = useState(intialOperatorDetails);

  const [profilePic, setProfilePic] = useState({
    profilePic: "",
    imageId: "",
  });

  const handleUploadFileToApi = (file) => {
    const apiUrl = "/utils/file/";
    const formData = new FormData();

    const options = {
      headers: {
        "Content-Type": "multipart/form-data", // This is important for form data
      },
    };
    formData.append("file", file);
    formData.append("folder", "public/");

    axiosInstance.post(apiUrl, formData, options).then((res) => {
      setProfilePic((prev) => ({ ...prev, imageId: res.data.data.id }));
    });
  };

  // function
  const handleOperatorDetailsChange = (name, value) => {
    setOperatorDetails((prevDetails) => ({ ...prevDetails, [name]: value }));
  };

  const handleAddressChange = (name, value) => {
    setOperatorDetails((prevDetails) => ({
      ...prevDetails,
      address: {
        ...prevDetails.address,
        [name]: value,
      },
    }));
  };

  function isObjectEmpty(obj) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (obj[key] === null || obj[key] === undefined || obj[key] === "") {
          // Property is empty, return true
          return true;
        }

        if (typeof obj[key] === "object" && !Array.isArray(obj[key])) {
          // Recursively check nested object
          if (isObjectEmpty(obj[key])) {
            return true;
          }
        }
      }
    }

    // No empty properties found
    return false;
  }

  // validation funtion
  const validateEmail = (email) => {
    const emailPattern = /^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/;
    // const emailIsValid = emailPattern.test(email);
    const emailIsValid = emailPattern.test(email);
    return emailIsValid;
  };

  const validatePhoneNumber = (phoneNumber) => {
    const phoneNumberPattern = /^\d{10}$/;
    const numberIsValid = phoneNumberPattern.test(phoneNumber);
    return numberIsValid;
  };

  const validateInput = (value, type) => {
    switch (type) {
      case "email":
        return validateEmail(value);
      case "phoneNumber":
        return validatePhoneNumber(value);
      default:
        return false;
    }
  };

  const handleCreate = async () => {
    const url = "/crm/operator/";

    const isEmpty = isObjectEmpty(operatorDetails);

    const checkProfiePic = profilePic.profilePic ? true : false;

    if (!checkProfiePic) {
      showErrorToast("Please Upload Profile Picture!");
      return;
    }

    if (isEmpty) {
      showErrorToast("Please fill in all the details!");
      return;
    }

    const operatorEmailValid = validateInput(
      operatorDetails.operatorEmail,
      "email"
    );
    const operatorNumberValid = validateInput(
      operatorDetails.operatorNumber,
      "phoneNumber"
    );

    if (!operatorEmailValid) {
      showErrorToast("Invalid email format");
      return;
    }

    if (!operatorNumberValid) {
      showErrorToast("Invalid phone number format");
      return;
    }

    const obj = {
      profile_pic_id: profilePic.imageId,
      full_name: operatorDetails?.operatorName,
      phone: operatorDetails?.operatorNumber
        ? "+63" + operatorDetails?.operatorNumber
        : "",
      email: operatorDetails?.operatorEmail,
      operator_name: operatorDetails?.operatorName,
      address: {
        house_number: operatorDetails?.address["House number"],
        barangay: operatorDetails?.address["Barangay"],
        city: operatorDetails?.address["City"],
        postal_code: operatorDetails?.address["Postal code"],
      },
      password: operatorDetails.password,
    };

    const filterObj = filterEmptyPropertiesRecursively(obj);

    try {
      const response = axiosInstance.post(url, filterObj);

      showSuccessToast("Operator Created Successfully!");
      navigate("/operator-management");
      setOperatorDetails(intialOperatorDetails);
      setProfilePic({
        profilePic: "",
        imageId: "",
      });
    } catch (error) {
      showErrorToast(error);
    }
  };

  // config

  const addressConfig = {
    labeltext: "Address",
    name: ["House number", "Barangay", "City", "Postal code"],
    value: operatorDetails.address,
    placeholder: ["House number", "Barangay", "City", "Postal code"],
    type: {
      "House number": "text",
      Barangay: "text",
      City: "text",
      "Postal code": "text",
    },
    handleChange: handleAddressChange,
  };

  //  css
  const containerCss = {
    m: "1.5rem 1.5rem",
    paddingBottom: "15px",
  };

  const titleStyle = {
    fontFamily: "Proxima Nova",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
    color: "#000",
    m: "25px 0",
  };

  return (
    <Box sx={containerCss}>
      <NewOperatorHeader type={"new"} handleCreate={handleCreate} />

      <Typography sx={titleStyle}>Operator Details</Typography>

      <OperatorProfile
        profilePic={profilePic}
        setProfilePic={setProfilePic}
        handleUploadFileToApi={handleUploadFileToApi}
      />

      <OperatorDetailsForm
        passwordEnable={true}
        handleAddressChange={handleAddressChange}
        handleOperatorDetailsChange={handleOperatorDetailsChange}
        operatorDetails={operatorDetails}
        addressConfig={addressConfig}
      />
    </Box>
  );
};

export default NewOperator;
