import { Avatar, Button, Typography } from "@mui/material";
import classes from "../styles/chat.module.css";
import { Box, Stack } from "@mui/system";
import styles from "../styles/chat.module.css";

const Image = ({ url }) => {
  return (
    <img
      src={url}
      width={"100%"}
      height={"100%"}
      style={{
        objectFit: "cover",
        cursor: "pointer",
        boxShadow:
          "0 1px 1px rgba(0,0,0,0.15), 0 2px 2px rgba(0,0,0,0.15), 0 4px 4px rgba(0,0,0,0.15), 0 8px 8px rgba(0,0,0,0.15)",
      }}
      alt="img"
    />
  );
};

const MessageLeft = ({
  name,
  profilePic,
  refund,
  date,
  time,
  userImgUrl,
  BootstrapTooltip,
  ticketStatus,
  label,
  imgurl,
  setShowImage,
}) => {
  return (
    <div className={classes.messageRow} style={{ gap: "10px" }}>
      <Box>
        <Avatar
          src={profilePic}
          sx={{
            width: "43px",
            height: "43px",
          }}
        />
      </Box>
      <div className={styles.detailContainer}>
        <Box>
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Proxima Nova",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "130%",
                color: "#000",
              }}
            >
              {name}
            </Typography>
            <Box
              sx={{
                padding: "3.835px 12.088px",
                width: "88px",
                borderRadius: "21.973px",
                backgroundColor: "#0182F9",
                justifyContent: "center",
                color: "#fff",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Proxima Nova",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "130%",
                }}
              >
                {ticketStatus}
              </Typography>
            </Box>
          </Box>

          <Box display={"flex"} alignItems={"flex-end"} gap={"5px"}>
            <Typography
              sx={{
                fontFamily: "Proxima Nova",
                fontSize: "15px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "130%",
              }}
            >
              {date}
            </Typography>
            <Typography
              sx={{
                fontFamily: "Proxima Nova",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "130%",
                color: "#6F747E",
              }}
            >
              {time}
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            padding: "10px",
            background: "#F6F8FA",
            borderRadius: "4px",
            boxSizing: "border-box",
          }}
        >
          <Stack gap={"15px"}>
            {refund && (
              <div className={styles.ticket}>
                <BootstrapTooltip title={refund}>
                  <p
                    style={{
                      fontSize: "15px",
                      maxWidth: "220px",
                      color: "#FFF",
                      overflow: "hidden",
                      height: "23px",
                      display: "inline",
                      textOverflow: "ellipsis",
                      cursor: "pointer",
                      minWidth: "80px",
                      margin: "0",
                    }}
                  >
                    {refund}
                  </p>
                </BootstrapTooltip>
                <div className={styles.cuts}>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            )}

            {userImgUrl && (
              <Box
                sx={{
                  boxSizing: "border-box",
                }}
              >
                <Button
                  sx={{
                    padding: "0",
                    width: "100px",
                    height: "100px",
                    margin: "0",
                    boxSizing: "border-box",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowImage({
                      show: true,
                      url: userImgUrl,
                    });
                  }}
                >
                  <Image url={userImgUrl} />
                </Button>
              </Box>
            )}
            <Typography
              sx={{
                width: "270px",
                fontFamily: "Proxima Nova",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "130%",
              }}
            >
              {label}
            </Typography>
          </Stack>
        </Box>
      </div>
    </div>
  );
};

export default MessageLeft;
