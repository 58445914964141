import React, { useCallback, useEffect, useReducer, useState } from "react";
import RatingAndReviewSidePanelFilter from "./RatingAndReviewSidePanelFilter";
import {
  Avatar,
  Box,
  Button,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { showErrorToast, showSuccessToast } from "../utils/toastUtils";
import axiosInstance from "../utils/axiosConfig";
import CloseIcon from "@mui/icons-material/Close";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { formatDateNew } from "../utils/formateDate";
import convertTimestampTo12HourFormat, {
  convertTo24HourFormat,
} from "../utils/convertTimestampTo12HourFormat";
import starSvg from "../assets/Star.svg";
import CustomConfirmationDialog from "./CustomConfirmationDialog";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import {
  incrementPage,
  setDriverDetails,
  setPage,
  setRatingAndReviewSidePanel,
} from "../state/ratingAndReviewSidePanelSlice";
import { clearFilters } from "../state/ratingAndReviewSidePanelFilterSlice";
import { useSettings } from "../hooks/useSettings";
import { removeQuery } from "../utils/queryHelpers";
import { useSearchParams } from "react-router-dom";

const RatingAndReviewSidePanel = ({
  ratingAndReviewId,
  toggleDrawer,
  ratingAndReviewData,
  setRatingAndReviewData,
  showRatingAndreviewsPanel,
  isAuthorized,
}) => {
  const [showDelete, setShowDelete] = useState(false);
  const { formatDate, timezone, timeFormat, dateformat } = useSettings();
  const [searchParams, setSearchParams] = useSearchParams();

  const dispatch = useDispatch();

  const [deleteId, setDeleteId] = useState("");

  const [prevScrollTop, setPrevScrollTop] = useState(0);
  const { rating, review, ratingDate, selectedTime, endTime, ratingDay } =
    useSelector((store) => store.ratingAndReviewSidePanelFilter);

  const { ratingAndReviewSidePanel, driverDetails, page } = useSelector(
    (store) => store.ratingAndReviewSidePanel
  );

  const buildQueryString = (download = false) => {
    const queryParameters = [];

    if (rating) {
      queryParameters.push(`rating=${rating}`);
    }

    if (review) {
      queryParameters.push(`rating_and_reviews=${review}`);
    }

    if (ratingDay) {
      queryParameters.push(`rating_day=${ratingDay}`);
    }
    if (ratingDate?.startDate && ratingDate?.endDate) {
      let startDate = formatDateNew(ratingDate?.startDate ?? "");
      let endDate = formatDateNew(ratingDate?.endDate ?? "");
      queryParameters.push(`date_gte=${startDate}&date_lte=${endDate}`);
    }

    if (selectedTime && endTime) {
      queryParameters.push(
        `min_rating_time=${convertTo24HourFormat(
          selectedTime
        )}&max_rating_time=${convertTo24HourFormat(endTime)}`
      );
    }

    const queryString =
      queryParameters.length > 0 ? `&${queryParameters.join("&")}` : "";

    return queryString;
  };

  // funtion to show ratings and reviews panel filter
  const handleRatingAndReviewsClick = (payload) => {
    dispatch(setRatingAndReviewSidePanel(payload));
  };

  // function to fetch rating and reviews by id
  const getRatingAndReviewsById = async (
    deleteBool = false,
    filterbool = false
  ) => {
    const query = buildQueryString();
    if (!ratingAndReviewId) return;
    if (!showRatingAndreviewsPanel.right) return;
    let url = `/crm/reviews/?driver_id=${ratingAndReviewId}&page=${
      deleteBool ? 1 : filterbool ? 1 : page
    }&limit=${5}${query}`;
    try {
      const resposne = await axiosInstance.get(url);

      if (resposne) {
        let data = resposne?.data?.data?.records ?? [];
        if (deleteBool) {
          dispatch(setPage(1));
          setRatingAndReviewData(data);
        } else {
          setRatingAndReviewData((prev) => {
            // Create a Set to store unique IDs
            const idSet = new Set(prev.map((item) => item.id));

            // Filter out duplicates from the new data based on ID
            const newDataFiltered = data.filter((item) => !idSet.has(item.id));

            // Merge the filtered new data with the previous data
            return [...prev, ...newDataFiltered];
          });
        }

        if (!filterbool) {
          dispatch(incrementPage());
        }
        if (
          !driverDetails?.driverName ||
          !driverDetails?.driverTier ||
          !driverDetails?.cabType
        ) {
          dispatch(
            setDriverDetails({
              driverName: data[0]?.driver_name ?? "",
              driverTier: data[0]?.driver_tier ?? "",
              cabType: data[0]?.cab_type ?? "",
            })
          );
        }
      }
    } catch (error) {
      showErrorToast(error);
    }
  };

  // funtion to delete rating
  const deleteRating = async () => {
    const url = `/crm/reviews/${deleteId}/`;
    try {
      let response = await axiosInstance.delete(url);
      if (response) {
        showSuccessToast("delete successfully");
        getRatingAndReviewsById(true);
      }
    } catch (error) {
      showErrorToast(error);
    }
  };

  const handleScroll = () => {
    const scrollableDiv = document.getElementById("scrollableDiv");
    if (scrollableDiv) {
      const { scrollTop, clientHeight, scrollHeight } = scrollableDiv;
      if (
        scrollTop + clientHeight >= scrollHeight - 20 &&
        scrollTop > prevScrollTop
      ) {
        // Fetch more data only when scrolling down and at the bottom
        getRatingAndReviewsById();
      }
      setPrevScrollTop(scrollTop);
    }
  };

  const handleFilter = async () => {
    setRatingAndReviewData([]);
    dispatch(setPage(1));
    await getRatingAndReviewsById(false, true);

    // Increment the page after the API call
    dispatch(incrementPage());
  };



  useEffect(() => {
    setRatingAndReviewData([]);
    if (ratingAndReviewId) {
      dispatch(setPage(1));
      getRatingAndReviewsById();
    }
  }, [ratingAndReviewId, showRatingAndreviewsPanel]);

  useEffect(() => {
    const scrollableDiv = document.getElementById("scrollableDiv");

    let timeoutId;

    const handleScrollWithDelay = () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      timeoutId = setTimeout(() => {
        handleScroll();
      }, 200); // Adjust the delay as needed
    };

    if (scrollableDiv) {
      scrollableDiv.addEventListener("scroll", handleScrollWithDelay);
    }

    return () => {
      if (scrollableDiv) {
        scrollableDiv.removeEventListener("scroll", handleScrollWithDelay);
      }
    };
  }, [page, ratingAndReviewId, prevScrollTop]);

  return (
    <>
      {ratingAndReviewSidePanel && (
        <RatingAndReviewSidePanelFilter
          handleFilterClick={handleRatingAndReviewsClick}
          handleDone={handleFilter}
        />
      )}
      <Box
        sx={{
          width: "446px",
          height: "1099px",
          background: "#FFF",
          padding: "10px",
          boxSizing: "border-box",
          boxShadow: "-4px 4px 18px 0px rgba(0, 0, 0, 0.07)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              if (ratingAndReviewSidePanel) {
                handleRatingAndReviewsClick(false);
              }

              dispatch(
                setDriverDetails({
                  driverName: "",
                  driverTier: "",
                  cabType: "",
                })
              );

              dispatch(setPage(1));
              setRatingAndReviewData([]);
              dispatch(clearFilters());
              removeQuery("ratingDrawer", setSearchParams)
          removeQuery("id", setSearchParams)
              toggleDrawer("right", false)(e);
            }}
          >
            <CloseIcon
              sx={{
                fill: "#000",
              }}
            />
          </IconButton>
        </Box>

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Box>
            <Stack gap="8px">
              <Typography
                sx={{
                  color: "var(--text-dark, #000)",
                  fontFamily: "Proxima Nova, sans-serif",
                  fontSize: 18,
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "normal",
                  letterSpacing: "0.36px",
                  textTransform: "capitalize",
                }}
              >
                {driverDetails?.driverName ?? ""}
              </Typography>
              <Typography
                sx={{
                  color: "var(--text-sub-text, #6F747E)",
                  fontFamily: "Proxima Nova, sans-serif",
                  fontSize: 16,
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "normal",
                  letterSpacing: "0.32px",
                  textTransform: "capitalize",
                }}
              >
                {`${driverDetails?.driverTier} , ${
                  driverDetails?.cabType ?? ""
                }`}{" "}
              </Typography>
            </Stack>
          </Box>
          <Button
            endIcon={<ArrowDropDownIcon />}
            onClick={(e) => {
              e.stopPropagation();
              handleRatingAndReviewsClick(true);
            }}
            sx={{
              width: "auto",
              height: 32,
              padding: "6px 15px",
              justifyContent: "space-between",
              alignItems: "center",
              flexShrink: 0,
              borderRadius: 12,
              border: "1px solid var(--text-30, rgba(0, 0, 0, 0.30))",
              background: "#FFF",
              color: "#000",
              fontFamily: "Proxima Nova, sans-serif",
              fontSize: 15,
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "normal",
            }}
          >
            Filter By
          </Button>
        </Stack>

        <Box
          marginTop={"18px"}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "12px",
            height: ratingAndReviewData.length > 4 ? "82vh" : "auto",
            overflowY: ratingAndReviewData.length > 4 ? "scroll" : "hidden",
            overflowX: "hidden",
          }}
          id="scrollableDiv"
        >
          {ratingAndReviewData.length > 0 &&
            ratingAndReviewData.map((res) => (
              <Box
                sx={{
                  width: "414px",
                  height: "auto",
                  borderRadius: 5,
                  padding: "10px 21px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "8px",
                  boxSizing: "border-box",
                  border: "1px solid #CDCDCD",
                  background: "#FFF",
                }}
                key={res.id}
              >
                <Stack
                  direction="row"
                  width="100%"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography
                    sx={{
                      color: "var(--text-70, rgba(0, 0, 0, 0.70))",
                      fontFamily: "Proxima Nova, sans-serif",
                      fontSize: 14,
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "normal",
                      letterSpacing: "0.28px",
                      textTransform: "capitalize",
                    }}
                  >
                    Booking ID : {res?.booking_id ?? ""}
                  </Typography>
                  {/* {isAuthorized && (
                      <Button
                        sx={{
                          color: "var(--system-red, #F1554C)",
                          textAlign: "right",
                          fontFamily: "Proxima Nova, sans-serif",
                          fontSize: 14,
                          fontStyle: "normal",
                          fontWeight: 500,
                          lineHeight: "20px",
                          textDecorationLine: "underline",
                        }}
                        variant="text"
                        onClick={() => {
                          setDeleteId(res.id);
                          setShowDelete(true);
                        }}
                      >
                        Delete
                      </Button>
                    )} */}
                </Stack>
                <Stack
                  direction="row"
                  width="100%"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography
                    sx={{
                      color: "var(--text-dark, #000)",
                      fontFamily: "Proxima Nova, sans-serif",
                      fontSize: 16,
                      fontStyle: "normal",
                      fontWeight: 500,
                      lineHeight: "normal",
                      letterSpacing: "0.32px",
                      textTransform: "capitalize",
                    }}
                  >
                    {res?.customer_name ?? ""}
                  </Typography>
                  <Stack direction="row" alignItems="center" gap="10px">
                    <Typography
                      sx={{
                        color: "var(--font-light-70, rgba(15, 24, 40, 0.70))",
                        textAlign: "right",
                        fontFamily: "Proxima Nova, sans-serif",
                        fontSize: 14,
                        fontStyle: "normal",
                        fontWeight: 400,
                        lineHeight: "20px",
                      }}
                    >
                      {res?.created_at &&
                        formatDate(res?.created_at, timezone, dateformat)}
                    </Typography>

                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="4"
                      height="4"
                      viewBox="0 0 4 4"
                      fill="none"
                    >
                      <circle cx="2" cy="2" r="2" fill="#0F1828" />
                    </svg>
                    <Typography
                      sx={{
                        color: "var(--font-light-70, rgba(15, 24, 40, 0.70))",
                        textAlign: "right",
                        fontFamily: "Proxima Nova, sans-serif",
                        fontSize: 14,
                        fontStyle: "normal",
                        fontWeight: 400,
                        lineHeight: "20px",
                      }}
                    >
                      {res?.created_at &&
                        formatDate(res?.created_at, timezone, timeFormat)}
                    </Typography>
                  </Stack>
                </Stack>
                <Stack direction="row" alignItems="center">
                  {res?.rating &&
                    new Array(res?.rating ?? 0).fill("").map((_, index) => (
                      <Avatar
                        key={index}
                        sx={{
                          width: "15px",
                          height: "15px",
                        }}
                        src={starSvg}
                      />
                    ))}

                  <Typography
                    sx={{
                      color: "var(--font-light-70, rgba(15, 24, 40, 0.70))",
                      fontFamily: "Proxima Nova, sans-serif",
                      fontSize: 14,
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "20px",
                      marginLeft: "5px",
                    }}
                  >
                    {" "}
                    {`(${res?.rating} Star)`}
                  </Typography>
                </Stack>

                <Typography
                  sx={{
                    width: 382,
                    color: "rgba(15, 24, 40, 0.70)",
                    fontFamily: "Proxima Nova, sans-serif",
                    fontSize: 16,
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "130%",
                  }}
                >
                  {res?.review ?? ""}
                </Typography>
              </Box>
            ))}
        </Box>
      </Box>

      <CustomConfirmationDialog
        open={showDelete}
        setOpen={setShowDelete}
        status="delete"
        deleteById={deleteRating}
        message="Are you sure you want to delete this account?"
      />
    </>
  );
};

export default RatingAndReviewSidePanel;
