import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Box, Stack, TextField, Typography } from "@mui/material";

const options = [
  { value: "true", label: "Yes" },
  { value: "false", label: "New Applicant" },
];

export default function ControlledRadioButtonsGroup({
  data = [],
  setDriverType,
  setOpertaorName,
  driverType,
  operatorName,
  basicDetails,
  setBasicDetails,
  setValidateLtfrb,
}) {
  const handleRadioChange = (event) => {
    setDriverType(event.target.value);
    setOpertaorName(""); // Reset the selected option when changing the radio option
    setBasicDetails({
      ...basicDetails,
      tnvsCheck: "",
      operator_full_name: "",
    });
  };

  const handleSelectChange = (event) => {
    if (driverType !== "VIA_OPERATOR") {
      setDriverType("VIA_OPERATOR");
    }
    setOpertaorName(event.target.value);
  };

  return (
    <Box>
      <Typography
        sx={{
          color: "#6F747E",
          fontFamily: "Proxima Nova",
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: "600",
          marginBottom: "2px",
          lineHeight: "normal",
        }}
      >
        Operator Type
      </Typography>
      <FormControl component="fieldset">
        <FormControlLabel
          control={<Radio />}
          label="TNVS Operator"
          value="INDIVIDUAL"
          checked={driverType === "INDIVIDUAL"}
          onChange={handleRadioChange}
        />

        {driverType === "INDIVIDUAL" && (
          <Stack paddingLeft={"31px"}>
            <Typography>Are you an existing TNVS driver?</Typography>
            <RadioGroup
              value={basicDetails?.tnvsCheck}
              onChange={(e) => {
                setBasicDetails({
                  ...basicDetails,
                  tnvsCheck: e.target.value,
                });

                setValidateLtfrb(e.target.value === "false" ? true : null);
              }}
            >
              <Stack direction={"row"}>
                {options.map((option) => (
                  <FormControlLabel
                    key={option.value}
                    value={option.value}
                    label={option.label}
                    control={<Radio />}
                  />
                ))}
              </Stack>
            </RadioGroup>
          </Stack>
        )}

        {driverType === "INDIVIDUAL" && (
          <TextField
            type="text"
            variant="outlined"
            color="secondary"
            label={"Enter Operator Full Name"}
            value={basicDetails.operator_full_name}
            onChange={(e) => {
              setBasicDetails({
                ...basicDetails,
                operator_full_name: e.target.value,
              });
            }}
            sx={{ m: "5px 0 16px 31px", width: "285px" }}
          />
        )}
        <FormControlLabel
          control={<Radio />}
          label={
            <div>
              <Select
                value={operatorName}
                onChange={handleSelectChange}
                displayEmpty
                sx={{ border: "none", outline: "none", width: "285px" }}
                inputProps={{ "aria-label": "select-option" }}
              >
                <MenuItem value="" disabled>
                  TAXI Operator
                </MenuItem>
                {data.map((option) => (
                  <MenuItem value={option?.id} key={option.operator_name}>
                    {option.operator_name}
                  </MenuItem>
                ))}
              </Select>
            </div>
          }
          value="VIA_OPERATOR"
          checked={driverType === "VIA_OPERATOR"}
          onChange={handleRadioChange}
        />
      </FormControl>
    </Box>
  );
}
