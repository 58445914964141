import { Box } from "@mui/material";
import React from "react";

//css
const containerStyle = {
  boxSizing: "border-box",
  width: "100%",
  margin: "20px 0px",
  objectFit: "cover",
  height: "fit-content",
  padding: "0px  20px",
};

// main component
const Banner = ({ url }) => {
  return url ? (
    <Box sx={containerStyle}>
      <img src={url} width={"100%"} height={"100%"} alt="banner" />
    </Box>
  ) : null;
};

export default Banner;
