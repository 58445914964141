import { Avatar, Box, Stack, Typography } from "@mui/material";
import React from "react";
import formatNumberShort from "../utils/formatNumberShort";

const AnalyticsCard = ({
  svg,
  format = false,
  headerText,
  background = "#fff",
  value = 0,
  boxshadow = true,
  handleClick = (e) => {},
  cursorType = "default",
}) => {
  return (
    <Box
      sx={{
        width: "100%",
        background: background,
        boxSizing: "border-box",
        height: {
          xs: "auto", // adjust for extra small screens
          md: "163px", // medium screens and up
        },
        padding: {
          xs: "15px",
          md: "20px",
        },
        borderRadius: "12px",
        display: "flex",
        flexDirection: "column",
        gap: {
          xs: "20px",
          md: "38px",
        },
        boxShadow: boxshadow
          ? "0px 0px 8px 0px rgba(150, 150, 150, 0.10)"
          : "0",
        cursor: cursorType,
      }}
      onClick={(e) => {
        handleClick(e, headerText);
      }}
    >
      <Stack direction={"row"} gap={"18px"} alignItems={"center"}>
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          sx={{
            background: "rgba(0, 0, 0, 0.07)",
            padding: "10px",
            borderRadius: "50px",
          }}
        >
          <Avatar
            sx={{
              width: "24px",
              height: "24px",
            }}
            src={svg}
          ></Avatar>
        </Box>

        <Typography
          dangerouslySetInnerHTML={{ __html: headerText }} sx={{
            color: "var(--text-70, rgba(0, 0, 0, 0.70))",
            fontFamily: "Proxima Nova",
            fontSize: {
              xs: "16px",
              md: "20px",
            },
            fontStyle: "normal",
            fontWeight: 500,
            textTransform: "capitalize",
            lineHeight: "normal",
          }}
        >
          
        </Typography>
      </Stack>

      <Typography
        sx={{
          color: "var(--Text-dark, #000)",
          fontFamily: "Proxima Nova",
          fontSize: {
            xs: "24px",
            md: "34px",
          },
          fontStyle: "normal",
          fontWeight: 600,
          lineHeight: "normal",
          letterSpacing: "0.051px",
        }}
      >
        {value === 0 ? value : format ? value : formatNumberShort(value)}
      </Typography>
    </Box>
  );
};

export default AnalyticsCard;
