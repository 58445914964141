import React from "react";
import SearchBar from "./SearchBar";
import FilterByButton, { SortByButton } from "../FilterByButton";
import { Box, Button } from "@mui/material";
import usePermission from "../../hooks/usePermission";

const SearchActionComponent = ({
  value,
  setState,
  placeholder,
  children,
  toggleDrawer,
  toggleSortDrawer,
  downloadFile,
  require = { search: true, filter: true, download: false, sort: false },
}) => {
  const permissions = usePermission();

  return (
    <Box
      sx={{
        display: "flex",
        gap: "15px",
        alignItems: "center",
      }}
    >
      {require.search && (
        <SearchBar
          value={value}
          setState={setState}
          placeholder={placeholder}
        />
      )}
      {require.filter && <FilterByButton toggleDrawer={toggleDrawer} />}

      {require.sort && <SortByButton toggleDrawer={toggleSortDrawer} />}

      {require.download && permissions?.DOWNLOAD && (
        <Button
          sx={{
            width: "110px",
            border: "1px solid rgba(0, 0, 0, 0.30)",
            background: "#fff",
            borderRadius: "12px",
            fontSize: "15px",
            height: "42px",
            fontFamily: "Proxima Nova",
            color: "#000",
            fontWeight: 600,
            lineHeight: "normal",
          }}
          variant="Primary"
          onClick={() => downloadFile()}
        >
          Download
        </Button>
      )}
      {children}
    </Box>
  );
};

export default SearchActionComponent;
