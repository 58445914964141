import React from "react";
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import Overview from "../assets/Overview.svg";
import Customers from "../assets/Customers.svg";
import DriverIcon from "../assets/DriverIcon.svg";
import SupportIcon from "../assets/SupportIcon.svg";
import Admin from "../assets/Admin.svg";
import { useSelector } from "react-redux";
import vector from "../assets/PeekUpWhiteLogo.webp";

const svgIcons = {
  Overview,
  Customer: Customers,
  Drivers: DriverIcon,
  Support: SupportIcon,
  Admin: Admin,
  Flagged: Overview,
};

const alt = {
  Overview: "overview logo",
  Customer: "customer logo",
  Drivers: "driver logo",
  Support: "support logo",
  Admin: "Admin Logo",
};

const navItems = [
  {
    text: "Overview",
    icon: (
      <Box
        component="img"
        sx={{
          height: 25,
          width: 25,
          maxHeight: { xs: 200, md: 167 },
          maxWidth: { xs: 200, md: 250 },
        }}
        alt="The house from the offer."
        src={Overview}
      />
    ),
    url: "overview",
  },
  {
    text: "Customer",
    icon: (
      <Box
        component="img"
        sx={{
          height: 25,
          width: 25,
          maxHeight: { xs: 200, md: 167 },
          maxWidth: { xs: 200, md: 250 },
        }}
        alt="The house from the offer."
        src={Customers}
      />
    ),
    url: "customer",
  },
  {
    text: "All Customers",
    icon: null,
    url: "customer",
  },
  // {
  //   text: "Customer Sos",
  //   icon: null,
  //   url: "customer-sos",
  // },
  {
    text: "Trips",
    icon: null,
    url: "customer-trips",
  },
  {
    text: "Payments",
    icon: null,
    url: "customer-payments",
  },
  {
    text: "Drivers",
    icon: (
      <Box
        component="img"
        sx={{
          height: 25,
          width: 25,
          maxHeight: { xs: 200, md: 167 },
          maxWidth: { xs: 200, md: 250 },
        }}
        alt="The house from the offer."
        src={DriverIcon}
      />
    ),
    url: "driver",
  },
  {
    text: "All Drivers",
    icon: null,
    url: "driver",
  },
  {
    text: "Ratings & Reviews",
    icon: null,
    url: "ratings-&-reviews",
  },
  {
    text: "Driver Trips",
    icon: null,
    url: "driver-trips",
  },
  {
    text: "Earnings & Settlements",
    icon: null,
    url: "earnings-&-settlements",
  },
  {
    text: "Appointments",
    icon: null,
    url: "appointments",
  },
  {
    text: "Training Center",
    icon: null,
    url: "training-center",
  },
  {
    text: "Support",
    icon: (
      <Box
        component="img"
        sx={{
          height: 25,
          width: 25,
          maxHeight: { xs: 200, md: 167 },
          maxWidth: { xs: 200, md: 250 },
        }}
        alt="The house from the offer."
        src={SupportIcon}
      />
    ),
    url: "customer-tickets",
  },
  {
    text: "Customer Tickets",
    icon: null,
    url: "customer-tickets",
  },
  {
    text: "Driver Tickets",
    icon: null,
    url: "driver-tickets",
  },
  {
    text: "Admin",
    icon: (
      <Box
        component="img"
        sx={{
          height: 25,
          width: 25,
          maxHeight: { xs: 200, md: 167 },
          maxWidth: { xs: 200, md: 250 },
        }}
        alt="The house from the offer."
        src={Admin}
      />
    ),
    url: "overview",
  },
  // {
  //   text: "Role Management",
  //   icon: null,
  //   url: "overview",
  // },
  {
    text: "Operator Management",
    icon: null,
    url: "operator-management",
  },
  // {
  //   text: "Content Modification",
  //   icon: null,
  //   url: "overview",
  // },
  // {
  //   text: "Contact Us",
  //   icon: null,
  //   url: "overview",
  // },
];

const menuItems = [
  {
    text: "Flagged",
    icon: (
      <Box
        component="img"
        sx={{
          height: 25,
          width: 25,
          maxHeight: { xs: 200, md: 167 },
          maxWidth: { xs: 200, md: 250 },
        }}
        alt="The house from the offer."
        src={Admin}
      />
    ),
    url: "customer-flagged",
  },
  {
    text: "customer flag",
    url: "customer-flagged",
  },
  {
    text: "trips flag",
    url: "trips-flagged",
  },
];

const Sidebar = ({ drawerWidth, isNonMobile }) => {
  const { pathname } = useLocation();
  const [active, setActive] = useState("");
  const navigate = useNavigate();
  const showDrawer = useSelector((store) => store?.auth?.showDrawer);

  const allowed_sections =
    useSelector((state) => {
      return state?.rolePermission?.allowed_sections ?? [];
    }) || [];

  const navItems = allowed_sections
    ? allowed_sections?.map((section) => ({
        text: section.text,
        icon: svgIcons[section?.text] ? (
          <Box
            component="img"
            sx={{
              height: 25,
              width: 25,
              maxHeight: { xs: 200, md: 167 },
              maxWidth: { xs: 200, md: 250 },
            }}
            alt={alt[section.text]}
            src={svgIcons[section.text]}
          />
        ) : null,
        url: section.url,
      }))
    : [];

  useEffect(() => {
    setActive(pathname.substring(1));
  }, []);

  return (
    <Box component="nav">
      <Drawer
        open={showDrawer}
        variant="persistent"
        anchor="left"
        sx={{
          width: drawerWidth,
          "& .MuiDrawer-paper": {
            color: "white",
            backgroundColor: "black",
            boxSixing: "border-box",
            borderWidth: isNonMobile ? 0 : "2px",
            width: drawerWidth,
          },
        }}
      >
        <Box width="100%" position="relative" bottom="-50px">
          <div
            className="logo-container"
            style={{
              width: "100%",
              height: "82px",
              margin: "-50px 0px",
              position: "absolute",
              backgroundColor: "#000",
              boxSizing: "border-box",
              display: "flex",
              gap: "6px",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                position: "fixed",
                backgroundColor: "#000",
                width: "-webkit-fill-available",
                padding: "10px 30px",
                borderBottom: "1px solid #555",
                zIndex: "10",
              }}
            >
              <Stack gap="3px">
                <Stack
                  direction="row"
                  gap="7px"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <img
                    src={vector}
                    alt="logo"
                    style={{
                      width: "80%",
                      display: "block",
                      marginBottom: "5px",
                    }}
                  />
                </Stack>
                <Typography
                  sx={{
                    color: "#FFF",
                    fontFamily: "Gotham",
                    fontSize: "15px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "normal",
                  }}
                >
                  Data Monitoring System
                </Typography>
              </Stack>
            </div>
            {/* <Box m="0.5rem 1rem 0rem 2rem">
              <FlexBetween color="#3C63AD">
                <Box display="flex" alignItems="center" backgroundColor="#000">
                  <Box
                    component="img"
                    alt="logo"
                    src={vector}
                    gap="10px"
                    height="32px"
                    width="32px"
                    sx={{ objectFit: "contain" }}
                  />
                  <Typography
                    variant="h1"
                    sx={{
                      color: "#FFF",
                      fontFamily: "Gotham",
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: 500,
                      lineHeight: "normal",
                    }}
                  >
                    PeekUp Admin
                  </Typography>
                </Box>
                {!isNonMobile && (
                  <IconButton
                    onClick={() =>
                      console.log(" setIsSidebarOpen(!isSidebarOpen)")
                    }
                  >
                    <ChevronLeft />
                  </IconButton>
                )}
              </FlexBetween>
            </Box> */}
          </div>

          <List sx={{ mt: "20px" }}>
            {navItems?.map(({ text, icon, url }, index) => {
              const lcText = text.toLowerCase();

              if (!icon) {
                return (
                  <ListItem key={text} disablePadding>
                    <ListItemButton
                      onClick={() => {
                        navigate(`/${url}`);
                        setActive(lcText);
                      }}
                      sx={{
                        height: "35px",
                        backgroundColor:
                          active === lcText
                            ? "rgba(255, 255, 255, 0.30)"
                            : "transparent",
                        color: "white",
                      }}
                    >
                      <FiberManualRecordIcon sx={{ fontSize: 8, ml: "35px" }} />
                      <ListItemText primary={text} sx={{ ml: "5px" }} />
                    </ListItemButton>
                  </ListItem>
                );
              }

              return (
                <ListItem
                  key={text}
                  disablePadding
                  sx={{ m: "1.5rem 0rem 0rem 0rem" }}
                >
                  <ListItemButton
                    onClick={() => {
                      navigate(
                        `/${navItems[index + 1]?.url ?? navItems[index].url}`
                      );
                      setActive(lcText);
                    }}
                    sx={{
                      height: "35px",
                      backgroundColor:
                        active === lcText
                          ? "rgba(255, 255, 255, 0.30)"
                          : "transparent",
                      color: "white",
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        ml: "0.5rem",
                        color: "white",
                        minWidth: "35px",
                      }}
                    >
                      {icon}
                    </ListItemIcon>
                    <ListItemText primary={text} />
                    {/* {active === lcText && (
                        <ChevronRightOutlined sx={{ ml: "auto" }} />
                      )} */}
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        </Box>

        <Box position="absolute" bottom="2rem">
          <Divider />
        </Box>
      </Drawer>
    </Box>
  );
};

export default Sidebar;
