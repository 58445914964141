import React from "react";
import SharedCard from "./SharedCard";
import { Box, Stack, Typography } from "@mui/material";
import removeSlugFromString from "../utils/removeSlugFromString";
import AnalyticsCard from "./AnalyticsCard";
import { useNavigate } from "react-router-dom";

const TotalSummary = ({
  overallSummary2,
  cdAnalyticsObj,
  dAnalyticsObj,
  TitleStyle,
  customerBackgroundColor,
  driverBackgroundColor,
  tripsAnalyticsObj,
}) => {
  const navigate = useNavigate();

  const getPathWithState = (title) => {
    const special = "summary_redirection";

    switch (title) {
      case "Total Cancelled Trips By Driver":
        return {
          path: "/driver-trips",
          state: { cancelledBy: "DRIVER", special, status: "CANCELLED" },
        };
      case "Total Cancelled Trips By Passenger":
        return {
          path: "/customer-trips",
          state: { cancelledBy: "RIDER", special, status: "CANCELLED" },
        };
      case "Total Driver Not Found":
        return {
          path: "/customer-trips",
          state: { status: "DRIVER_NOT_FOUND", special },
        };
      case "Cancel Trips":
        return {
          path: "/driver-trips",
          state: { cancelledBy: "CANCELLED", special },
        };
      case "Current Live Trips":
        return {
          path: "/driver-trips",
          state: { status: "LIVE_TRIP", special },
        };
      case "Current Requested Trips":
        return {
          path: "/driver-trips",
          state: { status: "REQUESTED", special },
        };
      case "Total Completed Trips":
        return {
          path: "/driver-trips",
          state: { status: "COMPLETED", special },
        };

      case "Total Created Trips":
        return {
          path: "/driver-trips",
          state: { special },
        };
      case "Total Trips":
        return {
          path: "/driver-trips",
          state: { special },
        };
      default:
        return {
          path: "/driver-trips",
        };
    }
  };

  const getPathWithStateFilter = (title) => {
    const special = "summary_redirection";

    switch (title) {
      case "Live Trips":
        return {
          path: "/driver-trips",
          state: { status: "LIVE_TRIP", special },
        };
      case "Requested Trips":
        return {
          path: "/driver-trips",
          state: { status: "REQUESTED", special },
        };
      default:
        return false;
    }
  };

  const analyticsHandleClick = (e, text) => {
    e.stopPropagation();
    const pathAndState = getPathWithState(text);

    if (pathAndState?.state) {
      navigate(pathAndState?.path, { state: pathAndState?.state });
    }
  };

  return (
    <Stack gap={"12px"} mt={"40px"}>
      <Typography sx={{ ...TitleStyle, mb: "10px", fontSize: "26px" }}>
        Total Summary
      </Typography>

      <Box>
        <Box
          sx={{
            // mt: "40px",
            display: "grid",
            boxSizing: "border-box",
            gridTemplateColumns: {
              xs: "repeat(1,1fr)",
              md: "repeat(2,1fr)",
              lg: "repeat(3,1fr)",
            },
            gap: "40px",
            padding: "0 0 0px 0",
          }}
        >
          {overallSummary2 &&
            overallSummary2?.rider &&
            Object.entries(overallSummary2?.rider).map((res, index) => {
              const [key, value] = res;

              if (key !== "total") return false;

              return (
                <SharedCard
                  key={index}
                  svgicon={cdAnalyticsObj[key]?.svgIcon ?? ""}
                  title={cdAnalyticsObj[key]?.text ?? removeSlugFromString(key)}
                  value={value}
                  backgroundColor={customerBackgroundColor}
                />
              );
            })}

          {overallSummary2 &&
            overallSummary2.driver &&
            Object.entries(overallSummary2.driver).map((res, index) => {
              const [key, value] = res;

              if (key === "total_new_onboarding" || key === "total_online") {
                return false;
              }

              return (
                <SharedCard
                  key={index}
                  svgicon={dAnalyticsObj[key]?.svgIcon ?? ""}
                  title={
                    index === 0
                      ? "Total Drivers"
                      : dAnalyticsObj[key]?.text ?? removeSlugFromString(key)
                  }
                  value={value}
                  backgroundColor={driverBackgroundColor}
                />
              );
            })}
        </Box>
      </Box>

      <Box
        sx={{
          display: "grid",
          mt: "40px",
          boxSizing: "border-box",
          gridTemplateColumns: {
            sm: "repeat(2 , 1fr`)",
            md: "repeat(3 , 1fr)",
          },
          gap: "40px",
          padding: "0 0 50px 0",
        }}
      >
        {overallSummary2 &&
          overallSummary2.trips &&
          Object.entries(overallSummary2.trips).map((res, index) => {
            let [key, value] = res;
            return (
              <AnalyticsCard
                key={index}
                svg={tripsAnalyticsObj[key]?.svgIcon ?? ""}
                handleClick={analyticsHandleClick}
                cursorType={"pointer"}
                headerText={
                  tripsAnalyticsObj[key]?.text ?? removeSlugFromString(key)
                }
                value={value}
                format={true}
              />
            );
          })}
      </Box>
    </Stack>
  );
};

export default TotalSummary;
