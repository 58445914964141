import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import DataTable from "./DataTable";
import SearchActionComponent from "./reusableComponents/SearchActionComponent";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate, useSearchParams } from "react-router-dom";
import SwipeableDrawerComponent from "./SwipeableDrawerComponent ";
import CustomFilter from "./reusableComponents/Filter/CustomFilter";
import { showErrorToast } from "../utils/toastUtils";
import axiosInstance from "../utils/axiosConfig";
import { BootstrapTooltip } from "./DetailsSidePanelChart";
import useDebounce from "../hooks/useDebounce";
import usePermission from "../hooks/usePermission";
import { useSettings } from "../hooks/useSettings";
import FilteredResults from "./FilteredResults";

const GenericText = ({ variant = "body1", text, css = "" }) => {
  const typoStyles = {
    color: "var(--text-dark, #000)",
    textAlign: "center",
    fontFamily: "Proxima Nova",
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    letterSpacing: "0.26px",
    textTransform: "capitalize",
  };
  return (
    <BootstrapTooltip title={text}>
      <Typography variant={variant} sx={css ? { ...css } : { ...typoStyles }}>
        {text}
      </Typography>
    </BootstrapTooltip>
  );
};

const ToggleButton = ({
  toggledrawer = () => () => {},
  setid = () => {},
  handleClick = () => {},
  id,
  text,
}) => {
  const textCss = {
    color: "#2d5a9b",
    fontFamily: "Proxima Nova",
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    letterSpacing: "0.26px",
    textTransform: "capitalize",
  };

  return (
    <Button
      variant="text"
      onClick={(e) => {
        setid(id);
        // this  toggledrawer funtion return a funtion then we are passing event on it
        toggledrawer("right", true)(e);
        handleClick(id);
      }}
    >
      <GenericText text={text} css={textCss} />
    </Button>
  );
};

const AccountButton = ({ status, label, totalCount, onClick }) => {
  return (
    <Button
      sx={{
        display: "flex",
        width: "142px",
        padding: "10px",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        marginTop: "25px",
        borderRadius: "10px 10px 0 0",
        border: "1px solid #929EAE",
      }}
      style={{
        background: label === status ? "lightgray" : "#FFF",
      }}
      onClick={() => {
        onClick(status);
      }}
    >
      <Typography
        variant="h4"
        sx={{
          fontSize: "14px",
          fontFamily: "Proxima Nova",
          textAlign: "right",
          fontWeight: "600",
          lineHeight: "normal",
          letterSpacing: "0.28px",
          textTransform: "capitalize",
          color: "#000",
        }}
      >
        {label}
      </Typography>

      {totalCount > 0 && (
        <Box
          sx={{
            display: "inline-flex",
            padding: "2px 5px",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "10px",
            background: "#000",
          }}
        >
          <Typography
            sx={{
              color: "#fff",
              textAlign: "right",
              fontFamily: "Proxima Nov",
              fontSize: "14px",
              lineHeight: "normal",
              fontWeight: "600px",
              letterSpacing: "0.28px",
              textTransform: "capitalize",
            }}
          >
            {totalCount > 0 ? totalCount : 0}
          </Typography>
        </Box>
      )}
    </Button>
  );
};

// Component for managing operators
const OperatorManagement = () => {
  const [isLoading, setisLoading] = useState(false);
  /// hooks
  const navigate = useNavigate();

  // state
  const [status, setStatus] = useState("Operators");
  const [search, setSearch] = useState("");

  const [searchParams, setSearchParams] = useSearchParams();

  const page = parseInt(searchParams.get("page"), 0) || 0;
  const limit = parseInt(searchParams.get("limit"), 10) || 10;
  const [operators, setOperators] = useState({
    data: [],
    totalCount: 0,
    paginationModel: {
      page: page || 0,
      pageSize: limit || 10,
    },
  });

  const permission = usePermission();

  const searchDebounce = useDebounce(search, 500);

  const [joinedOn, setJoinedon] = useState("");
  const [numberOfDrivers, setNumberOfDrivers] = useState("");

  const [date, setDate] = useState({
    startDate: "",
    endDate: "",
  });

  const { formatDate, dateformat, timezone } = useSettings();

  const [showFilter, setShowFilter] = useState({
    right: false,
  });

  // functions
  // Function to handle status changes
  const handleStatus = (label) => {
    // if label is empty we are  returning
    if (!label) return;
    setStatus(label);
  };

  // Funtion to Navigate
  const handleNavigate = (path) => {
    if (!path) return;
    navigate(path);
  };

  const getOperatorDetails = async () => {
    setisLoading(true);
    const querys = builderQueryString();
    const url = `/crm/operator/?page=${
      operators?.paginationModel?.page + 1
    }&limit=${operators?.paginationModel?.pageSize}&${querys}`;

    try {
      const response = await axiosInstance.get(url);

      setOperators((prev) => ({
        ...prev,
        data: response?.data?.data?.records ?? [],
        totalCount: response?.data?.data?.total_count ?? 0,
      }));
    } catch (error) {
      showErrorToast(error);
    } finally {
      setisLoading(false);
    }
  };

  // Funtion to Clear Filter
  const handleClear = () => {
    if (numberOfDrivers) {
      setNumberOfDrivers("");
    }

    if (joinedOn) {
      setJoinedon("");
    }

    if (date.startDate || date.endDate) {
      setDate({ startDate: "", endDate: "" });
    }
  };
  // Funtion to Toggle Drawer
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setShowFilter((state) => ({ ...state, [anchor]: open }));
  };

  // Funtion to handle dates
  const customeDateHandler = (name, value, setState) => {
    setState((prev) => ({ ...prev, [name]: value }));
  };

  //  css
  const containerCss = {
    m: "1.5rem 1.5rem",
  };

  const tableheadButtonCss = {
    mt: "15px",
    display: "flex",
    gap: "0.25rem",
  };

  const addOpertorButtonCss = {
    height: "42px",
    padding: "12px 37px",
    background: "#000",
    color: "#fff",
    borderRadius: "12px",
    "&:hover": {
      background: "#000",
    },
  };

  const addOpertorButtonTextCss = {
    color: "#FFF",
    textAlign: "center",
    fontFamily: "Proxima Nova",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
    letterSpacing: "0.28px",
    textTransform: "capitalize",
  };

  const allowed_sub_sections = permission?.SUB_SECTIONS ?? ["operators"];

  const sub_section_label = {
    operators: "Operators",
  };

  // config for dynamic
  const tableHeadButton = allowed_sub_sections?.map((res) => ({
    label: sub_section_label[res],
    count: operators?.totalCount ?? 0,
  }));

  // mui table column config
  const columns = [
    {
      field: "phone",
      headerName: "Phone Number",
      flex: 1,
      renderCell: (param) => {
        if (!param.value) return "--";

        let phoneNumber = param.value;
        let number = phoneNumber.slice(0, 3) + " " + phoneNumber.slice(3);

        return <GenericText text={number} />;
      },
    },
    {
      field: "operator_name",
      headerName: "Operator name",
      flex: 1,
      renderCell: (param) => {
        if (!param.value) return "--";

        return (
          <ToggleButton
            text={param.value}
            handleClick={() => {
              navigate(`/operator/${param.id}`);
            }}
          />
        );
      },
    },
    {
      field: "created_at",
      headerName: "Joined on",
      flex: 1,
      formatter: {
        created_at: (params) => formatDate(params?.value, timezone, dateformat),
        // time: ( params) => formatDate(params?.row.created_at, timezone, timeFormat),
        rating: (params) => `(${params?.value})`,
        // Add other formatters as needed
      },
      renderCell: (param) => {
        if (!param.value) return "--";

        let inputDate = formatDate(param.value, timezone, dateformat);

        return <GenericText text={`${inputDate}`} />;
      },
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      renderCell: (param) => {
        if (!param.value) return "--";

        return <GenericText text={param.value} />;
      },
    },
    {
      field: "address",
      headerName: "Address",
      flex: 1,
      renderCell: (param) => {
        if (!param.value) return "--";

        return <GenericText text={param.value} />;
      },
    },
    {
      field: "driver_count",
      headerName: "Drivers",
      flex: 1,
      renderCell: (param) => {
        if (param?.value !== 0 && !param?.value) return "--";

        return <ToggleButton text={param.value} />;
      },
    },
  ];

  //  filter config
  const filterOptions = [
    {
      summryText: "Number of Drivers",
      state: numberOfDrivers,
      setState: setNumberOfDrivers,
      options: [
        { label: "0-10", value: "0-10" },
        { label: "11-25", value: "11-25" },
        { label: "26-50", value: "26-50" },
        { label: "51-75", value: "51-75" },
      ],
    },
    {
      summryText: "Joined on",
      state: joinedOn,
      setState: setJoinedon,
      options: [
        { label: "Yesterday", value: "yesterday" },
        { label: "Last Week", value: "last_week" },
        { label: "Last Month", value: "last_month" },
        { label: "Last Year", value: "last_year" },
      ],
      date: {
        customDateState: date,
        customDateHeader: "Custom",
        customDateLabel: ["From", "To"],
        customDateName: ["startDate", "endDate"],
        customeDateHandler,
        setState: setDate,
      },
    },
  ];

  const builderQueryString = () => {
    const query = [];

    if (numberOfDrivers) {
      const splitValueBySlash = numberOfDrivers.split("-");

      query.push(
        `max_driver_count=${splitValueBySlash[1]}&min_driver_count=${splitValueBySlash[0]}`
      );
    }

    if (joinedOn) {
      query.push(`joined_on=${joinedOn}`);
    }

    if (searchDebounce && search) {
      query.push(`search=${searchDebounce}`);
    }

    if (date.startDate && date.endDate) {
      let startDate = `${date.startDate.year()}-${date.startDate.month()}-${date.startDate.date()}`;
      let endDate = `${date.endDate.year()}-${date.endDate.month()}-${date.endDate.date()}`;
      query.push(`date_gte=${startDate}&_date_lte=${endDate}`);
    }

    let querys = query.length > 1 ? query.join("&") : query.join("");

    return querys;
  };

  useEffect(() => {
    getOperatorDetails();
  }, [
    searchDebounce,
    joinedOn,
    date.startDate,
    date.endDate,
    numberOfDrivers,
    operators?.paginationModel,
  ]);

  return (
    <>
      <Box sx={containerCss}>
        <SearchActionComponent
          setState={setSearch}
          placeholder={"Search Operator, Phone no, Email"}
          value={search}
          toggleDrawer={toggleDrawer}
        >
          {permission?.CREATE && (
            <Button
              startIcon={<AddIcon />}
              variant="contained"
              sx={addOpertorButtonCss}
              onClick={() => {
                let path = "/operator-management/new-operator";
                handleNavigate(path);
              }}
            >
              <Typography sx={addOpertorButtonTextCss}>Add Operator</Typography>
            </Button>
          )}
        </SearchActionComponent>
        {filterOptions && <FilteredResults options={filterOptions} />}
        <Box sx={tableheadButtonCss}>
          {tableHeadButton.map((res, index) => (
            <AccountButton
              key={index}
              onClick={handleStatus}
              status={status}
              label={res.label}
              totalCount={res.count}
            />
          ))}
        </Box>
        <DataTable
          loading={isLoading}
          row={operators?.data ?? []}
          columns={columns}
          paginationModel={operators?.paginationModel ?? {}}
          setPaginationModel={setOperators}
          totalCount={operators?.totalCount ?? 0}
          paginationInnerObject={true}
        />
      </Box>

      <SwipeableDrawerComponent
        anchor="right"
        onClose={toggleDrawer("right", false)}
        isOpen={showFilter["right"]}
      >
        <CustomFilter
          toggleDrawer={toggleDrawer}
          filterOptions={filterOptions}
          handleClear={handleClear}
        />
      </SwipeableDrawerComponent>
    </>
  );
};

export default OperatorManagement;
