import axios from "axios";

const baseURL = process.env.REACT_APP_SERVER_BASE_URL ?? "";

const axiosInstance = axios.create({
  baseURL: baseURL, // Your API base URL
  headers: {
    "Content-Type": "application/json",
  },
});

export default axiosInstance;
