// ReusableRadioGroup.js
import React from "react";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import { Box, Typography } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CustomDatePicker from "../DateTimePickers/CustomDatePicker";
import ReusableTimePicker from "../DateTimePickers/ReusableTimePicker";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import { useSearchParams } from "react-router-dom";
import useQueryParams from "../../../hooks/useQueryParams ";

const ReusableRadioGroup = ({
  value,
  onChange = () => {},
  options,
  date,
  time,
  type,
  isoString,
  state,
  parentDisaptch,
  filterName,
  condition,
  disable,
  inputOptionsFilterName,
}) => {
  const { searchParams, setSearchParams, setQuery } = useQueryParams();
  const emptyCallBack = () => {};
  const dispatch = useDispatch();

  const dateAndTimeDisable = state;
  const anyValuesPresent = (obj) => {
    if (!obj) return false;

    return Object.values(obj).some((value) => !!value);
  };
  const radioDisable =
    anyValuesPresent(date?.customDateState) ||
    anyValuesPresent(time?.timeState);

  const conditionLogic =
    condition?.conditionLogic && condition?.conditionLogic(state);

  return (
    <React.Fragment>
      <FormControl disabled={radioDisable || disable}>
        <RadioGroup
          value={value}
          onChange={(e) => {
            if (filterName || inputOptionsFilterName) {
              setQuery(
                filterName || inputOptionsFilterName,
                e.target.value,
                setSearchParams
              );
            }
            parentDisaptch
              ? dispatch(onChange(e.target.value))
              : onChange(e.target.value);

            if (condition?.setState) {
              const conditionLogic =
                condition?.conditionLogic &&
                condition?.conditionLogic(e.target.value);
              if (!conditionLogic) {
                condition?.conditionIsDispatch
                  ? dispatch(condition?.setState(""))
                  : condition?.setState("");
              }
            }
          }}
          sx={{
            display: "flex",
            boxSizing: "border-box",
            width: "365px",
            alignItems: "flex-start",
            alignContent: "flex-start",
            flexDirection: "row",
            flexWrap: "wrap",
            gap: "20px",
            margin: "auto",
          }}
        >
          {options?.map((option) => (
            <FormControlLabel
              key={option.value}
              value={option.value}
              label={option.label}
              control={<Radio />}
            />
          ))}
        </RadioGroup>

        {condition &&
          condition?.conditionLogic &&
          (conditionLogic ? (
            <RadioGroup
              value={condition?.state ?? ""}
              onChange={(e) => {
                if (condition?.setState) {
                  if (condition?.filterName) {
                    setQuery(condition?.filterName, e.target.value);
                  }
                  condition?.conditionIsDispatch
                    ? dispatch(condition?.setState(e.target.value))
                    : condition?.setState(e.target.value);
                } else {
                  throw new Error("Condition State is not provided");
                }
              }}
              sx={{
                display: "flex",
                boxSizing: "border-box",
                width: "365px",
                alignItems: "flex-start",
                alignContent: "flex-start",
                flexDirection: "row",
                flexWrap: "wrap",
                gap: "20px",
                margin: "auto",
              }}
            >
              {condition?.options?.map((option) => (
                <FormControlLabel
                  key={option.value}
                  value={option.value}
                  label={option.label}
                  control={<Radio />}
                />
              ))}
            </RadioGroup>
          ) : (
            false
          ))}
      </FormControl>
      {date && (
        <React.Fragment>
          <Typography m={"10px 0"}>{date?.customDateHeader ?? ""}</Typography>
          <Box
            sx={{
              display: "grid",
              columnGap: 1,
              gridTemplateColumns: "repeat(2, 1fr)",
              marginTop: "12px",
            }}
          >
            {date?.customDateLabel &&
              date.customDateLabel.map((label, index) => (
                <LocalizationProvider dateAdapter={AdapterDayjs} key={index}>
                  <CustomDatePicker
                    value={
                      date?.customDateState[date.customDateName[index]] ?? null
                    }
                    label={label}
                    name={
                      date?.customDateName ? date.customDateName[index] : ""
                    }
                    filterName={
                      date?.filterName ? date?.filterName[index] : null
                    }
                    setState={date?.setState ?? emptyCallBack}
                    onChange={date?.customeDateHandler ?? emptyCallBack}
                    isoString={date?.isoString}
                    isDispatch={date.isDispatch}
                    state={date.customDateState}
                    disabled={dateAndTimeDisable}
                    // {...(index === 0 ? {maxDate : da} : {})}
                  />
                </LocalizationProvider>
              ))}
          </Box>
        </React.Fragment>
      )}

      {time && (
        <React.Fragment>
          {time?.timeHeader && (
            <Typography m={"10px 0"}>{time?.timeHeader ?? ""}</Typography>
          )}
          <Box
            sx={{
              display: "grid",
              columnGap: 1,
              gridTemplateColumns: "repeat(2, 1fr)",
              marginTop: time?.timeHeader ? "12px" : "0",
            }}
          >
            {time &&
              time?.customTimeName &&
              time.customTimeName.map((name, index) => (
                <LocalizationProvider dateAdapter={AdapterDayjs} key={index}>
                  <ReusableTimePicker
                    header={time?.timeHeader ?? ""}
                    ampm={false}
                    label={time?.timeLabel[index] ?? ""}
                    filterName={
                      time?.filterName ? time?.filterName[index] : null
                    }
                    name={name || ""}
                    value={time?.timeState[name] ?? null}
                    setState={time?.setState ?? emptyCallBack}
                    onChange={time?.customeTimeHandler ?? emptyCallBack}
                    disabled={dateAndTimeDisable}
                  />
                </LocalizationProvider>
              ))}
          </Box>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

ReusableRadioGroup.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  date: PropTypes.shape({
    customDateHeader: PropTypes.string,
    customDateLabel: PropTypes.arrayOf(PropTypes.string),
    customDateName: PropTypes.arrayOf(PropTypes.string),
    customDateState: PropTypes.object,
    setState: PropTypes.func,
    customeDateHandler: PropTypes.func,
  }),
  time: PropTypes.shape({
    timeHeader: PropTypes.string,
    customTimeName: PropTypes.arrayOf(PropTypes.string),
    timeLabel: PropTypes.arrayOf(PropTypes.string),
    timeState: PropTypes.object,
    setState: PropTypes.func,
    customeTimeHandler: PropTypes.func,
  }),
  isDispatch: PropTypes.bool, // or adjust according to your needs
};

export default ReusableRadioGroup;
