import React, { useState } from "react";
import { Card, CardMedia, Modal, Box, Typography } from "@mui/material";

const ImageCard = ({ imageUrl }) => {
  const [open, setOpen] = useState(false);
  const [hover, setHover] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Card
        sx={{
          width: 225,
          cursor: "pointer",
          position: "relative",
          overflow: "hidden",
          display: "flex", // Ensure Card size is consistent
          alignItems: "center",
          justifyContent: "center",
        }}
        onClick={handleOpen}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <CardMedia
          component="img"
          sx={{
            width: "100%",
            maxHeight: "300px",
            height: "auto",
            display: "block",
          }} // Ensure image scales with Card
          image={imageUrl}
          alt="Image"
        />
        {hover && (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              bgcolor: "rgba(0, 0, 0, 0.4)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              variant="body1"
              color="white"
              sx={{ fontWeight: "bold" }}
            >
              View
            </Typography>
          </Box>
        )}
      </Card>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="image-modal"
        aria-describedby="modal-showing-full-image"
        sx={{
          zIndex: 9000,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            maxWidth: "90%",
            width: "auto",
            maxHeight: "90%",
            bgcolor: "background.paper",
            boxShadow: 24,
            overflow: "auto",
          }}
        >
          <img
            src={imageUrl}
            alt="Full size"
            style={{ width: "100%", height: "auto", maxWidth: 600 }}
          />
        </Box>
      </Modal>
    </>
  );
};

export default ImageCard;
