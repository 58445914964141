import React from "react";
import styles from "./SearchDropdown.module.css";
import { Button, Typography } from "@mui/material";

export const SearchDropdown = ({
  suggestion = [],
  ZoomOnMap,
  setSuggestion,
  select
}) => {


  return (
    <div
      style={{
        position: "absolute",
        inset: "54px 0 0 0",
        boxShadow: "0px 2px 8px 0px rgba(99, 99, 99, 0.2)",
        zIndex: 999,
        // minHeight: "240px",
        height: suggestion.length > 5 ? "300px" : suggestion.length * 36,
        minWidth: "300px",
        overflowY: "scroll",
        backgroundColor: "white",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        padding: "10px 0px",
      }}
    >
      {suggestion.length > 0 &&
        suggestion.map(([key, value], i) => (
          <Button
            key={key}
            onClick={() => {
              const lat = value?.position?.lat();
              const lng = value?.position?.lng();
              ZoomOnMap(lat, lng);
              setSuggestion([]);
            }}
          >
            <Typography key={key}>{  select === "phoneNumber"  ?  value[select].slice(0, 3) + " " + value[select].slice(3)  : value[select] || ""}</Typography>
          </Button>
        ))}
    </div>
  );
};