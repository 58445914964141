import { Box, Typography } from "@mui/material";
import React from "react";

const NotFound = () => {
  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gape: "5px",
    height: "100vh",
  };

  const textStyle = {
    fontSize: { xs: "1.8rem", md: "3rem" },
    margin: "10px 0",
  };

  const subTextStyle = {
    fontSize: { xs: "0.8rem", md: "1.2rem" },
  };
  return (
    <Box style={containerStyle}>
      <Typography variant="h2" sx={textStyle}>
        404 - Page Not Found
      </Typography>
      <Typography sx={subTextStyle}>
        The page you are looking for does not exist.
      </Typography>
    </Box>
  );
};

export default NotFound;
