function convertTimestampTo12HourFormat(timestamp) {
  if (!timestamp) {
    return "";
  }
  const date = new Date(timestamp);

  const hours = date.getHours() % 12 || 12; // Get hours in 12-hour format
  const minutes = date.getMinutes();
  const ampm = date.getHours() >= 12 ? "pm" : "am";

  // Format the time
  const formattedTime = `${hours}:${minutes < 10 ? "0" : ""}${minutes} ${ampm}`;

  return formattedTime;
}

export default convertTimestampTo12HourFormat;

export function convertTo24HourFormat(utcDateString) {
  const utcDate = new Date(utcDateString);
  const localTime = utcDate.toLocaleTimeString("en-US", {
    hour12: false,
    hour: "2-digit",
    minute: "2-digit",
  });

  return localTime;
}

export const formatTimeDifference = (timestamp) => {
  // Parse the timestamp string
  const dateObject = new Date(timestamp);

  // Calculate the difference in milliseconds between the current date and the parsed date
  const timeDifference = Date.now() - dateObject.getTime();

  // Calculate the number of days and remaining milliseconds
  const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  const remainingMilliseconds = timeDifference % (1000 * 60 * 60 * 24);

  // Calculate the number of hours
  const hours = Math.floor(remainingMilliseconds / (1000 * 60 * 60));

  // Format the result
  return `${days}D ${hours}H`;
};
