function calculateAge(date) {
  if (date === "") {
    return "";
  }
  const birthDate = new Date(date);
  const currentDate = new Date();

  const yearsDiff = currentDate.getFullYear() - birthDate.getFullYear();
  const monthsDiff = currentDate.getMonth() - birthDate.getMonth();
  const daysDiff = currentDate.getDate() - birthDate.getDate();

  if (monthsDiff < 0 || (monthsDiff === 0 && daysDiff < 0)) {
    return yearsDiff - 1; // Subtract 1 year if the birthdate hasn't occurred this year yet.
  } else {
    return yearsDiff;
  }
}

export default calculateAge;
