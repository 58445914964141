// React imports
import React from "react";

// Mui imports
import { Avatar, Box, Stack, Typography } from "@mui/material";

// Assets imports
import RoutingSvg from "../../assets/Routing.svg";

import VerificationSvg from "../../assets/Verification.svg";

import StarSvg from "../../assets/Star.svg";

// Other imports

import RequestRideAddress from "./RequestRideAddress";
import Destination from "./Destination";
import Banner from "./Banner";
import {
  bottomCardInnerStyles,
  bottomCardStyles,
  driverImageStyle,
  groupCircleImagesStyles,
  starStyle,
  strongTextStyle,
  thinTextStyle,
} from "../RideTracking";
const RequestedPhaseBottomCard = ({
  apiResponse,
  dropOffTime,
  status,
  tripStatus,
}) => {
  return (
    <Box
      sx={{
        flex: 1.5,
        overflowY: "scroll",
      }}
    >
      <div
        style={
          status === "REQUESTED"
            ? { ...bottomCardStyles, height: "auto" }
            : { ...bottomCardStyles, height: "auto" }
        }
      >
        {status === "REQUESTED" ? (
          <div style={bottomCardInnerStyles}>
            <div style={groupCircleImagesStyles}>
              <Avatar
                sx={driverImageStyle}
                src={apiResponse?.driver_details?.driver_image_url}
              />

              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                // justifyContent={"center"}
                gap={"5px"}
              >
                <img src={StarSvg} alt="rating-star-svg" style={starStyle} />

                <Typography
                  sx={{
                    fontSize: "1rem",
                    color: "#0F1828",
                    fontStyle: "normal",
                    fontWeight: 500,
                    lineHeight: "normal",
                    fontFamily: "Proxima Nova",
                    letterSpacing: "0.027px",
                  }}
                >
                  {apiResponse?.driver_details?.avg_rating?.toFixed(1)}
                </Typography>
              </Box>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "5px",
                width: "100%",
                height: "100%",
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  ...strongTextStyle,
                  fontFamily: "Proxima Nova",
                }}
              >
                <Stack direction="row" alignItems="center" gap="6px">
                  <Typography
                    sx={{
                      fontSize: "1.125rem",
                      color: "#0F1828",
                      fontStyle: "normal",
                      fontWeight: 500,
                      lineHeight: "normal",
                      letterSpacing: "0.027px",
                      fontFamily: "Proxima Nova",
                    }}
                  >
                    {apiResponse?.driver_details?.car_registration_id}
                  </Typography>
                </Stack>
              </Typography>

              <Stack gap={"6px"}>
                {apiResponse?.driver_details?.car_color && (
                  <Typography
                    variant="body2"
                    style={{
                      ...thinTextStyle,
                      fontWeight: "bold",
                    }}
                  >
                    {apiResponse?.driver_details?.car_color ?? ""}
                  </Typography>
                )}

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Typography
                    variant="body2"
                    style={{
                      ...thinTextStyle,
                      fontWeight: 500,
                    }}
                  >
                    {apiResponse?.driver_details?.car_company}
                  </Typography>
                  <div
                    style={{
                      width: "2px",
                      padding: "0 0",
                      margin: "auto 5px",
                      height: "65%",
                      backgroundColor: "#0F1828B2",
                    }}
                  ></div>

                  {apiResponse?.driver_details?.car_model_number && (
                    <Typography
                      variant="body2"
                      style={{
                        ...thinTextStyle,
                        fontWeight: 500,
                      }}
                    >
                      {apiResponse?.driver_details?.car_model_number ?? ""}
                    </Typography>
                  )}
                </Box>

                <Typography
                  variant="body2"
                  style={{
                    ...thinTextStyle,
                    fontWeight: 500,
                  }}
                >
                  {apiResponse?.driver_details?.full_name}
                </Typography>
              </Stack>

              {/*
            <Box display={"flex"} gap={"5px"}>
              {apiResponse?.driver_details?.car_color && (
                <Typography
                  variant="body2"
                  style={{
                    ...thinTextStyle,
                    fontWeight: "bold",
                  }}
                >
                  {apiResponse?.driver_details?.car_color ?? ""}
                </Typography>
              )}

              {apiResponse?.driver_details?.car_color && (
                <Box sx={dotContainerStyle}>
                  <Box sx={dotCss}></Box>
                </Box>
              )}
              <Typography variant="body2" style={thinTextStyle}>
                {apiResponse?.driver_details?.car_company}
              </Typography>

              {apiResponse?.driver_details?.car_model_number && (
                <Box sx={dotContainerStyle}>
                  <Box sx={dotCss}></Box>
                </Box>
              )}

              {apiResponse?.driver_details?.car_model_number && (
                <Typography variant="body2" style={thinTextStyle}>
                  {apiResponse?.driver_details?.car_model_number ?? ""}
                </Typography>
              )}
            </Box>
            <Typography variant="body2" style={thinTextStyle}>
              {apiResponse?.driver_details?.full_name}
            </Typography> */}
            </div>
            <div
              style={{ alignSelf: "flex-start", width: "70px", height: "59px" }}
            >
              <img src={VerificationSvg} />
            </div>
          </div>
        ) : (
          <></>
        )}
        <div
          style={{
            display: "flex",
            width: "100%",
            flexDirection: "row",
          }}
        >
          {status === "REQUESTED" ? (
            <>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  boxSizing: "border-box",
                  height: "auto",
                  borderRadius: "12px",
                  backgroundColor: "#F7F9FC",
                  marginLeft: "10px",
                  marginRight: "30px",
                  padding: "10px",
                }}
              >
                <img
                  style={{
                    height: "27px",
                    width: "27px",
                    marginRight: "10px",
                  }}
                  src={RoutingSvg}
                  alt="routing svg"
                />
                <Typography
                  variant="h6"
                  sx={{
                    color: "var(--font-dark, #0F1828)",
                    fontFamily: "Proxima Nova",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    lineHeight: "18.91px",
                    letterSpacing: "0.015px",
                  }}
                >
                  {status === "REQUESTED" ? "Peekup At" : "Drop off"}
                </Typography>

                <Typography
                  sx={{
                    color: "var(--font-dark, #0F1828)",
                    fontFamily: "Proxima Nova",
                    fontSize: "18px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "23px",
                    letterSpacing: "0.024px",
                    marginLeft: "8px",
                  }}
                >
                  {dropOffTime}
                </Typography>
              </Box>
            </>
          ) : (
            <>
              <Destination
                dropOffTime={dropOffTime}
                dropOfAddress={apiResponse?.dropoff_location?.address_text}
                stops={apiResponse?.stops}
              />
            </>
          )}
        </div>
      </div>

      {/* Pickup and dropoff location with icon */}

      {status === "REQUESTED" ? (
        <RequestRideAddress apiResponse={apiResponse} />
      ) : (
        <></>
      )}

      {status === "REQUESTED" ? <Banner url={apiResponse?.banner_url} /> : null}
    </Box>
  );
};

export default RequestedPhaseBottomCard;
