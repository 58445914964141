import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  ThemeProvider,
  Typography,
  createTheme,
  useMediaQuery,
} from "@mui/material";
import React from "react";

const theme = createTheme({
  components: {
    MuiSelect: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          [theme.breakpoints.down("xs")]: {
            width: "100%",
          },
          [theme.breakpoints.up("sm")]: {
            display: "none",
          },
          [theme.breakpoints.up("lg")]: {
            display: "none",
          },
        }),
      },
    },
  },
});

const RoleSelectorButton = ({
  roleName = [],
  roleHandler = () => {},
  selectedName = "",
}) => {
  const isSmallScreen = useMediaQuery("(max-width:450px)");

  return isSmallScreen ? (
    <ThemeProvider theme={theme}>
      <FormControl variant="outlined" fullWidth>
        <InputLabel id="responsive-select-label" shrink>
          Select
        </InputLabel>
        <Select
          displayEmpty
          sx={{
            backgroundColor: "#fff",
          }}
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={selectedName}
          onChange={(e) => roleHandler(e.target.value)}
          label="Select"
        >
          {roleName?.map((res) => {
            return (
              <MenuItem
                sx={{
                  textTransform: "capitalize",
                }}
                value={res}
                key={res}
              >
                {res}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </ThemeProvider>
  ) : (
    <Box
      sx={{
        boxSizing: "border-box",
        display: { xs: "none", sm: "flex" },
        flexWrap: "wrap",
        width: "auto",
        padding: "5px",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "12px",
        border: "1px solid #B3B3B3",
        background: "#FFF",
        gap: "5px",
      }}
      onClick={(event) => {
        const clickedButton = event.target.closest("button");
        if (clickedButton) {
          const clickedName = clickedButton.getAttribute("data-name");

          if (!clickedName) return;

          roleHandler(clickedName);
        }
      }}
    >
      {roleName.map((name) => (
        <Button
          key={name}
          variant={selectedName === name ? "contained" : "text"}
          sx={{
            display: "flex",
            width: "auto",
            height: "32px",
            padding: "10px 50px",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "10px",
            backgroundColor: selectedName === name ? "#000" : "#fff",
            "&:hover": {
              backgroundColor: selectedName === name ? "#000" : "#fff",
            },
          }}
          data-name={name} // Add data-name attribute to store the name
        >
          <Typography
            sx={{
              color: selectedName === name ? "#fff" : "#000",
              fontFamily: "Proxima Nova",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "normal",
            }}
          >
            {name}
          </Typography>
        </Button>
      ))}
    </Box>
  );
};

export default RoleSelectorButton;
