import React from "react";

const ComingSoon = () => {
  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gape: "5px",
    height: "100vh",
  };

  const textStyle = {
    fontSize: "3rem",
    margin: "10px 0",
    color: "#363636",
  };

  return (
    <div style={containerStyle}>
      <h2 style={textStyle}>Coming Soon</h2>
    </div>
  );
};

export default ComingSoon;
