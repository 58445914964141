import React, { useRef } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { showErrorToast } from "../utils/toastUtils";

// plase provide status and funtion when we click model will know which funtion to hit

function CustomConfirmationDialog({
  open = false,
  isGroup = false,
  value,
  handleState = () => {},
  setOpen = () => {},
  status = "",
  deleteById = () => {},
  blockById = () => {},
  flagbyid = () => {},
  textArea = false,
  remarkref,
  message = "",
  riderId,
  prop = false, // prop can we id or anyting you can pass if you want
}) {
  const clearTextArea = () => {
    if (remarkref) {
      remarkref.current = "";
    }
  };

  const handleCloseDialog = () => {
    if (isGroup) {
      handleState(status, false);
      return;
    }

    setOpen(false);
  };

  const handleConfirm = (e) => {
    if (status) {
      switch (status) {
        case "delete":
          deleteById(prop || e);
          break;
        case "block":
          blockById(prop || riderId || e);
          break;
        case "flagged":
          flagbyid(prop || riderId || e);
          break;
        default:
          break;
      }

      if (isGroup) {
        handleState(status, false);
      } else {
        handleCloseDialog();
      }
    }
  };

  return (
    <Dialog
      open={open}
      style={{ zIndex: 2000, backgroundColor: "transparent" }}
      onClose={() => {
        remarkref.current = "";
        if (isGroup) {
          handleState(status, false);
        } else {
          handleCloseDialog();
        }
      }}
    >
      <DialogTitle>Confirmation</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
        {textArea && (
          <textarea
            defaultValue={remarkref.current}
            onChange={(e) => {
              e.stopPropagation();
              remarkref.current = e.target.value;
            }}
            style={{
              width: "97%",
              outline: "none",
              fontFamily: "Proxima Nova",
              fontSize: "1rem",
              border: "1px solid #E3E7EF",
              boxSizing: "border-box",
              height: "90px",
              resize: "none",
              marginTop: "15px",
              padding: "10px 10px 10px 16px",
              borderRadius: "5px",
            }}
            placeholder="Add Remark"
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={(e) => {
            e.stopPropagation();
            if (isGroup) {
              handleState(status, false);
            } else {
              handleCloseDialog();
            }

            clearTextArea();
          }}
          sx={{ color: "#000" }}
        >
          Cancel
        </Button>
        <Button
          onClick={(e) => {
            if (!textArea) {
              handleConfirm(e);
            } else {
              if (remarkref?.current && remarkref?.current?.length > 0) {
                handleConfirm(e);
              } else {
                showErrorToast("Please Add Remark");
              }
            }
          }}
          sx={{ color: "#000" }}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CustomConfirmationDialog;
