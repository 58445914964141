import {
  Box,
  IconButton,
  Modal,
  Stack,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import React, { useState } from "react";
import styles from "../styles/chat.module.css";
import MessageLeft from "./MessageLeft";
import MessageRight from "./MessageRight";
import convertTimestampTo12HourFormat from "../utils/convertTimestampTo12HourFormat";
import styled from "@emotion/styled";
import ClearIcon from "@mui/icons-material/Clear";
import { useSettings } from "../hooks/useSettings";

// Gets the corresponding label for a given status value
const getStatusLabel = (statusValue) => {
  switch (statusValue) {
    case "INITIATED":
      return {
        text: "initiated",
        color: "#043A87",
        background: "rgba(4, 58, 135, 0.10)",
      };
    case "UNDER_REVIEW":
      return {
        text: "under review",
        color: "#FFC940",
        background: "rgba(255, 201, 64, 0.09)",
      };
    case "COMPLETED":
      return {
        text: "resolved",
        color: "#F98244",
        background: "rgba(249, 130, 68, 0.10)",
      };
    case "REOPENED":
      return {
        text: "reopened",
        color: "#043A87",
        background: "rgba(4, 58, 135, 0.10)",
      };
    case "FEEDBACK":
      return {
        text: "Feedback",
        color: "#043A87",
        background: "rgba(4, 58, 135, 0.10)",
      };
    default:
      return "unknown"; // Default label if the statusValue doesn't match any case
  }
};
// tooltips
export const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "#000",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#000",
    maxWidth: 500,
  },
}));
const DetailsSidePanelChart = ({
  chartoptions = [],
  userDetails,
  ticket = {},
  ticketsRole,
}) => {
  const [showImage, setShowImage] = useState({
    show: false,
    url: "",
  });

  const { formatDate, timezone, dateformat, timeFormat } = useSettings();

  return (
    <Stack direction={"row"}>
      {showImage.show && (
        <Modal
          open={showImage.show}
          onClose={() =>
            setShowImage({
              show: false,
              url: "",
            })
          }
        >
          <Box
            sx={{
              minHeight: "100vh",
              width: "100%",
            }}
          >
            <IconButton
              onClick={() => {
                setShowImage({
                  show: false,
                  url: "",
                });
              }}
              sx={{
                position: "fixed",
                top: "10px",
                right: "10px",
              }}
            >
              <ClearIcon
                sx={{
                  fill: "#fff",
                }}
              />
            </IconButton>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "95%",
                height: "95%",
              }}
            >
              <img
                src={showImage.url}
                width={"100%"}
                height={"100%"}
                style={{ objectFit: "contain" }}
              />
            </Box>
          </Box>
        </Modal>
      )}
      <div className={styles.container}>
        <div className={styles.messagesBody}>
          {chartoptions.length > 0 &&
            chartoptions.map((res, index) => {
              const userActivityFlag =
                ["INITIATED", "REOPENED"].includes(res.status) &&
                res[
                  ticketsRole === "driver" ? "driver_remarks" : "rider_remarks"
                ]
                  ? true
                  : false;

              const adminActivityFlag = res.admin_remarks ? true : false;

              const appImage = res?.assets?.find(
                (asset) => asset.asset_type === "APP"
              );
              const appImageUrl = appImage ? appImage.url : null;

              const internote = res?.assets?.find(
                (asset) => asset.asset_type === "INTERNAL"
              );

              const internalImageUrl = internote ? internote.url : null;

              return (
                <React.Fragment key={res.id}>
                  {userActivityFlag && (
                    <MessageLeft
                      name={userDetails?.full_name ?? ""}
                      profilePic={
                        userDetails?.driver_image_url ??
                        userDetails?.rider_image_url ??
                        ""
                      }
                      refund={ticket?.subject}
                      date={formatDate(res.created_at, timezone, dateformat)}
                      time={formatDate(res.created_at, timezone, timeFormat)}
                      ticketStatus={getStatusLabel(res?.status)?.text ?? ""}
                      userImgUrl={""}
                      label={
                        res[
                          ticketsRole === "driver"
                            ? "driver_remarks"
                            : "rider_remarks"
                        ]
                      }
                      BootstrapTooltip={BootstrapTooltip}
                    />
                  )}
                  {adminActivityFlag && (
                    <MessageRight
                      setShowImage={setShowImage}
                      name={res?.admin_details?.full_name ?? ""}
                      profilePic={
                        userDetails?.admin_details?.profile_pic_url ?? ""
                      }
                      date={formatDate(res.created_at, timezone, dateformat)}
                      time={formatDate(res.created_at, timezone, timeFormat)}
                      label={res.admin_remarks}
                      ticketStatus={
                        !userActivityFlag
                          ? getStatusLabel(res?.status)?.text ?? ""
                          : ""
                      }
                      internalNote={res.internal_notes}
                      BootstrapTooltip={BootstrapTooltip}
                      appimageUrl={appImageUrl}
                      internalNoteImgUrl={internalImageUrl}
                    />
                  )}
                </React.Fragment>
              );
            })}
        </div>
      </div>
    </Stack>
  );
};

export default DetailsSidePanelChart;
