/**
 * Formats a number into a shortened format with suffixes (e.g., "k" for thousands, "M" for millions).
 *
 * @param {number} num - The number to be formatted.
 * @returns {string} The formatted number with an appropriate suffix.
 */

function formatNumberShort(num = 0) {
  let isAddSymbol = false


 if(typeof num === "string" && num.includes("₱")){
   isAddSymbol =true
   num = parseInt(num?.replace("₱" , ""))
 }




  if (num === 0) {
    return 0;
  }

  const suffixes = ["", "k", "M", "B", "T"];

  if (typeof num !== "number") {
    return "Invalid Number";
  }

  if (num < 1000) {
    return isAddSymbol ?  `₱ ${num.toString()}` : num.toString();
  }

  let i = 0;
  while (num >= 1000 && i < suffixes.length - 1) {
    num /= 1000;
    i++;
  }

  // Check if the number has a decimal part equal to .0 and remove it
  const formattedNum = num % 1 === 0 ? num.toFixed(0) : num.toFixed(1);


  const  returnvalue = isAddSymbol ? `₱ ${formattedNum.replace(/\.0$/, "") + suffixes[i]}` : formattedNum.replace(/\.0$/, "") + suffixes[i];
  return  returnvalue
}


export function validateNumber(value) {
  const isValidNumber = typeof value === 'number' && !isNaN(value) && value !== undefined;

  if (isValidNumber) {
    return value;
  } else {
    return 0;
  }
}
export default formatNumberShort;
