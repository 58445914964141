import {
  Accordion,
  AccordionSummary,
  Box,
  Button,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import FlexBetween from "./FlexBetween";
import CloseIcon from "@mui/icons-material/Close";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import {
  clearFilters,
  setEndTime,
  setRatingDate,
  setRatingDay,
  setReview,
  setSelectedTime,
  setRating,
} from "../state/ratingAndReviewSidePanelFilterSlice";

const RatingAndReviewSidePanelFilter = ({ handleFilterClick, handleDone }) => {
  const { rating, review, ratingDate, selectedTime, endTime, ratingDay } =
    useSelector((store) => store.ratingAndReviewSidePanelFilter);
  const dispatch = useDispatch();
  return (
    <>
      <Box
        width={520}
        sx={{
          position: "fixed",
          left: "0",
          backgroundColor: "#fff",
          height: "100dvh",
          width: "350px",
          background: "#FFF",
          padding: "10px",
          boxSizing: "border-box",
          boxShadow: "-4px 4px 18px 0px rgba(0, 0, 0, 0.07)",
        }}
      >
        <FlexBetween
          sx={{
            m: "0.5rem 0.5rem",
          }}
        >
          <Typography variant="h6" fontWeight="bold" fontFamily="sans-serif">
            Filter By
          </Typography>

          <IconButton
            onClick={() => {
              handleFilterClick(false);
            }}
          >
            <CloseIcon
              sx={{
                fill: "#000",
              }}
            />
          </IconButton>
        </FlexBetween>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{
              backgroundColor: "rgba(145, 145, 145, 0.30)",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Proxima Nova",
                fontSize: "14px",
                color: "#000",
                fontStyle: "normal",
                fontWeight: "600",
                lineHeight: "normal",
              }}
            >
              Rating
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FormControl>
              <RadioGroup
                row
                sx={{
                  display: "grid",
                  justifyContent: "space-between",
                  gridTemplateColumns: "repeat(2,1fr)",
                  justifyItems: "center",
                  textAlign: "center",
                  gap: "1vh",
                }}
                value={rating}
                onChange={(e) => {
                  let ratingValue = e.target.value;
                  dispatch(setRating(ratingValue));
                }}
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
              >
                <FormControlLabel
                  value="5"
                  control={<Radio />}
                  label="5 star"
                />
                <FormControlLabel
                  value="4"
                  control={<Radio />}
                  label="4 star"
                />
                <FormControlLabel
                  value="3"
                  control={<Radio />}
                  label="3 star"
                />
                <FormControlLabel
                  value="2"
                  control={<Radio />}
                  label="2 star"
                />
                <FormControlLabel
                  value="1"
                  control={<Radio />}
                  label="1 star"
                />
              </RadioGroup>
            </FormControl>
            <Button
              sx={{
                color: "#000",
                m: "1vh 0.5vh",
                fontSize: "0.8rem",
                cursor: "pointer",
              }}
              onClick={(e) => {
                e.stopPropagation();
                if (rating) {
                  let emptyString = "";
                  dispatch(setRating(emptyString));
                }
              }}
            >
              Clear
            </Button>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{
              backgroundColor: "rgba(145, 145, 145, 0.30)",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Proxima Nova",
                fontSize: "14px",
                color: "#000",
                fontStyle: "normal",
                fontWeight: "600",
                lineHeight: "normal",
              }}
            >
              Rating & Reviews
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FormControl>
              <RadioGroup
                row
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "0.5vh",
                  flexDirection: "column",
                }}
                value={review}
                onChange={(e) => dispatch(setReview(e.target.value))}
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <FormControlLabel
                    value="rating_only"
                    control={<Radio />}
                    label="Rating only"
                  />
                  <FormControlLabel
                    value="review_only"
                    control={<Radio />}
                    label="Review only"
                  />
                </Box>
                <FormControlLabel
                  value="rating_and_review_both"
                  control={<Radio />}
                  label="Rating and review both"
                />

                <FormControlLabel
                  value="no_rating_and_review"
                  control={<Radio />}
                  label="No rating and review"
                />
              </RadioGroup>
            </FormControl>
            <Button
              sx={{
                color: "#000",
                m: "1vh 0.5vh",
                fontSize: "0.8rem",
                cursor: "pointer",
              }}
              onClick={(e) => {
                e.stopPropagation();
                if (review) {
                  dispatch(setReview(""));
                }
              }}
            >
              Clear
            </Button>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{
              backgroundColor: "rgba(145, 145, 145, 0.30)",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Proxima Nova",
                fontSize: "14px",
                color: "#000",
                fontStyle: "normal",
                fontWeight: "600",
                lineHeight: "normal",
              }}
            >
              Rating Day
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FormControl>
              <RadioGroup
                row
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "0.5vh",
                }}
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={ratingDay}
                onChange={(e) => dispatch(setRatingDay(e.target.value))}
              >
                <FormControlLabel
                  value="Recent"
                  control={<Radio />}
                  disabled
                  label="Recent"
                />
                <FormControlLabel
                  value="last_week"
                  control={<Radio />}
                  label="Last week"
                />
                <FormControlLabel
                  value="yesterday"
                  control={<Radio />}
                  label="Yesterday"
                />
                <FormControlLabel
                  value="last_month"
                  control={<Radio />}
                  label="Last month"
                />
                <FormControlLabel
                  value="last_year"
                  control={<Radio />}
                  label="Last year"
                />
              </RadioGroup>
            </FormControl>
            <Typography sx={{ m: "1vh 0.5vh" }}>Custom</Typography>
            <Box
              sx={{
                display: "grid",
                columnGap: 1,
                gridTemplateColumns: "repeat(2, 1fr)",
                marginTop: "12px",
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="From"
                  value={dayjs(ratingDate.startDate)}
                  onChange={(newValue) => {
                    dispatch(
                      setRatingDate({
                        ...ratingDate,
                        startDate: newValue.toISOString(),
                      })
                    );
                  }}
                />
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="To"
                  value={dayjs(ratingDate.endDate)}
                  onChange={(newValue) => {
                    dispatch(
                      setRatingDate({
                        ...ratingDate,
                        endDate: newValue.toISOString(),
                      })
                    );
                  }}
                />
              </LocalizationProvider>
            </Box>
            <Button
              sx={{
                color: "#000",
                m: "1vh 0.5vh",
                fontSize: "0.8rem",
                cursor: "pointer",
              }}
              onClick={(e) => {
                e.stopPropagation();
                if (ratingDay) {
                  dispatch(setRatingDay(""));
                }

                if (ratingDate.startDate || ratingDate.endDate) {
                  dispatch(
                    setRatingDate({
                      startDate: "",
                      endDate: "",
                    })
                  );
                }
              }}
            >
              Clear
            </Button>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{
              backgroundColor: "rgba(145, 145, 145, 0.30)",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Proxima Nova",
                fontSize: "14px",
                color: "#000",
                fontStyle: "normal",
                fontWeight: "600",
                lineHeight: "normal",
              }}
            >
              Rating time
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <AccordionDetails>
              <Box sx={{ marginTop: "12px" }}>
                <Box
                  sx={{
                    display: "grid",
                    columnGap: 1,
                    gridTemplateColumns: "repeat(2, 1fr)",
                  }}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                      label="start time"
                      ampm={false}
                      value={dayjs(selectedTime)}
                      onChange={(e) => {
                        dispatch(setSelectedTime(e.toISOString()));
                      }}
                    />
                  </LocalizationProvider>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                      label="end time"
                      ampm={false}
                      value={dayjs(endTime)}
                      onChange={(e) => {
                        dispatch(setEndTime(e.toISOString()));
                      }}
                    />
                  </LocalizationProvider>
                </Box>
                <Button
                  variant="text"
                  sx={{
                    color: "#000",
                    marginTop: "10px",
                    "&:hover": {
                      background: "#fff",
                    },
                  }}
                  onClick={() => {
                    if (selectedTime) {
                      dispatch(setSelectedTime(""));
                    }

                    if (endTime) {
                      dispatch(setEndTime(""));
                    }
                  }}
                >
                  Clear
                </Button>
              </Box>
            </AccordionDetails>
          </AccordionDetails>
        </Accordion>
        <Box
          sx={{
            width: "100%",
            margin: "25px auto",
            boxSizing: "border-box",
            padding: "20px",
            display: "flex",
            justifyContent: "space-between",
            background: "#fff",
            boxShadow: "0px -4px 7px 0px rgba(0, 0, 0, 0.15)",
          }}
        >
          <Button
            sx={{
              padding: "10px 16px",
              borderRadius: "5px",
              border: "1px solid rgba(0, 0, 0, 0.30)",
              color: "#000",
              fontSize: "14px",
              fontWeight: "600",
            }}
            onClick={() => {
              dispatch(clearFilters());
            }}
          >
            Clear all
          </Button>
          <Button
            sx={{
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "600",
              color: "#fff",
              background: "rgba(0, 0, 0, 0.30)",
              "&:hover": {
                background: "rgba(0, 0, 0, 0.30)",
              },
            }}
            onClick={handleDone}
          >
            Apply filters
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default RatingAndReviewSidePanelFilter;
