import { IconButton, Menu, MenuItem, Typography } from "@mui/material";
import { Box } from "@mui/system";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useState } from "react";

const MenuComponent = ({
  options = [],
  handleEventByName,
  handleId = () => {},
  setIsBlocked = () => {},
  blockStatus = false,
  id = "",
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event, riderId) => {
    if (id) {
      handleId("id", id);
    }

    setIsBlocked(blockStatus);

    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Box sx={{ boxSizing: "border-box" }}>
      <IconButton onClick={handleClick}>
        <MoreVertIcon sx={{ fill: "#000" }} />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        ModalProps={{
          style: {
            zIndex: 1200,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },

          getContentAnchorEl: null,
        }}
      >
        {options.length > 0 &&
          options.map((option) => (
            <MenuItem
              key={option.text}
              onClick={() => {
                handleClose();
                handleEventByName(option.status);
              }}
            >
              <Typography>{option?.text ?? ""}</Typography>
            </MenuItem>
          ))}
      </Menu>
    </Box>
  );
};

export default MenuComponent;
