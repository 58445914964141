import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import SearchActionComponent from "./reusableComponents/SearchActionComponent";
import { Typography } from "@mui/material";
import { Button } from "@mui/material";
import DataTable from "./DataTable";
import axiosInstance from "../utils/axiosConfig";
import AddIcon from "@mui/icons-material/Add";
import TrainingCenterModal from "./TrainingCenterModal";
import { showErrorToast, showSuccessToast } from "../utils/toastUtils";
import { useNavigate } from "react-router";
import MenuComponent from "./MenuComponent";
import CustomConfirmationDialog from "./CustomConfirmationDialog";
import useDebounce from "../hooks/useDebounce";
import SwipeableDrawerComponent from "./SwipeableDrawerComponent ";
import CustomFilter from "./reusableComponents/Filter/CustomFilter";
import usePermission from "../hooks/usePermission";
import FilteredResults from "./FilteredResults";
import { useSearchParams } from "react-router-dom";

const TableAction = ({
  performActionByName,
  handleId,
  id,
  setIsBlocked,
  blockStatus = false,
}) => {
  const permission = usePermission();

  const options = [
    permission?.DELETE && {
      text: "Delete Center",
      status: "delete",
    },
    permission?.BLOCK && {
      text: blockStatus ? "Unblock" : "Block",
      status: "block",
    },
  ];

  return (
    <MenuComponent
      options={options}
      handleEventByName={performActionByName}
      handleId={handleId}
      id={id}
      setIsBlocked={setIsBlocked}
      blockStatus={blockStatus}
    />
  );
};

const TrainingCenter = () => {
  const [isLoading, setisLoading] = useState(false);

  const navigate = useNavigate();

  const permission = usePermission();

  const [search, setSearch] = useState("");
  const [blocked, setBlocked] = useState(null);

  const searchDebounce = useDebounce(search, 500);
  const [searchParams, setSearchParams] = useSearchParams();

  const page = searchParams.get("page");
  const limit = searchParams.get("limit");

  const [trainingCenterData, setTraningCenter] = useState({
    data: [],
    totalCount: 0,
    paginationModal: {
      page: page || 0,
      pageSize: limit || 10,
    },
  });

  const [open, setOpen] = React.useState({
    create: false,
    update: false,
  });

  const [isBlocked, setIsBlocked] = useState(false);

  const initialTraningCenterState = {
    training_center_name: "",
    training_center_latitude: "",
    training_center_longitude: "",
    training_center_time_from: "",
    training_center_time_to: "",
    training_center_address: "",
    center_availability_in_days: "",
  };

  const [showSidePanel, setShowSidePanel] = useState({
    filter: {
      right: false,
    },
  });

  const toggleFilterSidePanel = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setShowSidePanel((prev) => ({ ...prev, filter: { [anchor]: open } }));
  };

  const [showConfirmDailog, setShowConfirmDailog] = useState({
    block: false,
    delete: false,
  });

  const [id, setId] = useState({
    id: "",
  });

  const [activeTableButton, setActiveTableButton] = useState("all");

  const [trainingCenter, setTrainingCenter] = useState(
    initialTraningCenterState
  );

  const [updateTrainingCenter, setupdateTrainingCenter] = useState({
    ...initialTraningCenterState,
    update: { ...initialTraningCenterState, updateId: "" },
  });

  const handleTrainingCenter = (e, propvalue) => {
    if (!e) return;

    // for timing if there is no e.target that means we will consider e as name
    // also propvalue as value
    const { name, value } = e?.target || { name: e, value: propvalue };

    setTrainingCenter((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleConformationDailog = (name, value) => {
    setShowConfirmDailog((prev) => ({ ...prev, [name]: !prev[name] }));
  };

  const performActionByName = (name, value) => {
    switch (name) {
      case "delete":
        handleConformationDailog(name, value);
        break;
      case "block":
        handleConformationDailog(name, value);
        break;
      default:
        break;
    }
  };

  const handleId = (name, value) => {
    setId((prev) => ({ ...prev, [name]: value }));
  };

  const createCenter = async () => {};

  const handleActiveTableButton = (value) => {
    setActiveTableButton(value);
  };

  // const handlePageination = (value) => {
  //   if (!value) return;

  //   searchParams.set("page", value?.page || 0);
  //   searchParams.set("limit", value?.pageSize || 10);
  //   setTraningCenter((prev) => ({ ...prev, paginationModal: value }));
  // };

  const containerStyle = {
    m: "1.5rem",
    boxSizing: "border-box",
  };

  const buildQueryString = () => {
    const queryParameters = [];

    if (searchDebounce) {
      queryParameters.push(`search=${search}`);
    }

    if (blocked) {
      queryParameters.push(`is_blocked=${blocked}`);
    }

    let qurys =
      queryParameters.length > 1
        ? queryParameters.join("&")
        : queryParameters.join("");

    return qurys;
  };

  const getTrainingCenterDetails = async () => {
    setisLoading(true);
    const querys = buildQueryString();
    const url = `/crm/training-center-v2/?page=${
      trainingCenterData?.paginationModal?.page + 1 ?? 0
    }&limit=${trainingCenterData?.paginationModal?.pageSize ?? 10}&${querys}`;
    try {
      const response = await axiosInstance.get(url);

      if (response.status === 200) {
        setTraningCenter((prev) => ({
          ...prev,
          data: response?.data?.data?.records ?? [],
          totalCount: response?.data?.data?.total_count ?? 0,
        }));
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      setisLoading(false);
    }
  };

  const deleteCenterById = async () => {
    const url = `/crm/training-center-v2/${id?.id ?? ""}/`;
    try {
      await axiosInstance.delete(url).then((_) => {
        showSuccessToast("Successfully Deleted the Center");
        handleId("id", "");
        getTrainingCenterDetails();
      });
    } catch (error) {
      showErrorToast(error);
    }
  };

  const blockCenterById = async () => {
    try {
      const url = `/crm/training-center-v2/${id?.id ?? ""}/`;

      const updatedBody = {
        is_blocked: !isBlocked,
      };

      await axiosInstance.patch(url, updatedBody).then((_) => {
        showSuccessToast(
          `Successfuly ${isBlocked ? "Unblocked" : "Block"} The Center`
        );

        getTrainingCenterDetails();
      });
    } catch (error) {
    } finally {
      setisLoading(false);
    }
  };

  // config
  const allowed_sub_sections = permission?.SUB_SECTIONS ?? ["all"];
  const sub_section_to_label = {
    all: "All",
  };

  const tableHeadButtonConfig = allowed_sub_sections.map((sub_section) => {
    return {
      label: sub_section_to_label[sub_section],
      value: sub_section,
      totalcount: trainingCenterData.totalCount,
      handleAccountStatus: handleActiveTableButton,
    };
  });

  const columns = [
    {
      field: "training_center_name",
      headerName: "Training Center name",
      flex: 0.6,
      renderCell: (param) => {
        if (!param.value) {
          return "--";
        }

        return (
          <Button
            onClick={() => {
              navigate(`/training-center/${param?.row?.id}`);
            }}
            fullWidth
            sx={{
              justifyContent: "flex-start",
            }}
          >
            <Typography
              sx={{
                color: "#2d5a9b",
                fontFamily: "Proxima Nova",
                fontSize: "13px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "normal",
                letterSpacing: "0.26px",
                textTransform: "capitalize",
              }}
            >
              {param.value}
            </Typography>
          </Button>
        );
      },
    },
    {
      field: "training_center_address",
      headerName: "Training Center Address",
      flex: 0.6,
      renderCell: (param) => {
        if (!param.value) {
          return "--";
        }

        return (
          <Button
            fullWidth
            sx={{
              justifyContent: "flex-start",
            }}
            onClick={() => {
              navigate(`/training-center/${param?.row?.id}`);
            }}
          >
            <Typography
              sx={{
                color: "#2d5a9b",
                fontFamily: "Proxima Nova",
                fontSize: "13px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "normal",
                letterSpacing: "0.26px",
                textTransform: "capitalize",
              }}
            >
              {param.value}
            </Typography>
          </Button>
        );
      },
    },
    {
      field: "Action",
      headerName: "Action",
      renderCell: (param) => {
        return (
          <TableAction
            performActionByName={performActionByName}
            handleId={handleId}
            setIsBlocked={setIsBlocked}
            id={param?.id}
            blockStatus={param?.row?.is_blocked}
          />
        );
      },
    },
  ];

  // config

  const filterConfig = [
    {
      summryText: "Blocked Center",
      state: blocked,
      setState: setBlocked,
      options: [
        { label: "Blocked Center", value: true },
        { label: "UnBlocked Center", value: false },
      ],
    },
  ];

  useEffect(() => {
    getTrainingCenterDetails();
  }, [trainingCenter?.paginationModal, searchDebounce, blocked]);

  return (
    <Box sx={containerStyle}>
      <SearchActionComponent
        value={search}
        setState={setSearch}
        placeholder={`Search Traning Center`}
        toggleDrawer={toggleFilterSidePanel}
        require={{ search: true, filter: true, download: false }}
      >
        {permission?.CREATE && (
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            sx={{
              backgroundColor: "#000",
              height: "42px",
              borderColor: "black",
              fontWeight: "bold",
              borderRadius: "10px",
              textTransform: "capitalize",
              ml: "10px",

              "&:hover": {
                backgroundColor: "#000",
              },
            }}
            onClick={() => {
              // setOpen((prev) => ({ ...prev, create: true }));
              navigate("/new-center");
            }}
          >
            Add Center
          </Button>
        )}
      </SearchActionComponent>

      {filterConfig && <FilteredResults options={filterConfig} />}

      <Box
        sx={{
          mt: "15px",
          display: "flex",
          gap: "0.25rem",
        }}
      >
        {tableHeadButtonConfig.length > 0 &&
          tableHeadButtonConfig.map((res) => (
            <Button
              key={res.label}
              sx={{
                display: "flex",
                width: "142px",
                padding: "10px",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
                marginTop: "25px",
                borderRadius: "10px 10px 0 0",
                border: "1px solid #929EAE",
              }}
              style={{
                background:
                  activeTableButton === res.value ? "lightgray" : "#FFF",
              }}
              onClick={() => {
                res.handleAccountStatus(res.value);
              }}
            >
              <Typography
                variant="h4"
                sx={{
                  fontSize: "14px",
                  fontFamily: "Proxima Nova",
                  textAlign: "right",
                  fontWeight: "600",
                  lineHeight: "normal",
                  letterSpacing: "0.28px",
                  textTransform: "capitalize",
                  color: "#000",
                }}
              >
                {res.label}
              </Typography>

              {activeTableButton === res.value && res.totalcount > 0 && (
                <Box
                  sx={{
                    display: "inline-flex",
                    padding: "2px 5px",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "10px",
                    background: "#000",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#fff",
                      textAlign: "right",
                      fontFamily: "Proxima Nov",
                      fontSize: "14px",
                      lineHeight: "normal",
                      fontWeight: "600px",
                      letterSpacing: "0.28px",
                      textTransform: "capitalize",
                    }}
                  >
                    {activeTableButton === res.value ? res.totalcount : 0}
                  </Typography>
                </Box>
              )}
            </Button>
          ))}
      </Box>

      <DataTable
        loading={isLoading}
        row={trainingCenterData?.data ?? []}
        columns={columns}
        totalCount={trainingCenterData?.totalCount ?? 0}
        paginationModel={trainingCenterData?.paginationModal ?? {}}
        setPaginationModel={setTraningCenter}
        paginationInnerObject={true}
      />

      <TrainingCenterModal
        open={open?.create ?? false}
        setOpen={setOpen}
        handleSubmit={createCenter}
        headerLabelText={"Add Center"}
        submitButtonText={"Create"}
        activeModalName={"create"}
        handleChange={handleTrainingCenter}
        trainingCenter={trainingCenter}
        setTrainingCenter={setTrainingCenter}
      />

      <CustomConfirmationDialog
        handleState={performActionByName}
        deleteById={deleteCenterById}
        isGroup={true}
        value={true}
        open={showConfirmDailog.delete}
        status="delete"
        message="Are You Sure You Want To Delete This Center?"
      />

      <CustomConfirmationDialog
        handleState={performActionByName}
        deleteById={deleteCenterById}
        isGroup={true}
        value={true}
        open={showConfirmDailog.delete}
        status="delete"
        message="Are You Sure You Want To Delete This Center?"
      />

      <CustomConfirmationDialog
        handleState={performActionByName}
        blockById={blockCenterById}
        isGroup={true}
        value={true}
        open={showConfirmDailog.block}
        status="block"
        message={`Are You Sure You Want To ${
          isBlocked ? "Unblocked" : "Block"
        } This Center?`}
      />

      <SwipeableDrawerComponent
        isOpen={showSidePanel?.filter["right"]}
        onClose={toggleFilterSidePanel("right", false)}
        anchor="right"
      >
        <CustomFilter
          filterOptions={filterConfig}
          toggleDrawer={toggleFilterSidePanel}
          handleClear={() => {
            setBlocked(null);
          }}
        />
      </SwipeableDrawerComponent>
    </Box>
  );
};

export default TrainingCenter;
