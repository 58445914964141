import { Box, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import AnalyticsCard from "./AnalyticsCard";

import duration from "dayjs/plugin/duration";

import SharedCard from "./SharedCard";
import axiosInstance from "../utils/axiosConfig";
import { showErrorToast } from "../utils/toastUtils";
import CustomSelect from "./reusableComponents/CustomSelect";
import removeSlugFromString from "../utils/removeSlugFromString";
import { useNavigate } from "react-router-dom";
import CircularLoader from "./CircularLoader";
import { useSettings } from "../hooks/useSettings";
import {
  SelectOptions,
  cdAnalyticsObj,
  dAnalyticsObj,
  filterAnalyticsObj,
  getNavigateStateByName,
  tripsAnalyticsObj,
} from "../const/analytics";
import TotalSummary from "./TotalSummary";
import CustomPieChart from "./rechart/CustomPieChart";
import CustomLineChart from "./rechart/CustomLineChart";
import CommonDateFilterPanel from "./CommonDateFilterPanel";
import dayjs from "dayjs";
import { formatDateWithDayJs } from "../utils/formateDate";
dayjs.extend(duration);

// import dayjs from "dayjs";

const initialDateFilter = {
  select: "TODAY",
  from_time: "",
  to_time: "",
};

export const areaoptions = {
  height: 300,
  width: "auto",
  legend: { position: "none" },
  series: {
    0: {
      areaOpacity: 0.1, // Background color opacity
      color: "#25CD25", // Stroke color
      lineWidth: 2, // Stroke width
      lineDashStyle: [3, 3], // Dashed line style
    },

    charArea: {
      backgroundColor: "red",
    },
  },
  vAxis: {
    gridlines: { color: "transparent" },
    minValue: 0,
    format: "0",
  },
  hAxis: {
    showTextEvery: 1, // Show every label on the x-axis
    slantedText: true, // Slant the text if there are too many labels
    slantedTextAngle: 45, // Angle of the slanted text
    gridlines: { color: "transparent" },
  },
};

export const options = {
  title: "User Growth with Time",
  titleTextStyle: {
    fontSize: 18,
    margin: 10,
  },
  // legend: { position: "none" },
  series: {
    0: { curveType: "linear", color: "#0F60FF", lineWidth: 4 },
    1: { curveType: "linear", color: "green", lineWidth: 4 },
  },
  width: "auto",
  height: "auto",
  vAxis: {
    gridlines: { color: "transparent" },
  },
  hAxis: {
    gridlines: { color: "transparent" },
    showTextEvery: 1, // Show every label on the x-axis
    // slantedText: true, // Slant the text if there are too many labels
    // slantedTextAngle: 45, // Angle of the slanted text
  },
};

export function graphyXLabel(option, date, formatDate, timezone) {
  switch (option) {
    case "TODAY":
      return formatDate(date, timezone, "h:mm A");

    case "DAYS":
      return formatDate(date, timezone, "MMM DD");

    case "YESTERDAY":
      return formatDate(date, timezone, "h:mm A");

    case "WEEKLY":
      return formatDate(date, timezone, "MMM DD");

    case "MONTHLY":
      return formatDate(date, timezone, "MMM DD");

    case "6M":
      return formatDate(date, timezone, "MMM YYYY");

    case "YEAR":
      return formatDate(date, timezone, "MMM YY");

    case "5Y":
      return new Intl.DateTimeFormat("en-US", { year: "numeric" }).format(date);

    default:
      return "";
  }
}

function convertText(value, replace) {
  if (!value) return "";
  const label = SelectOptions?.find((res) => replace === res?.value)?.label;
  let newText = value.replace(/Total/g, label === "Custom" ? "" : label);
  return newText;
}

let pieChartColor = [
  "#A2E2E8",
  "#FCA7A5",
  "#CEB7EF",
  "#B2E0AC",
  "#F5E69C",
  "#F8C6A0",
  "#FFB4C9",
  "#89A7D7",
  "#9AA5DE",
  "#6D9FC3",
  "#DDE299",
];

const Bullet = ({ backgroundColor, size }) => {
  return (
    <Box
      className="CirecleBullet"
      sx={{
        backgroundColor,
        height: "100%",
        width: "100%",
        borderRadius: "40px",
        padding: "0px",
      }}
    ></Box>
  );
};

const CustomizedLegend = (props) => {
  const { payload, legend, selectedOption } = props;
  const navigate = useNavigate();

  return (
    <>
      <Box
        sx={{
          display: "flex",
          height: "fit-content",
          flexWrap: "wrap",
          overflow: "hidden",
          gap: "8px",
          padding: "0",
          width: "100%",
          cursor: "pointer",
          mt: "41px",
        }}
      >
        {payload?.map((entry, index) =>
          entry.value === 0 ? (
            false
          ) : (
            <Stack
              flexDirection={"row"}
              key={`item-${index}`}
              alignItems={"center"}
              justifyContent={"center"}
              gap={"4px"}
              mb={"5px"}
              onClick={(e) => {
                e.stopPropagation();
                getNavigateStateByName(
                  legend,
                  payload[index]?.name,
                  navigate,
                  selectedOption
                );
              }}
            >
              <Box
                sx={{
                  height: "100%",
                }}
              >
                <Box
                  sx={{
                    width: "12px",
                    height: "12px",
                    paddingTop: "1px",
                  }}
                >
                  <Bullet
                    backgroundColor={
                      pieChartColor[index % pieChartColor.length]
                    }
                  />
                </Box>
              </Box>
              <Typography
                sx={{
                  display: "flex",
                  fontFamily: "Proxima Nova",
                  fontSize: "13px",
                  fontWeight: 400,
                  lineHeight: "15.83px",
                  color: "#39465F",
                }}
              >
                {entry?.name + " " + `(${entry.value})`}
              </Typography>
            </Stack>
          )
        )}
      </Box>
    </>
  );
};

export const getLabel = (diffYears, diffMonths, diffWeeks, diffDays) => {
  if (diffYears > 0) {
    return "YEAR";
  } else if (diffMonths > 0) {
    return "MONTHLY";
  } else if (diffWeeks > 0) {
    return "WEEKLY";
  } else if (diffDays > 0) {
    return "DAYS";
  } else {
    return "TODAY";
  }
};

const Summary = () => {
  let customerBackgroundColor = "rgba(15, 96, 255, 0.10)";
  let driverBackgroundColor = "rgba(241, 85, 76, 0.10)";
  const { timezone, formatDate } = useSettings();
  const [loader, setLoader] = useState(false);
  const [dateFilter, setDateFilter] = useState(initialDateFilter);
  const [chartOptions, setChartOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState("TODAY");
  const navigate = useNavigate();
  const [overallSummary2, setOverAllSummary2] = useState(null);

  const handleDateFilter = (name, value) => {
    if (name === "select" && value !== "CUSTOM") {
      setDateFilter((prev) => ({
        ...prev,
        [name]: value,
        from_time: null,
        to_time: null,
      }));

      return;
    }

    setDateFilter((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const TitleStyle = {
    color: "#000",
    fontFamily: "Proxima Nova",
    fontSize: 20,
    fontStyle: "normal",
    fontWeight: 800,
    lineHeight: "normal",
  };

  const handleSetCharOptions = (overallSummary) => {
    if (!overallSummary) return;

    // Parse the start and end dates
    const start = dayjs(dateFilter?.from_time);

    const end = dayjs(dateFilter?.to_time);

    // Calculate the difference in years, months, weeks, and days
    const diffYears = end.diff(start, "year");
    const diffMonths = end.diff(start, "month");
    const diffWeeks = end.diff(start, "week");
    const diffDays = end.diff(start, "day");

    const checkisCustom =
      dateFilter?.select === "CUSTOM" &&
      dateFilter?.from_time &&
      dateFilter?.to_time;

    const label = getLabel(diffYears, diffMonths, diffWeeks, diffDays);

    const charts = {
      ...(overallSummary?.user_growth_with_time && {
        user_growth_with_time: overallSummary?.user_growth_with_time,
      }),
      ...(overallSummary?.trips_count_vs_time && {
        trips_count_vs_time: overallSummary?.trips_count_vs_time,
      }),
    };

    for (let data in charts) {
      if (charts.hasOwnProperty(data) && typeof charts[data] === "object") {
        const chartData = Array.isArray(charts[data]?.data)
          ? charts[data].data.map((res) => {
              const name = graphyXLabel(
                checkisCustom ? label : dateFilter.select,
                res?.name,
                formatDate,
                timezone
              );

              return {
                ...res,
                name,
              };
            })
          : [];

        charts[data] = { ...charts[data], data: chartData };
      }
    }
    setChartOptions(charts);
  };

  const getPathWithState = (title) => {
    const special = "summary_redirection";

    switch (title) {
      case "Total Cancelled Trips By Driver":
        return {
          path: "/driver-trips",
          state: { cancelledBy: "DRIVER", special, status: "CANCELLED" },
        };
      case "Total Cancelled Trips By Rider":
        return {
          path: "/customer-trips",
          state: { cancelledBy: "RIDER", special, status: "CANCELLED" },
        };
      case "Driver Not Found":
        return {
          path: "/customer-trips",
          state: { status: "DRIVER_NOT_FOUND", special },
        };
      case "Cancel Trips":
        return {
          path: "/driver-trips",
          state: { cancelledBy: "CANCELLED", special },
        };
      case "Current Live Trips\n                ":
        return {
          path: "/driver-trips",
          state: { status: "LIVE_TRIP", special },
        };
      case "Current Requested Trips\n                ":
        return {
          path: "/driver-trips",
          state: { status: "REQUESTED", special },
        };
      case "Total Completed Trips":
        return {
          path: "/driver-trips",
          state: { status: "COMPLETED", special },
        };
      case "Total Trips":
        return {
          path: "/driver-trips",
          state: { special },
        };
      default:
        return {
          path: "/driver-trips",
        };
    }
  };

  const getPathWithStateFilter = (title) => {
    const special = "summary_redirection";

    switch (title) {
      case "Live Trips":
        return {
          path: "/driver-trips",
          state: { status: "LIVE_TRIP", special },
        };
      case "Requested Trips":
        return {
          path: "/driver-trips",
          state: { status: "REQUESTED", special },
        };
      default:
        return false;
    }
  };

  const analyticsHandleClick = (e, text) => {
    e.stopPropagation();
    const pathAndState = getPathWithState(text);

    if (pathAndState?.state) {
      navigate(pathAndState?.path, { state: pathAndState?.state });
    }
  };

  const disableConatainer = (key) => {
    switch (key) {
      case "total":
        return false;
      case "ongoing_trips":
        return true;
      case "requested_trips":
        return true;
      case "cancelled_trips":
        return false;
      case "cancelled_by_driver_trips":
        return false;
      case "cancelled_by_rider_trips":
        return false;
      case "driver_not_found_trips":
        return false;
      case "completed_trips":
        return false;
      default:
        return false;
    }
  };

  const DisableShareCard = (key) => {
    switch (key) {
      case "total":
        return true;
      case "total_new_onboarding":
        return true;
      case "total_online":
        return false;
      case "total_activated":
        return true;
      default:
        return true;
    }
  };

  const navigateSharedCard = (title) => {
    switch (title) {
      case "Current Online Driver":
        return {
          path: "/overview",
          state: { tab: "Drivers on Map" },
        };
      default:
        return false;
    }
  };

  const handleNavigateSharedCard = (text) => {
    const navigateData = navigateSharedCard(text);

    if (navigateData) {
      navigate(navigateData?.path, { state: navigateData?.state });
    }
  };

  // Funtion to Fetch Summary Data from server
  const getSummaryV2 = async (range) => {
    const queryBuilder = () => {
      const querys = [];

      const format = "DD-MM-YYYY";

      if (range && range !== "CUSTOM") {
        querys.push(`range=${range}`);
      }

      if (range === "CUSTOM" && dateFilter?.from_time && dateFilter?.to_time) {
        const custom_start_date = formatDateWithDayJs(
          dateFilter?.from_time,
          format
        );

        const custom_end_date = formatDateWithDayJs(
          dateFilter?.to_time,
          format
        );

        querys.push(`range=${range}`);
        querys.push(`custom_start_date=${custom_start_date}`);
        querys.push(`custom_end_date=${custom_end_date}`);
      }

      return querys.length > 0 ? `?${querys.join("&")}` : "";
    };

    const querys = queryBuilder();
    setLoader(true);
    const url = `/crm/v3/summary/${querys}`;

    try {
      let response = await axiosInstance.get(url);

      if (response) {
        let overallSummary = response?.data?.data?.overall_summary ?? null;
        setOverAllSummary2(overallSummary);
        handleSetCharOptions(overallSummary);
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      setTimeout(() => {
        setLoader(false);
      }, 500);
    }
  };

  useEffect(() => {
    // calling summary funtion
    if (dateFilter?.select !== "CUSTOM") {
      getSummaryV2(dateFilter?.select);
    } else if (dateFilter?.from_time && dateFilter?.to_time) {
      getSummaryV2(dateFilter?.select);
    }
  }, [dateFilter]);

  // useEffect(() => {
  //   getSummary("1D");
  // }, []);

  return loader ? (
    <CircularLoader />
  ) : (
    <Box
      sx={{
        position: "relative",
        boxSizing: "border-box",
      }}
    >
      {/* <CustomSelect
        state={selectedOption}
        setState={setSelectedOption}
        options={SelectOptions}
      /> */}

      {/* <DateRangePicker /> */}

      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          margin: "14px 0",
          alignContent: "flex-end",
        }}
      >
        <CommonDateFilterPanel
          dateFilter={dateFilter}
          filterSelectOptions={SelectOptions}
          handleDateFilter={handleDateFilter}
        />
      </Box>
      {/* <Box
        sx={{
          mt: "25px",
          display: "grid",
          boxSizing: "border-box",
          gridTemplateColumns: "repeat(1, 1fr)",
          gap: "16px",
        }}
      >
        {chartOptions.length > 0 &&
          chartOptions.map((res, index) => (
            <CustomChart
              key={index}
              options={res?.options ?? {}}
              data={res?.data ?? []}
              chartType={res.chartType}
            />
          ))}
      </Box> */}

      <Box
        sx={{
          mt: "25px",
          display: "grid",
          boxSizing: "border-box",
          gridTemplateColumns: {
            xs: "repeat(1,1fr)",
          },
          gap: "24px",
        }}
      >
        {Object.entries(chartOptions).length > 0 &&
          Object.entries(chartOptions).map((res, index) => {
            const data = res[1];
            return (
              <CustomLineChart
                key={data?.title}
                title={data?.title ?? ""}
                options={data?.options ?? []}
                data={data?.data ?? []}
              />
            );
          })}
      </Box>

      <Box
        sx={{
          mt: "40px",
          display: "grid",
          boxSizing: "border-box",
          gridTemplateColumns: {
            xs: "repeat(1,1fr)",
            md: "repeat(2,1fr)",
            lg: "repeat(3,1fr)",
          },
          gap: "40px",
          padding: "0 0 0 0",
        }}
      >
        {overallSummary2 &&
          overallSummary2.filtered_trips &&
          Object.entries(overallSummary2.filtered_trips).map((res, index) => {
            let [key, value] = res;
            const disable = disableConatainer(key);
            return (
              <AnalyticsCard
                key={index}
                svg={filterAnalyticsObj[key]?.svgIcon ?? ""}
                handleClick={analyticsHandleClick}
                format={true}
                cursorType={!disable ? "not-allowed" : "pointer"}
                background={!disable ? "#CCCCCC" : "#fff"}
                headerText={`${convertText(
                  filterAnalyticsObj[key]?.text ?? removeSlugFromString(key),
                  dateFilter?.select
                )}
                ${
                  dateFilter?.select === "CUSTOM" &&
                  filterAnalyticsObj[key]?.text !== "Current Live Trips" &&
                  filterAnalyticsObj[key]?.text !== "Current Requested Trips"
                    ? `<br/>${
                        dayjs(dateFilter?.from_time).format("DD/MM/YYYY") ===
                          "Invalid Date" ||
                        dayjs(dateFilter?.to_time).format("DD/MM/YYYY") ===
                          "Invalid Date"
                          ? ""
                          : `${dayjs(dateFilter?.from_time).format(
                              "DD/MM/YYYY"
                            )} - ${dayjs(dateFilter?.to_time).format(
                              "DD/MM/YYYY"
                            )}`
                      }`
                    : ""
                }`}
                value={value}
              />
            );
          })}
      </Box>

      <Box
        sx={{
          mt: "40px",
          display: "grid",
          boxSizing: "border-box",
          gridTemplateColumns: {
            xs: "repeat(1,1fr)",
            md: "repeat(2,1fr)",
            lg: "repeat(3,1fr)",
          },
          gap: "40px",
          padding: "0 0 0px 0",
        }}
      >
        {overallSummary2 &&
          overallSummary2.rider &&
          Object.entries(overallSummary2.rider).map((res, index) => {
            const [key, value] = res;

            if (key === "total_online" || key === "total") return false;

            let title;

            if (key === "total_new_onboarding") {
              title = `${convertText(
                cdAnalyticsObj[key]?.text ?? removeSlugFromString(key),
                dateFilter?.select
              )} ${
                dateFilter?.select === "CUSTOM"
                  ? `<br/>${
                      dayjs(dateFilter?.from_time).format("DD/MM/YYYY") ===
                        "Invalid Date" ||
                      dayjs(dateFilter?.to_time).format("DD/MM/YYYY") ===
                        "Invalid Date"
                        ? ""
                        : `${dayjs(dateFilter?.from_time).format(
                            "DD/MM/YYYY"
                          )} - ${dayjs(dateFilter?.to_time).format(
                            "DD/MM/YYYY"
                          )}`
                    }`
                  : ""
              }`;
            } else {
              title = cdAnalyticsObj[key]?.text ?? removeSlugFromString(key);
            }
            return (
              <SharedCard
                key={index}
                svgicon={cdAnalyticsObj[key]?.svgIcon ?? ""}
                title={title}
                value={value}
                backgroundColor={customerBackgroundColor}
              />
            );
          })}

        {overallSummary2 &&
          overallSummary2.driver &&
          Object.entries(overallSummary2.driver).map((res, index) => {
            const [key, value] = res;
            let title;

            const cursorType = DisableShareCard(key) ? "default" : "pointer";

            if (key === "total_activated" || key === "total") return false;

            if (key === "total_new_onboarding" || key === "total") {
              title = `${convertText(
                dAnalyticsObj[key]?.text ?? removeSlugFromString(key),
                dateFilter?.select
              )}
            ${
              dateFilter?.select === "CUSTOM"
                ? `<br/>${
                    dayjs(dateFilter?.from_time).format("DD/MM/YYYY") ===
                      "Invalid Date" ||
                    dayjs(dateFilter?.to_time).format("DD/MM/YYYY") ===
                      "Invalid Date"
                      ? ""
                      : `${dayjs(dateFilter?.from_time).format(
                          "DD/MM/YYYY"
                        )} - ${dayjs(dateFilter?.to_time).format("DD/MM/YYYY")}`
                  }`
                : ""
            }`;
            } else if (key === "total_online") {
              title = "Current Online Driver";
            } else {
              title = dAnalyticsObj[key]?.text ?? removeSlugFromString(key);
            }

            return (
              <SharedCard
                key={index}
                svgicon={dAnalyticsObj[key]?.svgIcon ?? ""}
                title={
                  index === 0
                    ? `${selectedOption?.toLowerCase()} Drivers`
                    : title
                }
                value={value}
                handleClick={handleNavigateSharedCard}
                cursorType={cursorType}
                backgroundColor={driverBackgroundColor}
              />
            );
          })}
      </Box>

      <Stack
        width={"100%"}
        mt={"40px"}
        display={"grid"}
        gridTemplateColumns={{
          xs: "repeat(1,1fr)",
          md: "repeat(2,1fr)",
          lg: "repeat(3,1fr)",
        }}
        gap={"18px"}
        flexWrap={"wrap"}
      >
        {[
          overallSummary2?.cancelled_rides_source_breakdown,
          overallSummary2?.cancelled_rides_user_reason,
          overallSummary2?.cancelled_rides_driver_reason,
        ]
          ?.filter((res) => res !== undefined)
          ?.map((res, index) => (
            <Box
              sx={{
                flex: 1,
                background: "#fff",
                borderRadius: "8px",
                padding: "10px 15px",
                boxShadow: "0px 4px 20px 0px #0000001A",
                backgroundColor: "#fff",
              }}
            >
              <CustomPieChart
                legend={res?.title}
                data={res?.data}
                pieCenterData={res?.pieChartCenterValue}
                selectedOption={selectedOption}
              />

              <CustomizedLegend
                payload={res?.data}
                legend={res?.title}
                selectedOption={selectedOption}
              />
            </Box>
          ))}
      </Stack>
      <TotalSummary
        overallSummary2={overallSummary2}
        cdAnalyticsObj={cdAnalyticsObj}
        dAnalyticsObj={dAnalyticsObj}
        TitleStyle={TitleStyle}
        customerBackgroundColor={customerBackgroundColor}
        driverBackgroundColor={driverBackgroundColor}
        tripsAnalyticsObj={tripsAnalyticsObj}
        analyticsHandleClick={analyticsHandleClick}
      />
    </Box>
  );
};

export default Summary;
