import { Avatar, Button, Typography } from "@mui/material";
import classes from "../styles/chat.module.css";
import { Box, Stack } from "@mui/system";

const Image = ({ url }) => {
  return (
    <img
      src={url}
      width={"100%"}
      height={"100%"}
      style={{
        objectFit: "cover",
        cursor: "pointer",
        boxShadow:
          "0 1px 1px rgba(0,0,0,0.15), 0 2px 2px rgba(0,0,0,0.15), 0 4px 4px rgba(0,0,0,0.15), 0 8px 8px rgba(0,0,0,0.15)",
      }}
      alt="img"
    />
  );
};

const MessageRight = ({
  name,
  profilePic,
  date,
  time,
  ticketStatus,
  label,
  internalNote,
  appimageUrl,
  internalNoteImgUrl,
  setShowImage,
}) => {
  return (
    <div className={classes.messageRowRight} style={{ gap: "10px" }}>
      <Stack gap={"18px"}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "5px" }}>
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            {ticketStatus && (
              <Box
                sx={{
                  padding: "3.835px 12.088px",
                  width: "88px",
                  borderRadius: "21.973px",
                  backgroundColor: "#0182F9",
                  justifyContent: "center",
                  color: "#fff",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Proxima Nova",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "130%",
                  }}
                >
                  {ticketStatus}
                </Typography>
              </Box>
            )}

            <Typography
              sx={{
                fontFamily: "Proxima Nova",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "130%",
                color: "#000",
              }}
            >
              {name}
            </Typography>
          </Box>

          <Box
            display={"flex"}
            alignItems={"flex-end"}
            gap={"5px"}
            justifyContent={"flex-end"}
          >
            <Typography
              sx={{
                fontFamily: "Proxima Nova",
                fontSize: "15px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "130%",
              }}
            >
              {date}
            </Typography>
            <Typography
              sx={{
                fontFamily: "Proxima Nova",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "130%",
                color: "#6F747E",
              }}
            >
              {time}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
          }}
        >
          <Stack gap={"15px"}>
            {internalNote && (
              <Box
                display={"flex"}
                flexDirection={"column"}
                gap={"12px"}
                sx={{
                  backgroundColor: "#FDFFA8",
                  borderRadius: "4px",
                  padding: "10px",
                }}
              >
                <Typography>internal</Typography>
                {internalNoteImgUrl && (
                  <Box
                    sx={{
                      boxSizing: "border-box",
                    }}
                  >
                    <Button
                      sx={{
                        padding: "0",
                        maxWidth: "150px",
                        margin: "0",
                        objectFit: "contain",
                        boxSizing: "border-box",
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        setShowImage({
                          show: true,
                          url: internalNoteImgUrl,
                        });
                      }}
                    >
                      <Image url={internalNoteImgUrl} />
                    </Button>
                  </Box>
                )}
                <Typography
                  sx={{
                    width: "270px",
                    fontFamily: "Proxima Nova",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "130%",
                    textAlign: "left",
                  }}
                >
                  {internalNote}
                </Typography>
              </Box>
            )}

            <Box
              sx={{
                padding: "10px",
                background: "#F6F8FA",
                borderRadius: "4px",
              }}
            >
              {appimageUrl && (
                <Box
                  sx={{
                    boxSizing: "border-box",
                    mb: "10px",
                  }}
                >
                  <Button
                    sx={{
                      padding: "0",
                      maxWidth: "150px",
                      margin: "0",
                      objectFit: "contain",
                      boxSizing: "border-box",
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowImage({
                        show: true,
                        url: appimageUrl,
                      });
                    }}
                  >
                    <Image url={appimageUrl} />
                  </Button>
                </Box>
              )}
              <Typography
                sx={{
                  width: "320px",
                  fontFamily: "Proxima Nova",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "130%",
                  textAlign: "left",
                }}
              >
                {label}
              </Typography>
            </Box>
          </Stack>
        </Box>
      </Stack>

      <Box>
        <Avatar
          src={profilePic}
          sx={{
            width: "43px",
            height: "43px",
          }}
        />
      </Box>
    </div>
  );
};

export default MessageRight;
