import axiosInstance from "./axiosConfig";

export const handleUploadFileToApi = (file, handleSaveProfile) => {
  const apiUrl = "/utils/file/";
  const formData = new FormData();

  const options = {
    headers: {
      "Content-Type": "multipart/form-data", // This is important for form data
    },
  };
  formData.append("file", file);
  formData.append("folder", "public/");

  axiosInstance.post(apiUrl, formData, options).then((res) => {
    handleSaveProfile(res?.data?.data ?? {});
  });
};
