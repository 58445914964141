import { Box } from '@mui/material';
import React from 'react';

const LottieIframe = () => {
  return (
    <Box
          sx={{
            inset: "0 0 0 0",
            maxWidth: "480px",
            boxSizing: "border-box",
            margin: "0 auto",
            backgroundColor: "#fff",
            height: "100dvh",
            // border: "1px solid red",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            border: "1px solid #ccc",
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
          }}
        >
 <iframe
      src="https://lottie.host/embed/b08852f8-44fd-4b37-993f-2f670cb27b2f/4domwKklDE.json"
      style={{ width: '100%', height: '100dvh', border: 'none' }}
      title="Lottie Animation"
      frameBorder="0"
      allowFullScreen
    ></iframe>
        </Box>
   
  );
};

export default LottieIframe;