import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Label,
  Tooltip,
} from "recharts";
import { getNavigateStateByName } from "../../const/analytics";

let pieChartColor = [
  "#A2E2E8",
  "#FCA7A5",
  "#CEB7EF",
  "#B2E0AC",
  "#F5E69C",
  "#F8C6A0",
  "#FFB4C9",
  "#89A7D7",
  "#9AA5DE",
  "#6D9FC3",
  "#DDE299",
];

const CustomLabel = ({ viewBox, labelText, value }) => {
  const { cx, cy } = viewBox;
  const labelY = cy + 5; // Adjust this value to position the label below the value

  return (
    <g>
      <text
        x={cx}
        y={labelY}
        className="recharts-text recharts-label"
        textAnchor="middle"
        dominantBaseline="central"
        alignmentBaseline="middle"
        fill="#000"
        fontSize="24"
        fontWeight="700"
        fontFamily="Proxima Nova"
      >
        {value}
      </text>
    </g>
  );
};

function CustomPieChart({
  margin = false,
  legend,
  color,
  series,
  options,
  data,
  pieCenterData,
  selectedOption,
  ...rest
}) {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        width: "100%",
        height: "250px",
        boxSizing: "border-box",
        mt: !margin ? "18px" : "0",
        // border: "1px solid green",
      }}
    >
      <Typography
        sx={{
          fontFamily: "Proxima Nova",
          textAlign: "left",
          fontSize: 18,
          fontWeight: 600,
          lineHeight: "17.05px",
          letterSpacing: "1px",
          padding: "10px 0 10px 25px",
          color: "#0F1828",
          display: "flex",
          gap: "5px",
        }}
      >
        Trip Cancel:
        <Typography
          sx={{
            fontFamily: "Proxima Nova",
            textAlign: "left",
            fontSize: 16,
            fontWeight: 500,
            lineHeight: "14.96px",
            letterSpacing: "1px",
            color: "#0F1828B2",
          }}
        >
          {legend}
        </Typography>
      </Typography>

      <ResponsiveContainer
        style={{
          width: "100%",
          height: "calc(100% - 52px)",
          // border: "1px solid red",
        }}
      >
        <PieChart>
          <Pie
            startAngle={90}
            endAngle={-270}
            data={data}
            dataKey="value"
            // cx={100}
            cy={109}
            innerRadius={40}
            outerRadius={100}
          >
            {data.map((entry, index) => (
              <Cell
                onClick={(e) => {
                  e.stopPropagation();
                  getNavigateStateByName(
                    legend,
                    entry?.name,
                    navigate,
                    selectedOption
                  );
                }}
                key={`cell-${index}`}
                fill={pieChartColor[index % pieChartColor.length]}
              />
            ))}
            <Label
              content={<CustomLabel value={pieCenterData} />}
              position="center"
            />
          </Pie>
          <Tooltip
            cursor={{ stroke: "red", strokeWidth: 2 }}
            contentStyle={{
              backgroundColor: "#f5f5f5",
              border: "1px solid #d5d5d5",
            }}
            wrapperStyle={{ zIndex: 1000 }}
            isAnimationActive={true}
          />
        </PieChart>
      </ResponsiveContainer>
    </Box>
  );
}

export default CustomPieChart;
