import { Box, Button, Typography } from "@mui/material";
import React from "react";
import dp from "../../assets/dp.jpg";
import FileUploadInput from "../FileUploadInput";

const ProfilePicture = ({ handleProfilePictureUpload, profilePicture }) => {
  return (
    <Box
      sx={{
        width: { xs: "20%", xl: "auto" },
        boxSizing: "border-box",
        position: "relative",
      }}
    >
      {/* <Box
        component="img"
        sx={{
          height: 200,
          display: "block",
          boxSizing: "border-box",
          maxHeight: { xs: 200, md: 180 },
          maxWidth: { xs: 200, md: 180 },
          m: "0 0.5rem",
        }}
        alt="Profile Picture"
        src={profilePicture?.url ? profilePicture.url : dp}
      /> */}

      <Box
        sx={{
          display: "flex",
          alignItems: "start",
          justifyContent: "center",
          boxSizing: "border-box",
          m: "0 0.7rem",
        }}
      >
        <img
          style={{
            width: "200px",
            height: "200px",
            objectFit: "cover",
            maxWidth: "200px", // Set your maximum width
            maxHeight: "180px", // Set your maximum height
          }}
          src={profilePicture?.url ? profilePicture.url : dp}
          alt="profile pic"
        />
      </Box>
      <label htmlFor="file-upload-input">
        {profilePicture?.url ? (
          <Button
            variant="outlined"
            sx={{
              position: "absolute",
              bottom: "52%",
              left: "50%",
              transform: "translateX(-50%)",
              width: "92%",
              margin: "0 auto",
              color: "#009D0F",
              border: "0.5px solid rgba(0, 0, 0, 0.30)",
              fontFamily: "Proxima Nova",
            }}
            onClick={() =>
              document.getElementById("profile-picture-upload-input").click()
            }
          >
            Change
          </Button>
        ) : (
          <Button
            variant="outlined"
            sx={{
              position: "absolute",
              bottom: "52%",
              left: "50%",
              transform: "translateX(-50%)",
              width: "92%",
              margin: "0 auto",
              color: "#009D0F",
              border: "0.5px solid rgba(0, 0, 0, 0.30)",
              fontFamily: "Proxima Nova",
            }}
            onClick={() =>
              document.getElementById("profile-picture-upload-input").click()
            }
          >
            Upload Photo
          </Button>
        )}
      </label>
      <FileUploadInput
        id="profile-picture-upload-input"
        onFileChange={handleProfilePictureUpload}
      />
      <Typography
        gutterBottom
        variant="h6"
        component="span"
        sx={{ position: "absolute", bottom: "42%", left: "5%" }}
      >
        Classic +
      </Typography>
    </Box>
  );
};

export default ProfilePicture;
