import {
  Box,
  Button,
  Divider,
  IconButton,
  MenuItem,
  Modal,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { useEffect, useState } from "react";
import axiosInstance from "../utils/axiosConfig";
import removeSlugFromString from "../utils/removeSlugFromString";
import filterEmptyPropertiesRecursively from "../utils/filterEmptyPropertiesRecursively";
import { showErrorToast, showSuccessToast } from "../utils/toastUtils";
import convertTimestampToCustomFormat from "../utils/convertTimestampToCustomFormat";

// intial ticket Status
const initialStatus = [
  {
    title: "initiated",
    value: "INITIATED",
  },
  {
    title: "under review",
    value: "UNDER_REVIEW",
  },
  {
    title: "resolved",
    value: "COMPLETED",
  },
  {
    title: "reopened",
    value: "REOPENED",
  },
];

const AddRemarkModel = ({
  showAddRemark,
  handleAddRemark,
  bookingID,
  ticketID,
  name,
  remarkData,
  selectName,
  getTickets,
}) => {
  const [ticketStatus, setTicketStatus] = useState(remarkData?.status ?? "");
  const [updateRemark, setUpdateRemark] = useState();
  const [updatedTicketStatus, setUpdatedTicketStatus] = useState("");
  const [remark, setRemark] = useState("");

  // funtion to update ticketstatus & remark

  const updateTicketsData = async () => {
    let updateData = {
      remarks: updateRemark,
      status: updatedTicketStatus,
    };

    // remove empty property
    const filterdata = filterEmptyPropertiesRecursively(updateData);

    const userType = selectName === "driver" ? "driver" : "rider";
    const url = `/crm/${userType}/ticket/${remarkData?.id ?? ""}/`;
    try {
      await axiosInstance.patch(url, filterdata).then((_) => {
        showSuccessToast("Tickets Updated Succesfully!");
        // reseting state value
        setUpdateRemark("");
        setUpdatedTicketStatus("");
        setRemark("");
        // close the modal
        handleAddRemark();
        getTickets(userType);
      });
    } catch (error) {
      showErrorToast(error);
    }
  };

  // css
  const selectStyle = {
    display: "flex",
    height: "20px",
    padding: "4px 6px",
    alignItems: "center",
    gap: "2px",
    borderRadius: "40px",
    background: "rgba(4, 58, 135, 0.10)",
  };

  const commonTextStyle = {
    color: "var(--text-dark, #000)",
    fontFamily: "Proxima Nova",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    letterSpacing: "0.32px",
    textTransform: "capitalize",
  };

  // lost item container style
  const ContainerStyle = {
    display: "flex",
    width: "482px",
    height: "94px",
    padding: "10px",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "flex-start",
    gap: "10px",
    mt: "16px",
    borderRadius: "8px",
    border: "0.5px solid var(--text-30, rgba(0, 0, 0, 0.30))",
  };

  const textStyles = {
    width: "462px",
    height: "44px",
    flexShrink: 0,
    color: "var(--text-dark, #000)",
    fontFamily: "Proxima Nova",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "128.499%", // 20.56px
    letterSpacing: "0.32px",
  };

  useEffect(() => {
    setTicketStatus(remarkData?.status ?? "");
    setRemark(remarkData?.remarks === null ? "" : remarkData.remarks ?? "");
  }, [remarkData.status, remarkData.remarks]);
  return (
    <Modal open={showAddRemark} onClose={handleAddRemark}>
      <Box
        sx={{
          width: "535px",
          height: "533px",
          borderRadius: "20px",
          background: "#fff",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          boxSizing: "border-box",
          padding: "20px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "483px",
            margin: "auto",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Proxima Nova",
              color: "#000",
              fontSize: "20px",
              fontWeight: "600",
              fontStyle: "normal",
              letterSpacing: "0.4px",
            }}
            variant="h2"
          >
            Add Remark
          </Typography>
          <IconButton onClick={handleAddRemark}>
            <CloseIcon
              sx={{
                color: "#000",
              }}
            />
          </IconButton>
        </Box>
        <Divider
          tick
          sx={{
            background: "#DBDBDB",
            width: "484px",
            margin: "13px auto 0 auto",
            border: "1px solid #DBDBDB",
          }}
        />

        <Stack gap="16px" mt="16px">
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h3" sx={commonTextStyle}>
              Booking ID : {bookingID}
            </Typography>
            <Typography
              variant="h3"
              sx={{ ...commonTextStyle, color: "#6F747E" }}
            >
              {convertTimestampToCustomFormat(remarkData?.createdAt)}
            </Typography>
          </Stack>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h3" sx={commonTextStyle}>
              Ticket ID : {ticketID}
            </Typography>
            <Select
              sx={selectStyle}
              value={ticketStatus}
              onChange={(e) => {
                setUpdatedTicketStatus(e.target.value);
                setTicketStatus(e.target.value);
              }}
            >
              {initialStatus.map(({ title, value }) => (
                <MenuItem key={title} value={value}>
                  {title}
                </MenuItem>
              ))}
            </Select>
          </Stack>
          <Typography variant="h3" sx={commonTextStyle}>
            Customer : {name}
          </Typography>
        </Stack>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "16px",
          }}
        >
          <Box sx={ContainerStyle}>
            <Typography sx={{ ...commonTextStyle, width: "265px" }}>
              Concern :{" "}
              {`${removeSlugFromString(remarkData?.subject ?? "") ?? ""}`}
            </Typography>
            <Typography sx={textStyles}>
              {`${remarkData?.description ?? ""}`}
            </Typography>
          </Box>

          <Stack gap={"16px"}>
            <Typography sx={{ ...commonTextStyle, fontSize: "18px" }}>
              Remark
            </Typography>
            <TextField
              type="text"
              value={remark}
              onChange={(e) => {
                setUpdateRemark(e.target.value);
                setRemark(e.target.value);
              }}
              sx={{
                height: "60px",
                // padding: "10px 10px 10px 16px",
                boxSizing: "border-box",
                gap: "10px",
                flexShrink: 0,
                borderRadius: "8px",
                border: "1px solid var(--background-stroke-line, #E3E7EF)",
                background: "var(--system-white, #FFF)",
              }}
              placeholder="Type Remarke here"
            />
          </Stack>
        </Box>
        <Stack
          direction="row"
          spacing="auto"
          mt="16px"
          sx={{
            boxSizing: "border-box",
            width: "483px",
            position: "absolute",
            bottom: "35px",
          }}
        >
          <Button
            variant="outlined"
            onClick={handleAddRemark}
            sx={{
              width: "230px",
              height: "50px",
              boxSizing: "border-box",
              padding: "13px 12px",
              border: "1.5px solid #000",
              color: "#000",
            }}
          >
            Cancel
          </Button>
          <Button
            //   onClick={postData}
            sx={{
              width: "230px",
              height: "50px",
              boxSizing: "border-box",
              padding: "13px 12px",
              border: "1.5px solid #000",
              background: "#000",
              color: "#fff",
              "&:hover": {
                background: "#000",
              },
            }}
            variant="contained"
            onClick={updateTicketsData}
          >
            Add
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default AddRemarkModel;
