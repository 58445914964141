import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setScriptState } from "../state/scriptSlice";

export const useExternalScript = (url, isAuth) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.script.scripts?.[url] || "idle");

  useEffect(() => {
    if (!url && !isAuth) {
      dispatch(setScriptState("idle"));
      return;
    }

    let script = document.querySelector(`script[src="${url}"]`);

    const handleScript = (event) => {
      dispatch(setScriptState(event.type === "load" ? "ready" : "error"));
    };

    if (!script) {
      script = document.createElement("script");
      script.type = "application/javascript";
      script.src = url;
      script.async = true;
      script.defer = true;

      if (
        url !==
        "https://maps.googleapis.com/maps/api/js?key=&libraries=geometry,drawing,journeySharing&callback=initMap"
      ) {
        script.onload = handleScript;
        document.head.appendChild(script);
      }
    } else {
      dispatch(setScriptState("ready"));
    }

    return () => {
      script.removeEventListener("load", handleScript);
    };
  }, [url, isAuth, dispatch]);

  return state;
};
