import React from "react";

const RideStatus = ({ row }) => {
  return row.status ? (
    <div>
      {row.status === "REQUESTED" && (
        <svg
          style={{
            borderRadius: "50%",
            boxShadow:
              "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
          }}
          xmlns="http://www.w3.org/2000/svg"
          width="10"
          height="10"
          viewBox="0 0 10 10"
          fill="none"
        >
          <circle cx="5" cy="5" r="5" fill="#fff" />
        </svg>
      )}

      {row.status === "COMPLETED" && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="10"
          height="10"
          viewBox="0 0 10 10"
          fill="none"
        >
          <circle cx="5" cy="5" r="5" fill="#FFC940" />
        </svg>
      )}

      {row.status === "DRIVER_NOT_FOUND" && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="10"
          height="10"
          viewBox="0 0 10 10"
          fill="none"
        >
          <circle cx="5" cy="5" r="5" fill="#FF0000" />
        </svg>
      )}

      {row.status === "ONGOING" && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="10"
          height="10"
          viewBox="0 0 10 10"
          fill=""
        >
          <circle cx="5" cy="5" r="5" fill="#043A87" />
        </svg>
      )}

      {row.status === "CANCELLED" && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="10"
          height="10"
          viewBox="0 0 10 10"
          fill="none"
        >
          <circle cx="5" cy="5" r="5" fill="#D1D1D1" />
        </svg>
      )}
    </div>
  ) : (
    <>{"--"}</>
  );
};

export default RideStatus;
