import React, { useState } from "react";
import { Document, Page } from "react-pdf";

const PdfViewer = ({ file }) => {
  const [numPages, setNumPages] = useState();
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <div>
      <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
        <Page
          pageNumber={pageNumber}
          renderAnnotationLayer={false}
          renderTextLayer={false}
          width={110}
          height={150}
        />
      </Document>
    </div>
  );
};

export default PdfViewer;
