import userIcon from "../assets/summary/userIcon.svg";
import userIcon1 from "../assets/summary/userIcon1.svg";
import carIcon from "../assets/summary/carIcon.svg";
import tierIcon from "../assets/summary/noun-tire.svg";
import global from "../assets/Global.svg";

export const initialDateFilter = {
  select: "TODAY",
  from_time: "",
  to_time: "",
};

export const SelectOptions = [
  {
    label: "Today",
    value: "TODAY",
  },
  {
    label: "Yesterday",
    value: "YESTERDAY",
  },
  {
    label: "Last Week",
    value: "WEEKLY",
  },
  {
    label: "Last Month",
    value: "MONTHLY",
  },
  {
    label : "Custom" ,
    value : "CUSTOM"
  }
];

// Object to store SVG icons and corresponding text for Customer and Driver Analytics
export const cdAnalyticsObj = {
  total: {
    text: "Total Customers",
    svgIcon: userIcon,
  },
  total_new_onboarding: {
    text: "Total New Customers",
    svgIcon: userIcon1,
  },
  total_online: {
    text: "Total Online Customers",
    svgIcon: global,
  },
  total_activated: {
    text: "total activated Customers",
    svgIcon: carIcon,
  },
};

export const dAnalyticsObj = {
  total: {
    text: "Total Drivers",
    svgIcon: userIcon,
  },
  total_new_onboarding: {
    text: "Total New Drivers",
    svgIcon: userIcon1,
  },
  total_online: {
    text: "Total Online Driver",
    svgIcon: global,
  },
  total_activated: {
    text: "total activated drivers",
    svgIcon: carIcon,
  },
};

// Object to store SVG icons and corresponding text for Trips Analytics
export const tripsAnalyticsObj = {
  total: {
    text: "Total Created Trips",
    svgIcon: carIcon,
  },
  ongoing_trips: {
    text: "Live Trips",
    svgIcon: tierIcon,
  },
  total_cancelled_trips: {
    text: "Cancel Trips",
    svgIcon: carIcon,
  },
  total_cancelled_by_driver_trips: {
    text: "Total Cancelled Trips By Driver",
    svgIcon: carIcon,
  },
  total_cancelled_by_rider_trips: {
    text: "Total Cancelled Trips By Passenger",
    svgIcon: carIcon,
  },
  total_driver_not_found_trips: {
    text: "Total Driver Not Found",
    svgIcon: carIcon,
  },
  total_completed_trips: {
    text: "Total Completed Trips",
    svgIcon: carIcon,
  },
};

export const filterAnalyticsObj = {
  total: {
    text: "Total Created Trips",
    svgIcon: carIcon,
  },
  ongoing_trips: {
    text: "Current Live Trips",
    svgIcon: tierIcon,
  },
  requested_trips: {
    text: "Current Requested Trips",
    svgIcon: tierIcon,
  },
  cancelled_trips: {
    text: "Cancel Trips",
    svgIcon: carIcon,
  },
  cancelled_by_driver_trips: {
    text: "Total Cancelled Trips By Driver",
    svgIcon: carIcon,
  },
  cancelled_by_rider_trips: {
    text: "Total Cancelled Trips By Passenger",
    svgIcon: carIcon,
  },
  driver_not_found_trips: {
    text: "Total Driver Not Found",
    svgIcon: carIcon,
  },
  completed_trips: {
    text: "Total Completed Trips",
    svgIcon: carIcon,
  },
};

export const commonXaxisProp = {
  tick: { stroke: "#000", angle: -45 },
  textAnchor: "end",
  fontFamily: "Proxima Nova",
  fontSize: "11px",
  fontWeight: 300,
  height: 50,
  letterSpacing: "1px",
};

export const commonYAxiosProp = {
  letterSpacing: "1px",
  textAnchor: "end",
  fontFamily: "Proxima Nova",
  fontSize: "11px",
  tick: { stroke: "#000" },
};

export const getNavigateStateByName = (name, reason, navigate ,range) => {
  

  if(range === "WEEKLY"){
    range = "last_week"
  }else if(range === "MONTHLY"){
    range = "last_month"
  }


  if (!name || !navigate) {
    console.error("Invalid parameters passed to getNavigateStateByName");
    return;
  }

  const navigateWithState = (path, state) => {
    navigate(path, { state });
  };

  if (name === "Source Breakdown") {
    switch (reason) {
      case "User":
        navigateWithState("/customer-trips", {
          status: "CANCELLED",
          cancelled_by: "RIDER",
          range : range?.toLowerCase()
        });
        break;
      case "Driver":
        navigateWithState("/driver-trips", {
          status: "CANCELLED",
          cancelled_by: "DRIVER",
          range : range?.toLowerCase()
        });
        break;
      default:
        navigateWithState("/customer-trips", {
          status: "DRIVER_NOT_FOUND",
          range : range?.toLowerCase()
        });
        break;
    }
  } else if (name === "User Reason" || name === "Driver Reason") {
    const path = name === "User Reason" ? "/customer-trips" : "/driver-trips";
    const cancelled_by = name === "User Reason"  ? "RIDER" : "Driver"
    navigateWithState(path, {
      status: "CANCELLED",
      reason,
      range : range?.toLowerCase(),
      cancelled_by
    });
  } else {
    console.log(`Unhandled navigation name: ${name}`);
  }
};
