/**
 * Recursively filters empty properties within objects and arrays, removing properties with null or empty values or undefined.
 *
 * @param {Object|Array} obj - The object or array to filter.
 * @returns {Object|Array} The filtered object or array with empty properties removed.
 */

function filterEmptyPropertiesRecursively(obj) {
  if (Array.isArray(obj)) {
    // If obj is an array, recursively filter each element in the array
    return obj
      .map((item) => filterEmptyPropertiesRecursively(item))
      .filter(Boolean);
  }

  if (typeof obj === "object" && obj !== null) {
    // If obj is an object, recursively filter its properties
    return Object.keys(obj).reduce((acc, key) => {
      const value = obj[key];
      if (value !== null && value !== "" && value !== undefined) {
        const filteredValue = filterEmptyPropertiesRecursively(value);
        if (filteredValue !== null) {
          acc[key] = filteredValue;
        }
      }
      return acc;
    }, {});
  }

  return obj;
}

export default filterEmptyPropertiesRecursively;
