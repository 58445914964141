import { Box, Modal } from "@mui/material";

const ViewImageModal = ({ url, open, onClose }) => {
  return (
    <Modal
      open={open}
      onClose={() => {
        onClose();
      }}
      aria-labelledby="image-modal"
      aria-describedby="modal-showing-full-image"
      sx={{
        zIndex: 9000,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          maxWidth: "90%",
          width: "auto",
          maxHeight: "90%",
          bgcolor: "background.paper",
          boxShadow: 24,
          overflow: "auto",
        }}
      >
        <img
          src={url}
          alt="Full size"
          style={{ width: "100%", height: "auto", maxWidth: 600 }}
        />
      </Box>
    </Modal>
  );
};

export default ViewImageModal;
