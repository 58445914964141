// ratingAndReviewSidePanelSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  driverDetails: {
    driverName: "",
    driverTier: "",
    cabType: "",
  },
  ratingAndReviewSidePanel: false,
  page: 1,
  ratingAndReviewData: [],
};

const ratingAndReviewSidePanelSlice = createSlice({
  name: "ratingAndReviewSidePanel",
  initialState,
  reducers: {
    setDriverDetails: (state, action) => {
      state.driverDetails = { ...action.payload };
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setRatingAndReviewData: (state, action) => {
      state.ratingAndReviewData = [...action.payload];
    },
    setRatingAndReviewSidePanel: (state, action) => {
      state.ratingAndReviewSidePanel = action.payload;
    },
    incrementPage: (state) => {
      state.page += 1;
    },
  },
});

export const {
  setDriverDetails,
  setPage,
  setRatingAndReviewData,
  setRatingAndReviewSidePanel,
  incrementPage,
} = ratingAndReviewSidePanelSlice.actions;
export default ratingAndReviewSidePanelSlice.reducer;
