function removeSlugFromString(inputString) {
  const result = inputString.replace(/_/g, " ");

  return result;
}

export default removeSlugFromString;

export function truncateWithEllipsis(text, maxLength) {
  if (text.length > maxLength) {
    return text.substring(0, maxLength) + "...";
  }
  return text;
}
