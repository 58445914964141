import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const usePermission = (path) => {
  const { pathname } = useLocation();
  const allowedPermissions = useSelector(
    (store) => store.rolePermission.allowed_permissions
  );

  const permissions = useMemo(
    () => allowedPermissions[path || pathname],
    [allowedPermissions, pathname]
  );

  return permissions;
};

export default usePermission;
