import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useDispatch } from "react-redux";
import useQueryParams from "../../../hooks/useQueryParams ";

const FilterClearDoneButton = ({
  onDone,
  filterOptions,
  text = "Filter",
  onClear,
}) => {
  const dispatch = useDispatch();

  const { removeMultipleQueries } = useQueryParams();
  const filterClear = () => {
    removeMultipleQueries(filterOptions);
    if (filterOptions) {
      filterOptions?.forEach(
        ({
          date,
          time,
          state,
          setState,
          parentDispatch,
          type,
          inputSetState,
          inputstate,
        }) => {
          if (type === "input radio") {
            if (inputSetState && inputstate) {
              inputSetState("");
            }
          }

          // checking state if state is present it has value then we are setting default value
          if (state) {
            parentDispatch ? dispatch(setState("")) : setState("");
          }

          // dtase date if state is present it has value then we are setting default value
          if (date && date?.customDateState) {
            const dateState = {};

            Object.entries(date?.customDateState ?? {}).forEach(([key, _]) => {
              dateState[key] = null;
            });

            date?.isDispatch
              ? dispatch(date.setState(dateState))
              : date.setState(dateState);
          }

          if (time && typeof time === "object") {
            time?.setState((prev) => {
              const newState = {};
              Object.entries(prev).forEach(([key, _]) => {
                newState[key] = null;
              });
              return newState;
            });
          }
        }
      );
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        margin: "21px auto",
        boxSizing: "border-box",
        padding: "20px",
        display: "flex",
        justifyContent: "space-between",
        background: "#fff",
        boxShadow: "0px -4px 7px 0px rgba(0, 0, 0, 0.15)",
      }}
    >
      <Button
        sx={{
          padding: "10px 16px",
          borderRadius: "5px",
          border: "1px solid rgba(0, 0, 0, 0.30)",
          color: "#000",
          fontSize: "14px",
          fontWeight: "600",
        }}
        onClick={() => {
          if (onClear) {
            onClear();
            removeMultipleQueries(filterOptions);
          } else {
            filterClear();
          }
        }}
      >
        {`Clear all ${text}`}
      </Button>
      <Button
        sx={{
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: "600",
          color: "#fff",
          background: "rgba(0, 0, 0, 0.30)",
          "&:hover": {
            background: "rgba(0, 0, 0, 0.30)",
          },
        }}
        onClick={onDone}
      >
        Done
      </Button>
    </Box>
  );
};

export default FilterClearDoneButton;
