// Import necessary dependencies
import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";

// Define initial state
const initialState = {
  rating: "",
  review: "",
  ratingDay: "",
  ratingDate: {
    startDate: "",
    endDate: "",
  },
  selectedTime: null,
  endTime: null,
};

// Create a slice
const ratingAndReviewSidePanelFilterSlice = createSlice({
  name: "ratingAndReviewSidePanelFilter",
  initialState,
  reducers: {
    setRating: (state, action) => {
      state.rating = action.payload;
    },
    setReview: (state, action) => {
      state.review = action.payload;
    },
    setRatingDay: (state, action) => {
      state.ratingDay = action.payload;
    },
    setRatingDate: (state, action) => {
      state.ratingDate = action.payload;
    },
    setSelectedTime: (state, action) => {
      state.selectedTime = action.payload;
    },
    setEndTime: (state, action) => {
      state.endTime = action.payload;
    },
    clearFilters: (state) => {
      return initialState;
    },
  },
});

// Export the actions and reducer
export const {
  setRating,
  setReview,
  setRatingDay,
  setRatingDate,
  setSelectedTime,
  setEndTime,
  clearFilters,
} = ratingAndReviewSidePanelFilterSlice.actions;
export default ratingAndReviewSidePanelFilterSlice.reducer;
