import React from "react";
import { Box, useMediaQuery } from "@mui/material";
import { Outlet } from "react-router-dom";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import { useLocation } from "react-router-dom";

const Layout = () => {
  const { pathname } = useLocation();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const isMobileView = useMediaQuery("(max-width:820px)");

  return (
    <Box
      display={isNonMobile ? "flex" : "block"}
      width="100%"
      height="100%"
      sx={{
        backgroundColor: "#F7F9FC",
        boxSizing: "border-box",
        overflow: "auto",
        scrollbarWidth: "thin",
        scrollbarColor: "transparent transparent", // for Firefox
        "&::-webkit-scrollbar": {
          width: "0px", // width of the scrollbar
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "transparent", // color of the thumb
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: "transparent", // color of the track
        },
      }}
    >
      <Sidebar isNonMobile={isNonMobile} drawerWidth="270px" />
      <Box flexGrow={1}>
        {pathname.substring(1).includes("driver/") ||
        pathname.substring(1).includes("new-driver") ||
        pathname.substring(1).includes("operator-management/new-operator") ||
        pathname.substring(1).includes("operator/") ||
        pathname.substring(1).includes("new-center") ||
        pathname.substring(1).includes("training-center/") ? (
          false
        ) : (
          <Navbar isMobileView={isMobileView} />
        )}
        <Outlet />
      </Box>
    </Box>
  );
};

export default Layout;
