import { Box, MenuItem, Stack, TextField } from "@mui/material";
import React, { useState } from "react";
import FlexBetween from "./FlexBetween";
import calculateAge from "../utils/calculateAge";
import { showErrorToast } from "../utils/toastUtils";
import DriverType from "../utils/DriverType";
import BoxHeader from "./driverDetails/BoxHeader";
import ProfilePicture from "./driverDetails/ProfilePicture";
import VerificationCheck from "./driverDetails/VerificationCheck";
import CommonSaveButton from "./driverDetails/CommonSaveButton";
import CreateDriverSaveButton from "./CreateDriverSaveButton";

const genders = [
  {
    value: "MALE",
    label: "MALE",
  },
  {
    value: "FEMALE",
    label: "FEMALE",
  },
];

const DriverBasicDetails = ({
  id,
  idRef = {},
  basicDetails,
  profilePicture,
  handleProfilePictureUpload,
  generateBasicDetailsBody = () => {},
  setBasicDetails,
  statusCheckBox,
  handleCheckboxChange,
  basicDetailsCheck,
  onChange,
  operator,
  setDriverType,
  setOpertaorName,
  operatorName,
  driverType,
  verificationCheckLabel,
  boxHeaderLabel,
  profilePictureLabel,
  saveButton = false,
  DriverDetailsValidationByApi,
  isDisabledCheckbox,
  setValidateLtfrb,
}) => {
  const [loading, setLoading] = useState({
    basicdetails: false,
  });
  return (
    <>
      <FlexBetween sx={{ m: "1.5rem 1.5rem" }}>
        <BoxHeader title={boxHeaderLabel} />
      </FlexBetween>
      <Box sx={{ display: "flex", position: "relative" }}>
        <ProfilePicture
          profilePicture={profilePicture}
          profilePictureLabel={profilePictureLabel}
          handleProfilePictureUpload={handleProfilePictureUpload}
        />
        <Box sx={{ width: "80%" }}>
          <Stack spacing={2} direction="row" sx={{ marginBottom: 4 }}>
            <TextField
              type="text"
              variant="outlined"
              color="secondary"
              label={"Name"}
              value={basicDetails.name}
              onChange={(e) => {
                setBasicDetails({
                  ...basicDetails,
                  name: e.target.value,
                });
              }}
              fullWidth
            />
            <TextField
              type="text"
              variant="outlined"
              color="secondary"
              label={"Nick Name (Optional)"}
              value={basicDetails?.nickName ?? ""}
              onChange={(e) => {
                setBasicDetails({
                  ...basicDetails,
                  nickName: e.target.value,
                });
              }}
              fullWidth
            />
          </Stack>
          <Stack spacing={2} direction="row" sx={{ marginBottom: 4 }}>
            <TextField
              type="email"
              variant="outlined"
              color="secondary"
              label={"Email"}
              onBlur={async (e) => {
                if (e.target.value.length > 0) {
                  await DriverDetailsValidationByApi({
                    ...(id && { driver_id: id }),
                    email: e.target.value,
                  });
                }
              }}
              value={basicDetails.email}
              onChange={(e) => {
                setBasicDetails({
                  ...basicDetails,
                  email: e.target.value,
                });
              }}
              fullWidth
            />
            <TextField
              select
              label={"Gender"}
              value={basicDetails.gender}
              onChange={(e) => {
                setBasicDetails({
                  ...basicDetails,
                  gender: e.target.value,
                });
              }}
              defaultValue="Male"
              fullWidth
            >
              {genders.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Stack>
          <Stack spacing={2} direction="row">
            <TextField
              type="date"
              InputLabelProps={{
                shrink: true,
                max: new Date().toISOString().split("T")[0],
              }}
              variant="outlined"
              color="secondary"
              label="DOB"
              value={basicDetails.dob}
              onChange={(e) => {
                setBasicDetails({
                  ...basicDetails,
                  dob: e.target.value,
                });
              }}
              onBlur={(e) => {
                let age = calculateAge(e.target.value);
                if (age < 18 && age !== "") {
                  showErrorToast("age should be grater then 18!");
                  return;
                }
              }}
              fullWidth
            />

            <TextField
              type="text"
              variant="outlined"
              color="secondary"
              label="Phone"
              value={basicDetails.phoneNumber}
              helperText={"Phone number must only contain numeric digits"}
              fullWidth
              onBlur={async (e) => {
                if (e.target.value.length === 10) {
                  await DriverDetailsValidationByApi({
                    ...(id && { driver_id: id }),
                    phone: `+63${e.target.value}`,
                  });
                }
              }}
              onChange={(e) => {
                if (!/^\d*$/.test(e.target.value)) {
                  // If alphabetic characters are present, throw an error or handle it accordingly
                  showErrorToast(
                    "Phone number must only contain numeric digits.",
                    {
                      autoClose: 5000,
                    }
                  );
                  return;
                }

                if (e.target.value.length <= 10) {
                  setBasicDetails({
                    ...basicDetails,
                    phoneNumber: e.target.value,
                  });
                }
              }}
            />
          </Stack>

          <Stack
            spacing={2}
            direction="column"
            sx={{ marginBottom: 4, marginTop: 2 }}
          >
            <TextField
              type="text"
              variant="outlined"
              color="secondary"
              label={"House number"}
              value={basicDetails.address1}
              onChange={(e) => {
                setBasicDetails({
                  ...basicDetails,
                  address1: e.target.value,
                });
              }}
              fullWidth
              sx={{ mb: 4, width: "50%" }}
            />
            <TextField
              type="text"
              variant="outlined"
              color="secondary"
              label={"Barangay"}
              value={basicDetails.address2}
              onChange={(e) => {
                setBasicDetails({
                  ...basicDetails,
                  address2: e.target.value,
                });
              }}
              sx={{ mb: 4, width: "50%" }}
            />

            <TextField
              type="text"
              variant="outlined"
              color="secondary"
              label={"City"}
              value={basicDetails.address3}
              onChange={(e) => {
                setBasicDetails({
                  ...basicDetails,
                  address3: e.target.value,
                });
              }}
              sx={{ mb: 4, width: "50%" }}
            />

            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              width={"50%"}
            >
              <TextField
                type="number"
                variant="outlined"
                color="secondary"
                label={"Postal code"}
                value={basicDetails.cityCode}
                inputProps={{ maxLength: 10, min: 0, max: 9999999999 }}
                onChange={(e) => {
                  if (e.target.value.toString().length <= 10) {
                    setBasicDetails({
                      ...basicDetails,
                      cityCode: e.target.value,
                    });
                  }
                }}
                sx={{ mb: 4, width: "34%" }}
              />
              <TextField
                type="number"
                variant="outlined"
                color="secondary"
                label={"TIN (Tax Identification Number)"}
                value={basicDetails.TIN}
                inputProps={{ maxLength: 10, min: 0, max: 9999999999 }}
                onChange={(e) => {
                  setBasicDetails({
                    ...basicDetails,
                    TIN: e.target.value,
                  });
                }}
                sx={{ mb: 4, width: "62%" }}
              />
            </Stack>
          </Stack>
          <Box
            sx={{
              position: "absolute",
              bottom: driverType === "INDIVIDUAL" ? "13%" : "32%",
              left: "65%",
            }}
          >
            <DriverType
              data={operator}
              setDriverType={setDriverType}
              basicDetails={basicDetails}
              setBasicDetails={setBasicDetails}
              setOpertaorName={setOpertaorName}
              operatorName={operatorName}
              driverType={driverType}
              setValidateLtfrb={setValidateLtfrb}
            />
            {/* { driverType === "INDIVIDUAL" &&  <TextField
              type="text"
              variant="outlined"
              color="secondary"
              label={"Operator Name"}
              value={basicDetails.operator_full_name}
              onChange={(e) => {
                setBasicDetails({
                  ...basicDetails,
                  operator_full_name : e.target.value,
                });
              }}
              sx={{ mt : 3 , width: "100%" }}
            />} */}
          </Box>
        </Box>
      </Box>

      {saveButton && (
        <Box sx={{ margin: "0px 1.5rem" }}>
          <CommonSaveButton
            updateIdName="basicDetails"
            title={"basicdetails"}
            DriverDetailsValidationByApi={DriverDetailsValidationByApi}
            basicDetails={basicDetails}
            idRef={idRef}
            loading={loading}
            handleLoading={(name, value) => {
              setLoading((prev) => ({ ...prev, [name]: value }));
            }}
            body={() => {
              return generateBasicDetailsBody();
            }}
          />
        </Box>
      )}

      {!saveButton && (
        <Box sx={{ margin: "0px 1.5rem" }}>
          <CreateDriverSaveButton
            handleLoading={(name, value) => {
              setLoading((prev) => ({ ...prev, [name]: value }));
            }}
            basicDetails={basicDetails}
            setLoading={setLoading}
            isDisabled={basicDetails?.phoneNumber?.length > 9}
            generateBasicDetailsBody={generateBasicDetailsBody}
            DriverDetailsValidationByApi={DriverDetailsValidationByApi}
          />
        </Box>
      )}
      <VerificationCheck
        checked={basicDetailsCheck}
        onChange={onChange}
        isDisabledCheckbox={isDisabledCheckbox}
        label={verificationCheckLabel}
        statusCheckBox={statusCheckBox}
        handleCheckboxChange={handleCheckboxChange}
      />
    </>
  );
};

export default DriverBasicDetails;
